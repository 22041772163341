const TableHead = () => {
  return (
    <thead className="bg-[#0096D1] text-white text-xs lg:text-[15px] ">
      <tr className="">
        <th className="py-2 border-r-8 border-white">School name</th>
        <th className="py-2">Subject and Message</th>
        <th className="py-2">Time</th>
      </tr>
    </thead>
  );
};

export default TableHead;
