import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

interface InputType{
    mobileScreen?: boolean,
    onChange: any,
    searchType?: string,
}

function StudentListInput({mobileScreen, onChange, searchType } : InputType) {
  return (
    <div className={`flex ${mobileScreen ? 'flex-col' : 'flex-wrap'} justify-start mr-6 md:justify-between text-[#1f1f1f]/[0.7] text-[14px] md:mt-6 mb-3 w-full`}>
    <div className={`${mobileScreen ? 'w-full' : 'w-4/12'} mt-2 md:mt-0`}>
      <div className="flex items-center px-2 rounded-lg border-2 border-gray-200 w-full min-w-[200px]">
        <input
          id="searchAttendanceInput"
          onChange={onChange}
          placeholder={`Search ${searchType ? searchType : "name"}`}
          className="rounded-lg py-2 pl-1 w-full focus:outline-none"
          type="text"
        />
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </div>
  </div>
  )


}



export default StudentListInput
