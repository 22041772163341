import "../../assets/css/studentRegistration.css";
import { defaultUrl } from "../../features/actions";
import { useEffect, useState } from "react";
import { makeFetchRequest } from "../../utilities/common";

export interface Classroom {
  value: string;
  name?: string;
}

export default function First({ nextStep, handleChange, form, next_form }: any) {
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);

  const fetchClassrooms = async () => {
    try {
      const response = await makeFetchRequest(`${defaultUrl}/classroom`, false, true)

      const data = response.map((item:any)=>{
        return {
          value: item?.id,
          name: item?.name
        }
      })
      // console.log(data)
      return setClassrooms(data || [])

    } catch (err: any) {
      // console.log({ errorInClassroom: err.response.data || err });
    }
  };

  useEffect(() => {
    fetchClassrooms()
  }, []);

  return (
    <>
      <div className="indicator">
        <div className="firstInd cursor-pointer" >1</div>
        <div className="firstInd2" onClick={next_form}>2</div>
      </div>
      <form className="regStudentForm">
        <div>
          <h2>Staff Profile</h2>
          <div className="">
            <div className="studentFormContent">
              <h4>Name<span className="text-red-600">*</span></h4>
              <div className="nameForm">
                <input
                  type="text"
                  placeholder="Surname*"
                  name="surname"
                  value={form.surname}
                  onChange={handleChange}
                  required
                />
                <div className="studentFormHide"></div>
                <input
                  type="text"
                  placeholder="First name*"
                  name="firstname"
                  value={form.firstname}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="other names*"
                  name="othername"
                  value={form.othername}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="studentFormContent">
              <h4>Email<span className="text-red-600">*</span></h4>
              <div className="nameForm">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="studentFormContent">
              <h4>Gender<span className="text-red-600">*</span></h4>
              <div className="genderForm">
                <span>
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    onChange={handleChange}
                    required
                    className="p-2"
                  />{" "}
                  Male
                </span>
                <span>
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    onChange={handleChange}
                    required
                  />{" "}
                  Female
                </span>
              </div>
            </div>
            <div className="studentFormContent">
              <h4>Date of Birth<span className="text-red-600">*</span></h4>
              <div className="nameForm">
                <input
                  type="date"
                  name="date_of_birth"
                  value={form.date_of_birth}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="studentFormContent">
              <h4>Mobile Number<span className="text-red-600">*</span></h4>
              <div className="nameForm">
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="phone_number"
                  value={form.phone_number}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="studentFormContent items-center">
              <h4>Qualification<span className="text-red-600">*</span></h4>
              <div className="educationdiv">
                <select
                  name="degree"
                  className="educationSecond"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select qualification</option>
                  <option>Graduate</option>
                  <option>Masters</option>
                  <option>Postgraduate Diploma</option>
                  <option>PhD</option>
                  <option>Other Qualifications</option>
                </select>
                <input
                  type="text"
                  placeholder="Discipline Name*"
                  className="text-[14px] border-2 rounded w-40 h-10 px-2 shadow"
                  name="discipline"
                  value={form.discipline}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="studentFormContent items-center">
              <h4>Assign To<span className="text-red-600">*</span></h4>
              <div className="educationdiv">
                <select
                  id=""
                  name="classroom_id"
                  className="educationSecond"
                  onChange={handleChange}
                >
                  <option value="">Select a class</option>
                  {classrooms?.map((classroom: any, idx: number) => (
                    <option key={classroom?.value} value={classroom?.value}>{classroom?.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <button onClick={nextStep} className="studentRegBtn">
          Next Step
        </button>
      </form>
    </>
  );
}
