import React, { useEffect, useState } from "react";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { RegisterCard } from "../components/RegisterCard";
import "../assets/css/photo.css";
import { getMediaThunk } from "../features/mediaSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import DashboardPhotoCard from "../components/DashboardPhotoCard";
import { chunkArray } from "../utilities/importantFns";
import { PagenateWithoutBackEnd } from "../components/pagination";


const PV = () => {

  const { images, videos, loading } = useAppSelector(state => state.photos)
  const itemsPerPage = 12;
  const [page, setPage] = useState(1)
  const pageIndex = page - 1
  const pictures = chunkArray(images, itemsPerPage)
  const [view] = useState("images");
  const dispatch = useAppDispatch();


  const changeStaffPage = (index: number) => {
    if (index === 0) {
      setPage(1)
    } else {
      setPage(index + 1)
    }
  }

  useEffect(() => {
    dispatch(getMediaThunk());
  }, [])



  return (
    <div>
      <div className="w-full">
        <TopAdminNav name="Jane Doe" title="Photos" />
      </div>
      <div className="">
        <div className="photoCardTop">
          <div className="w-10/12">
            <RegisterCard
              title="Let’s share it ."
              details="All pictures shared on platform"
            />
          </div>

        </div>

        <div className="photoCenter mt-4 h-[80vh] overflow-y-auto">
          {!loading && ![view].length ?
            <div className="imageCollection overflow-y-auto ">
              <img src="/img/camera.png" alt="" />
              <p>No {view} shared today.</p>
            </div>
            :
            <div className="h-full flex flex-col md:flex-row md:flex-wrap
          lg:flex-wrap-none justify-start items-start ">
              {(view === "images" ? pictures[pageIndex] : videos)?.map((item: any, index: number) => (
                <div key={index} className="m-4"><DashboardPhotoCard item={item} /></div>
              ))}

            </div>
          }

        </div>
                 {/* PAGENATION */}
                <div className="m-5">
                <PagenateWithoutBackEnd
                array={pictures}
                page_change={changeStaffPage}
                pageNum={page}
                itemperPage={itemsPerPage}
                totalItems={pictures && pictures.length}
              />
                </div>
      </div>
    </div>
  );
};

export default PV;
