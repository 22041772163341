import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import BackButton from "../components/backButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import studentEnImg from "../assets/image/02Student.png"
import staffEnImg from "../assets/image/01.png"
import { Link } from "react-router-dom";


export const EnrollmentSupport = () => {

    return (
        <div style={{ position: "relative" }}>
            <div className="w-full flex items-center">
                <BackButton />
                <TopAdminNav name="Jane Doe" title="Enrollment Support" />
            </div>

            <div className="flex">
                <div className="block w-full h-[87vh] overflow-auto register-scroll">
                    <div className="flex">
                        <div className="w-full">
                            <RegisterCard
                                title="Enrollment Support"
                                details="Help with blueprint in enrolling pupils and staff"
                            />
                        </div>
                    </div>
                    <div className="staffData border border-gray-100 !pb-0 !pt-[0.05px] mt-7 ">

                        <div className="flex justify-around flex-wrap">
                            <div
                                title="Click to view/download pdf"
                                className="h-[300px] w-[300px] my-8 mx-5 border-[1px] border-gray-600 rounded-md">
                                <Link target="_blank"
                                    to="https://res.cloudinary.com/djztsnmej/image/upload/v1728311121/Student_Enrollment_Form_a1tsu8.pdf">
                                    <LazyLoadImage
                                        style={{ height: "100%", width: "100%" }}
                                        src={studentEnImg}
                                        effect="blur"
                                        wrapperProps={{
                                            style: { transitionDelay: "1s" },
                                        }}
                                        alt=""
                                    />
                                    <p className="my-3">Pupil Enrollment Form</p>
                                </Link>

                            </div>

                            <div
                                title="Click to view/download pdf"
                                className="h-[300px] w-[300px] my-8 mx-5  border-[1px] border-gray-600 rounded-md">
                                <Link target="_blank"
                                    to="https://res.cloudinary.com/djztsnmej/image/upload/v1728311122/Staff_Enrollment_Form_l7grqa.pdf">
                                    <LazyLoadImage
                                        style={{ height: "100%", width: "100%" }}
                                        src={staffEnImg}
                                        effect="blur"
                                        wrapperProps={{
                                            style: { transitionDelay: "1s" },
                                        }}
                                        alt=""
                                    />
                                    <p className="my-3">Staff Enrollment Form</p>
                                </Link>

                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};
