import axios from "axios";
import React, {useState} from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useNavigation } from "react-router-dom";
import { defaultUrl } from "../features/actions";
import { globalToken } from "../pages/AdminHome";

const Faq = () => {
  let token = sessionStorage.getItem('token');
  let navigation= useNavigate();
  let [feedback, setFeedback] = useState("");
  function sendFeedback() {
    if(feedback.length < 4) {
      return toast("Please enter feedback");
    };

    toast.loading("Sending feedback...");
    return axios.post(`${defaultUrl}/feedback`, {feedback}, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then(response => {
      setFeedback("");
      toast.remove();
      toast(response.data.message); 
      // navigation(-1);
    })
    .catch((err:any)=> {
      console.log({feedbackError: err.response.data || err});
      toast.remove();
      toast("Sorry, an error occurred. Please try again")
    })
  }
  return (
    <div className="schProfile">
      <div style={{flex: 1, justifyContent: "center", alignItems:"center", padding:"10 0"}}>
        <div style={{display:"flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", minHeight:50, padding:5}}>
            <h1 style={{textAlign:"center", fontSize: 24}}>How can we improve?</h1>
            <div style={{width: "40%", height:5, backgroundColor: "#0096D1"}}></div>
        </div>
        <div style={{alignItems: "flex-start", width: "100%", padding:10, marginTop:10}}>
            <p style={{fontSize: 12}}>Type your feedback here</p> 
            <textarea onChange={(e)=>setFeedback(e.target.value)} value={feedback} placeholder="Type your message here..." className="my-4 p-2 text-sm rounded-lg border-gray-300 max-w-[600px] border h-60 w-[280px]" />
        </div>
        <div style={{alignItems: "center", justifyContent: "center", width: "100%", height:70, paddingLeft: 20}}>
            <div >
                <button
                  onClick={sendFeedback} className="bg-[#0096D1] w-max"
                  style={{justifyContent:"center", alignItems:"center", borderRadius:10, width: 200, height:40}}
                >
                  <p style={{fontSize: 14, color:"white", textAlign:"center"}}>Send Feedback</p>
                </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
