import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

interface SearchBarSchema {
  onChange: any,
  searchFeature? : string
}

function SearchBar({onChange, searchFeature}: SearchBarSchema) {
  return (
    <div className="flex max-w-full">
    <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 w-10/12 min-w-[200px] ">
      <FontAwesomeIcon icon={faSearch} />
      <input
        id="searchAttendanceInput"
        onChange={onChange}
        placeholder={`Search ${searchFeature ? searchFeature : "Name"}`}
        className="rounded-2xl pl-4 p-2 w-full focus:outline-none "
        type="text"
      />
    </div>
  </div>
  )
}

export default SearchBar
