import "../assets/css/calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { EventItem } from "./EventAndCalendar";
import { readableDate } from "../utilities/common";
import { isSameDay } from "date-fns";

const EventList = ({
  events,
  selectedDate: value,
  onClick,
}: {
  events: EventItem[];
  selectedDate: Date;
  onClick: any;
}) => {
  const selectedDaystring = value.toDateString();

  function todayEvents() {
    return (
      events &&
      //@ts-ignore
      events.filter((event) => isSameDay(new Date(event.start_date), value))
    );
  }

  return (
    <div className="w-[100%] min-w-min">
      <div
        className={`shadow my-2 px-2 pb-2 flex flex-wrap items-center justify-between`}
      >
        <h1 className={`text-[#012063B2] font-[600] text-[25px] text-center`}>
          {selectedDaystring}
        </h1>
        <div className="w-3/12 flex items-center justify-end">
          <button
            onClick={onClick.create}
            className="rounded min-w-max flex items-center px-4 !h-[40px] text-white bg-[#0096D1] mr-1"
          >
            Add Event
            <span className="rounded-full ml-2 h-4 w-4 text-sm text-[#0096D1] flex items-center justify-center bg-blue-100 opacity-80">
              <FontAwesomeIcon icon={faAdd} />
            </span>
          </button>
        </div>
      </div>
      <div className="h-[50vh] w-full flex flex-wrap items-start justify-center md:justify-start overflow-auto register-scroll">
        {events &&
          events.length > 0 &&
          todayEvents()?.map((item, index) => {
            const start = readableDate(item.start_date);
            const end = readableDate(item.end_date);
            return (
              <div
                key={index}
                className="rounded border-2 border-[#0096D1] border-opacity-50 flex flex-col m-2 p-2"
              >
                <div>
                  <h2 className="capitalize flex flex-col  text-[15px]">
                    <span className="text-xs text-[#0096D1] mr-1">Title: </span>
                    {item.title}
                  </h2>
                  <p className="capitalize flex flex-col my-4 text-[13px]">
                    <span className="text-xs text-[#0096D1] mr-1">
                      Description:{" "}
                    </span>
                    <span className=" h-10 max-h-20 w-60 max-w-80 overflow-auto register-scroll">
                      {item.description}
                    </span>
                  </p>
                  <p className="text-xs  text-[#0096D1] mr-1">
                    From {start}
                    <span
                      className={`${
                        item.start_date === item.end_date && "hidden"
                      }`}
                    >
                      {" "}
                      to {end}
                    </span>
                  </p>
                </div>
                <div className="w-full">
                  <button
                    onClick={(e) => onClick.edit(item)}
                    className="float-left rounded bg-cyan-600 text-white text-xs px-4 py-2 mt-4"
                  >
                    Edit Event
                  </button>
                  <button
                    onClick={(e) => onClick.delete(item.id)}
                    className="float-right rounded bg-red-600 bg-opacity-70 text-white text-xs px-4 py-2 mt-4"
                  >
                    Delete Event
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EventList;
