import { GrDocumentPdf } from 'react-icons/gr'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function MessageDisplayType({ message }: any) {

    const howToDisplayMessage = (text: string) => {
        if (text.includes("Media")) {

            let last4 = text?.slice(-4)?.slice(0, -1)

            if (last4 === "jpg") {
                return (<div className="shadow-md shadow-slate-600 rounded-md
                 h-[220px] w-[200px] sm:h-[250px] sm:w-[250px]">
                    <a href={text?.slice(8, -1)} target='_blank' rel="noreferrer">
                        <LazyLoadImage
                          alt=""
                          className="!rounded-md !h-full !w-full"
                          src={text?.slice(8, -1)}
                          effect='blur'
                          wrapperProps={{
                            style: {transitionDelay: "1s"},
                        }}
                           />
                    </a>
                </div>)
            } else if (last4 === "mp4") {
                return (<div className="shadow-md shadow-slate-600 rounded-[10px]">
                    <video controls className='rounded-[10px] w-[200px] h-[230px]
                    sm:w-[300px] sm:h-[200px]'>
                        <source src={text?.slice(8, -1)} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>)
            } else if (last4 === "pdf") {
                return (<div className="border-1 border-black shadow-md shadow-slate-600
             rounded-[10px] p-3">
                    <a href={text?.slice(8, -1)} target='_blank' rel="noreferrer"
                        className='flex flex-col justify-center'
                        download>
                        <GrDocumentPdf className="text-2xl sm:text-4xl mx-auto" />
                        <button className='text-xs'>Click to view</button></a>
                </div>)
            }

        } else {
            return <p className="text-[13px]">{text}</p>
        }
    }



    return (
        <div>{howToDisplayMessage(message)}</div>
    )

}

export default MessageDisplayType
