// StudentTable.jsx
import React from 'react';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import loader from "../../assets/loader.gif";
import { useNavigate } from 'react-router-dom';

export const StudentTable = ({ classrooms, studentData, isLoading, setEditForm,
                               setEditModeType, getEmergencyContact, handleArchieve
                             }: any) => {


  const navigate = useNavigate()

  return (
            <>
              {isLoading ? (
                <div className="flex justify-center m-4">
                  <div>
                    <img src={loader} alt="loader" className="" />
                  </div>
                </div>
              ) : classrooms &&
                studentData &&
                studentData.length > 0 ? (
                studentData.map((item: any, index: any) => {
                  const parentsLength : any = item?.parents
                  return (
                    <div
                      key={index}
                      className="flex items-center border w-full text-center text-gray-600 bg-white py-1 rounded-xl mt-[5px]"
                    >
                      <div className="w-2/12 text-[0.9em] font-bold flex justify-center min-h-[20px]">
                        <div className="h-20 w-20 overflow-auto rounded-full">
                          {item?.profile_picture}
                        </div>
                      </div>
                      <div className="w-4/12 min-h-[20px]">
                        <p className="text-center text-[0.9em] font-semibold text-[#012063CC]">{`${
                          item.firstname ?? ""
                        } ${item.othername ? item.othername : ""} ${
                          item.surname
                        }`}</p>
                      </div>
                      <div className="w-2/12 min-h-[20px]">
                        <p className="text-center text-[0.9em] min-w-max">
                          {item.date_of_birth ?? null}
                        </p>
                      </div>
                      <div className="w-2/12 min-h-[20px]">
                        <p className="text-center text-[0.9em]">
                          {item.classroom?.name ?? null}
                        </p>
                      </div>
                      {/* <div className="w-3/12"><p className="text-center text-[0.9em] ">{item.allergies?.length > 0?? "---"}</p></div> */}
                      <div className="relative w-2/12 min-w-[70px] text-[15px] pr-2">
                        <Menu
                          menuButton={
                            <MenuButton
                              className={`rounded px-4 py-1 bg-[#0096D1] text-gray-100`}
                            >
                              Options
                            </MenuButton>
                          }
                        >
                          <div className="border-2 w-44 bg-white cursor-pointer
                          absolute top-0 z-[2] -left-[84px]">
                            {/* <MenuItem onClick={()=> { login(item.id) }} className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}>Log In</MenuItem>
                          <MenuItem onClick={()=> { logout(item.id) }} className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}>Log Out</MenuItem> */}
                            <MenuItem
                              onClick={() => {
                                /*dispatch(selectStudent(item.id));*/ navigate(
                                  `/admin/child-activities/${item.id}`
                                );
                              }}
                              className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}
                            >
                              View Activites
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setEditForm(item);
                                setEditModeType("child");
                              }}
                              className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}
                            >
                              Edit profile
                            </MenuItem>

                            {parentsLength.length < 2
                            &&
                            <MenuItem
                            onClick={() => {
                              setEditForm(item);
                              setEditModeType("parent");
                            }}
                            className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}
                          >
                            Add Parent
                          </MenuItem> }

                            <MenuItem
                              onClick={() => {
                                setEditForm(item);
                                setEditModeType("pickup");
                                getEmergencyContact(item.id);
                              }}
                              className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}
                            >
                              Add Emergency Contact
                            </MenuItem>
                            <MenuItem
                              className={`bg-white border-2 m-1 text-[14px] font-normal hover:text-amber-500`}
                              onClick={() => handleArchieve(item.id)}
                            >
                              Archive
                            </MenuItem>

                          </div>
                        </Menu>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex justify-center my-4 text-lg ">
                  No data found...
                </div>
              )}
              </>

  );
};
