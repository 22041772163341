import React from 'react'
import { Link } from 'react-router-dom'

const PaymentOnboarding = () => {
  return (
    <div>paymentOnboarding


    </div>
  )
}

export default PaymentOnboarding