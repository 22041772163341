import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/main.css";
import { RegisterCard } from "../components/RegisterCard";
import { First } from "../components/registerStudent/First";
import { Second } from "../components/registerStudent/Second";
import Third from "../components/registerStudent/Third";
import "../assets/css/studentRegistration.css";
import { useState } from "react";
import BackButton from "../components/backButton";
import { useNavigate } from "react-router-dom";
import { defaultUrl } from "../features/actions";
import { uploadImageToCloudinary } from "../utilities/importantFns";
import { makePostRequest } from "../utilities/common";

export const RegisterStudent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    surname: "",
    firstname: "",
    othername: "",
    gender: "",
    date_of_birth: "",
    email: "",
    phone_number: "",
    classroom_id: "",
    house_address: "",
    city: "",
    state: "",
    country: "",
    admission_date: "",
    zip_code: "",
    profile_picture: "",
    parent_id: [""],
    contact_name: [""],
    phone: [""],
    relationship: [""],
    condition: ["none"],
    extent: [],
  });

  const [step, setStep] = useState(1);

   const handleChange = ({ name, value, files }: any) => {
    setForm({ ...form, [name]: files?.length>0? files[0] : value });
  };


  const handleSubmit = async (parentIDs: string[]) => {
    // Make shallow copy of the form
    const formData = form;
    setLoading(true);

    // if media is available
    let profile_pictureString;
    if (formData.profile_picture) {
      profile_pictureString = await uploadImageToCloudinary(formData.profile_picture);
      formData.profile_picture = profile_pictureString ||  "";
    }

    const body = {
        surname: form.surname.trim(),
        firstname: form.firstname.trim(),
        othername: form.othername.trim(),
        gender: form.gender.trim(),
        date_of_birth: form.date_of_birth.trim(),
        email: form.email.trim(),
        phone_number: form.phone_number.trim(),
        classroom_id: form.classroom_id.trim(),
        house_address: form.house_address.trim(),
        city: form.city.trim(),
        state: form.state.trim(),
        country: form.country.trim(),
        admission_date: form.admission_date.trim(),
        zip_code: form.zip_code.trim(),
        profile_picture: formData.profile_picture.trim(),
        parent_id: parentIDs,
        contact_name: [form.contact_name],
        phone: [form.phone],
        relationship: [form.relationship],
        condition: [form.condition],
        extent: [form.extent],
    }


    await makePostRequest(`${defaultUrl}/students/create`, body)
    setLoading(false);

    setForm({
      surname: "",
      firstname: "",
      othername: "",
      gender: "",
      date_of_birth: "",
      email: "",
      phone_number: "",
      classroom_id: "",
      house_address: "",
      city: "",
      state: "",
      country: "",
      admission_date: "",
      zip_code: "",
      profile_picture: "",
      parent_id: [""],
      contact_name: [""],
      phone: [""],
      relationship: [""],
      condition: [],
      extent: [],
    });

    navigate(-1);
    navigate("/admin/student/student_data");

  };


  const nextStep = (page: number) => {
    const newStep = page ? page : step + 1;
    setStep(newStep);
  };

  const render = () => {
    switch (step) {
      case 1:
        return (
          <First
            nextStep={nextStep}
            handleChange={handleChange}
            form={form}
          />
        );

      case 2:
        return (
          <Third
            nextStep={nextStep}
            handleChange={handleChange}
            form={form}
            loading={loading}
          />
        );

      case 3:
        return (
          <Second
            nextStep={nextStep}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            form={form}
            loading={loading}
            setLoading={setLoading}
          />
        );

      default:
        return (
          <First
            nextStep={nextStep}
            handleChange={handleChange}
            form={form}
          />
        );
    }
  };

  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Enroll Pupil Form" />
      </div>

      <div className="studentReg max-h-[90vh] overflow-y-auto overflow-x-hidden register-scroll">
        <div className="studentRegisterCard">
          <RegisterCard
            title="Pupil"
            details="Enter Pupil data"
            activeDetails="Active Enrolled Pupil"
            activeNumber={180}
          />
        </div>
        <div className="mainReg">{render()}</div>
      </div>
    </>
  );
};
