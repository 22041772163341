import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { parentInterfaceWithToken, studentRegisterInterfaceWithToken } from "../types/interfaces";

let url : any = 'https://api.pendulumafrica.com'
if(process.env.REACT_APP_ENV === "development"){
   url = process.env.REACT_APP_DEV_BASE_URL
}

export let defaultUrl = url



const config = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export interface detailsInterface {
  email: string;
  password: string;
}

// const details:detailsInterface = {email: "olayemi289@gmail.com", password: "password"}


export const loginThunk = createAsyncThunk("/login/loginthunk", async ({email, password}:any, {rejectWithValue}) => {

        try {

                const {data}:any  = await axios.post(`${defaultUrl}/login`, {email, password}, config)

                // console.log({data: data.data, token: data.token})

                sessionStorage.setItem('token', data.token);
                sessionStorage.setItem('userData', JSON.stringify(data.data));
                return {data: data.data, token: data.token};

        }
        catch(err:any){
                if(err.response && err.response.data.message) return rejectWithValue(err.response.data.message)
                return err.message
        }

})

export const registerStudentThunk = createAsyncThunk('register/student', async ({studentRegisterData, token}:studentRegisterInterfaceWithToken, {rejectWithValue}) => {
        try {

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

                const receivedPayload = await axios.post(`${defaultUrl}/students/create`, studentRegisterData, config)

                // console.log("receivedPayload")
                // console.log(receivedPayload)
                return receivedPayload

        }
        catch(err: any) {
                if(err.response && err.response.data.message) return rejectWithValue(err.response.data.message)

                return err.message
        }
})


export const registerParentThunk = createAsyncThunk('registerParentThunk', async ({parent, token}:parentInterfaceWithToken, {rejectWithValue}) => {

        try {

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

                const response = await axios.post(`${defaultUrl}/parents/create`, parent, config )

                return response

        }
        catch(err:any){
                if(err.response && err.response.data.message) return rejectWithValue(err.response.data.message)

                return err.message

        }

})


export const resetPasswordThunk = createAsyncThunk('forgot-password', async ({email, method}:{email: string, method: string}, {rejectWithValue}) => {
        try {
                const response = await axios.post(`${defaultUrl}/forgot-password`, {email, method}, config)

                return response
        }

        catch (err: any) {
                if(err.response && err.response.data.message) return rejectWithValue(err.response.data.message)

                return err.message
        }
});

export const fetchStudentsThunk = createAsyncThunk('fetchStudentList', async ({token}:{token: string}, {rejectWithValue}) => {

})

export let openConversationThunk = createAsyncThunk('message/openConversation', async (convo:any, {getState}:any )=> {
        let {token} = getState().login.data;
        let sessionToken = sessionStorage.getItem("token");

        if (convo.conversation_id) {
                return axios.get(`${defaultUrl}/conversations/${convo.conversation_id}`, {
                        headers: {
                                Authorization: token? 'Bearer ' + token : 'Bearer ' + sessionToken
                        },
                })
                .then((result)=> {
                        let messages = result.data.data?.reverse();
                        sessionStorage.setItem('messageBoxDetails', JSON.stringify({
                                convo,
                                conID: convo.conversation_id,
                                recipient: convo.recipient,
                                recipientID: convo.recipient_id,
                                messages: result.data.data,
                        }));
                        return {
                                convo,
                                conID: convo.conversation_id,
                                recipient: convo.recipient,
                                recipientID: convo.recipient_id,
                                messages: messages
                        }
                })
                .catch((error)=> {
                        console.log({error: error.response.data || error})
                })
        } else {
                return {
                        conID: null,
                        recipient: convo.recipient,
                        messages: [],
                        recipientID: convo.recipient_id,
                }

        }
});

export let fetchConversationsThunk = createAsyncThunk('message/fetchConversation', async (_, {getState}:any )=> {
        let {token} = getState().login.data;
        let sessionToken = sessionStorage.getItem("token");
        return axios.get(`${defaultUrl}/conversations`, {
                headers: {
                        Authorization: token? 'Bearer ' + token : 'Bearer ' + sessionToken
                },
        })
        .then((result)=> {
                // console.log({threads: result.data.data});
                return {conversations: result.data.data};
        })
        .catch((error)=> {
                console.log({error: error.response.data || error})
        })
});


export async function fetchMedia(token: string){

        try {
          let {data: dataObj} = await axios.get(`${defaultUrl}/activity_feed?select=image&per_page=100` , {
            headers: {
              Authorization: 'Bearer ' + token
            },
          });

          let data = dataObj.data;
        //   console.log({data})

          let fullImageList: any[] = [];
          let fullVideoList: any[] = [];

          data.forEach((item:any) => {
            if (Array.isArray(item)){
              return;
            }

            let image = item.image[0] === "null" ? null : item.image[0];
            let video = item.video === "null" ? null : item.video;
            if(image) {
              fullImageList.push({uri: image})
            }
            if(video) {
              fullVideoList.push({uri: video})
            }
          });
          return {
            images: fullImageList,
            videos: fullVideoList
          }
        } catch (error:any) {
          console.log({error: error.response || error});
        }
}
