import { AddUserCard } from "../components/AddUserCard";
import { TopAdminNav } from "../layouts/TopAdminNav";

export const Staff = () => {
  return (
    <>
      <div className="w-full">
        <TopAdminNav name="Jone Doe" title="Staff Management" />
      </div>

      <div className="flex flex-wrap items-center pt-4">
        <AddUserCard
          title="Add Staff"
          content="Create new staff data"
          link="/admin/staff/register"
        />
        {/* <AddUserCard
          title="Activity Planner"
          content="View all activities"
          link="/admin/staff/lesson_note"
        /> */}

        <AddUserCard
          title="Staff Data"
          content="View/ edit staff"
          link="/admin/staff/staff_data"
        />
      </div>
    </>
  );
};
