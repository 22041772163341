import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const BackButton = ()=> {
    const navigate = useNavigate();
    return (
        <div onClick={()=> navigate(-1)} className="sks px-4 py-3 mr-2 rounded text-white flex items-center justify-center bg-[#012063]">
          <HiOutlineArrowLeft />
        </div>
    )
}

export default BackButton;