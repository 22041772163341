import { Link } from "react-router-dom";
import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { FaUserEdit } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faAdd, faSearch } from "@fortawesome/free-solid-svg-icons";
import BackButton from "../components/backButton";

export const LessonNote = () => {
  const studentData = [
    {
      name: "Clasroom One",
      avatar:
        "https://images.unsplash.com/photo-1559179868-da5aa82ba4d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YXxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
      createDate: "14-10-1990",
      chiefStaff: "",
      students: [
        /**Student objects */
      ],
    },
    {
      name: "Clasroom Two",
      avatar:
        "https://images.unsplash.com/photo-1559179868-da5aa82ba4d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YXxlbnwwfDJ8MHx8&auto=format&fit=crop&w=500&q=60",
      createDate: "14-10-1990",
      chiefStaff: "",
      students: [
        /**Student objects */
      ],
    },
  ];
  useEffect(() => {
    const searchBar = document.getElementById("searchAttendanceInput");
    searchBar?.focus();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [classRoomFilter, setClassRoomFilter] = useState("");
  const [filteredData, setFilteredData] = useState(studentData);

  function filterData() {
    const filtered = studentData.filter((data) => {
      if (!searchTerm.length) {
        return data;
      }
      console.log(searchTerm, data.name.includes(searchTerm));
      return data.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    // .filter(data=> {
    //         if (!classRoomFilter.length) {
    //                 return data;
    //         }

    //         return data.classRoom.name.toLowerCase() === classRoomFilter.toLowerCase();
    // });

    console.log({ filtered });
    return filtered;
  }

  useEffect(() => {
    setFilteredData(filterData());
  }, [searchTerm, classRoomFilter]);
  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Activity Planner" />
      </div>

      <div className="flex">
        <div className="block w-full h-[87vh] overflow-auto register-scroll">
          <div className="flex">
            <div className="w-full">
              <RegisterCard
                title="All activity uploads"
                details="View Lesson plans"
              />
            </div>
          </div>
          <div className="rounded-lg border-2 border-gray-100 px-3 !pb-0 !pt-[0.05px] mt-7 attendance_bg">
            <div className="flex flex-wrap justify-start mr-6 px-2 md:justify-between text-[#1f1f1f]/[0.7] md:mt-6 mb-3 w-[98%] ">
              <div className="w-3/12 w-full max-w-[240px] min-w-max">
                <select
                  className="border-2 border-gray-200 rounded-2xl w-full max-w-[240px] min-w-[214px] mr-2 p-2 py-2 text-center focus:outline-none"
                  defaultValue={"all"}
                >
                  <option value="all">All Classrooms</option>
                  <option value="one">Classroom One</option>
                  <option value="two">Classroom Two</option>
                </select>
              </div>
              <div className="float-right w-4/12 min-w-max mt-6 md:mt-0">
                <div className="flex md:justify-end">
                  <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 w-10/12 min-w-[200px]">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                      id="searchAttendanceInput"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search name"
                      className="rounded-2xl pl-4 p-2 w-full focus:outline-none "
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="w-3/12 w-full max-w-[240px] min-w-max flex items-center">
                <Link to="/admin/staff/lesson_note/upload">
                  <button className="bg-[#0096D1] text-white float-right rounded-[12px] py-2 px-3">
                    {" "}
                    <FontAwesomeIcon icon={faAdd} className="mr-2" />
                    Add Activity
                  </button>
                </Link>
              </div>
            </div>
            <div className="text-[#012063CC] mt-1 p-4 px-2">
              <div className=" w-full">
                <div className="w-full text-center">
                  <div className="flex w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
                    <div className="w-3/12 text-[0.9em] font-bold flex justify-center">
                      Class Name
                    </div>
                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Week
                    </div>
                    <div className="w-3/12 text-[0.9em] font-bold flex justify-center">
                      Date Submitted
                    </div>
                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Teacher Name
                    </div>
                    {/* <div className="w-2/12 min-w-[70px] text-[0.8em]"></div> */}
                  </div>
                  <div className="h-[58vh] w-[98%] !min-w-[700px]  overflow-auto register-scroll">
                    {studentData &&
                      filteredData.map((item, index) => {
                        return (
                          <div className="border !min-w-[700px] text-center font-semibold text-[#012063]/[0.7] text-gray-600 bg-white py-3 rounded-xl mt-[5px]">
                            <div className="flex items-center w-full border-blue-500">
                              <div className="w-3/12">
                                <p className="text-center text-[15px] text-[#012063CC]">
                                  {item.name}
                                </p>
                              </div>
                              <div className="w-2/12">
                                <p className="text-center text-[0.9em] min-w-max">
                                  {item.students.length || 10}
                                </p>
                              </div>
                              <div className="w-3/12">
                                <p className="text-center text-[0.85em]">
                                  {item.createDate}
                                </p>
                              </div>
                              <div className="w-2/12">
                                <p className="text-center text-[0.9em]">
                                  {item.chiefStaff || "Mr Quadri FED"}
                                </p>
                              </div>
                        
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
