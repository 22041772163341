
import ReactApexChart from 'react-apexcharts';

const LineChart = ({ xaxis, data, title }) => {
    //console.log({ xaxis, data });
    const options = {
        series: data.map((item)=>{
            return {
                name: item.name,
                data: item.data
            }
        }),   
        
        colors: ['#D7320D', '#078eb0', '#40d5d3', '#078eb0', '#1ea5ee', '#055a70', '#09add6'],
        chart: {
            height: 350,
                type: 'line',
                dropShadow: {
                  enabled: true,
                  color: '#000',
                  top: 18,
                  left: 7,
                  blur: 10,
                  opacity: 0.2
                },
                toolbar: {
                  show: false
                }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                //console.log({val})
                return "₦ " + val + "m";
            },
        },
        stroke: {
            curve: 'straight',
            width: 2,
            lineCap: 'butt'
        },
        title: {
            text: title,
            align: 'left',
            style: {
                fontFamily: 'roboto, sans-serif',
                fontSize: '18px',
            }
        },
        noData: {
            text: 'No data to show (...for now)',
            align: 'center',
        },
        /*grid: {
            row: {
                colors: ['#41B883', '#afafaf', '#57a850'],
                opacity: 0.5
            }
        },*/
        yaxis: {
            show: true,
            label: {
                show: true,
                formatter: (value) => { return value + '%'}
            },
            title: {
                text: 'Percentage coverage ( % )',
                style: {
                    fontFamily: 'roboto, sans-serif',
                    fontSize: '11px',
                }
            }
        },
        xaxis: {
            categories: xaxis,
        }
    };

    return (
        <div>
            <ReactApexChart options={options} series={options.series} height={300} />
        </div>
    )

};

export default LineChart;