
function ContactList({item}: any) {
  return (
    <div  className="border !min-w-[700px] text-center text-[#012063]/[0.7] text-gray-600 bg-white py-3 rounded-xl mt-[5px]">
    <div className="flex items-center w-full border-blue-500">
      <div className="w-2/12 text-[0.9em] font-bold flex justify-center min-h-[20px]">
        <div className="h-20 w-20 overflow-auto rounded-full">
          {item?.profile_picture}
        </div>
      </div>
      <div className="w-3/12">
        <p className="text-center text-[15px] text-[#012063CC] font-semibold">
          {item?.name}
        </p>
      </div>
            {item?.parents
                &&
                <div className="w-3/12">
                <p className="text-left text-[0.85em]">
                  <span className="capitalize font-semibold">
                    {item?.parents[1]?.name}
                  </span>{" "}
                  <br />
                  {item?.parents[1]?.phone}
                  <br />
                  <b>{item?.parents[1]?.email?.slice(0,20)}
                  <br />
                  {item?.parents[1]?.email?.slice(20)}</b>
                  <br />
                  {item?.parents[1]?.relationship}
                </p>
              </div>
            }
         {item?.parents &&
        <div className="w-4/12">
        <p className="text-left text-[0.85em]">
          <span className="capitalize font-semibold">
            {item?.parents[0]?.name}
          </span>
          <br />
          {item?.parents[0]?.phone}
          <br />
          <b>{item?.parents[0]?.email?.slice(0,25)}
          <br />
          {item?.parents[0]?.email?.slice(25)}</b>
          <br />
          {item?.parents[0]?.relationship}
        </p>
      </div>}
    </div>
  </div>
  )
}

export default ContactList
