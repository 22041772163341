import React from "react";
import "../assets/css/setting.css";

const Onboarding = () => {
  return (
    <div className="schProfile">
      <div className="inputDv">
        <div className="input">
          <label>Public Key</label>
          <input type="text" />
        </div>
        <div className="input">
          <label>Private key</label>
          <input type="text" />
        </div>
        <div className="input">
          <label>Contract code</label>
          <input type="text" />
        </div>
        <div className="input">
          <label>Webhook URL</label>
          <input type="text" />
        </div>

        <div className="cameraBtn1">
          <button>Save Changes</button>
          <button className="btn2">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
