import { RegisterCard } from "../components/RegisterCard"
import { TopAdminNav } from "../layouts/TopAdminNav"
import '../assets/css/main.css'


export const CreateQuizTick = () => {
        return (
                
                <>
                        <div className='w-full'>
                                <TopAdminNav name="Jane Doe" title="Create Quiz"  />
                        </div>

                        <div className='flex'>
                                <div className='px-5 block w-full h-[87vh] overflow-auto register-scroll'>
                                        <div className="flex">
                                                <div className="w-7/12">
                                                        <RegisterCard title="Upload your quiz" details="Quiz for student now"  />
                                                </div>
                                                <div className="w-5/12">
                                                </div>
                                        </div>
                                        <div className='text-[#012063] attendance_bg m-5 mt-1 p-5'>
                                                
                                                <div className="w-full">
                                                        <div className="w-full text-center">
                                                                
                                                                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 mb-5 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2">
                                                                        <div className=" block w-full py-3 px-5">
                                                                                <h2 className="font-bold text-[#012063]/[0.7] text-left"><span className="pr-3">1.</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                                                                                <div className="block mt-10 w-[400px]">
                                                                                        <div className="mx-2 px-3 py-1 my-5 font-medium text-[#1F1F1F]/[0.6] text-[15px] flex"> 
                                                                                                <div className="w-[30px] h-[30px] border-2 border-[#ccc]/[0.7] rounded-full p-[5px] mx-2">
                                                                                                        <div className="bg-[#25D366] h-full w-full rounded-full"></div>
                                                                                                </div> 
                                                                                                <div className="border-2 border-[#ccc]/[0.7] rounded-2xl px-2 py-1"> (A) Lorem ipsum dolor sit ame </div> 
                                                                                        </div>
                                                                                        <div className="mx-2 px-3 py-1 my-5 font-medium text-[#1F1F1F]/[0.6] text-[15px] flex"> 
                                                                                                <div className="w-[30px] h-[30px] border-2 border-[#ccc]/[0.7] rounded-full p-[5px] mx-2">
                                                                                                        <div className="bg-[#fff] h-full w-full rounded-full border-2"></div>
                                                                                                </div> 
                                                                                                <div className="border-2 border-[#ccc]/[0.7] rounded-2xl px-2 py-1"> (A) Lorem ipsum dolor sit ame </div> 
                                                                                        </div>
                                                                                        <div className="mx-2 px-3 py-1 my-5 font-medium text-[#1F1F1F]/[0.6] text-[15px] flex"> 
                                                                                                <div className="w-[30px] h-[30px] border-2 border-[#ccc]/[0.7] rounded-full p-[5px] mx-2">
                                                                                                        <div className="bg-[#fff] h-full w-full rounded-full border-2"></div>
                                                                                                </div> 
                                                                                                <div className="border-2 border-[#ccc]/[0.7] rounded-2xl px-2 py-1"> (A) Lorem ipsum dolor sit ame </div> 
                                                                                        </div>
                                                                                        <div className="mx-2 px-3 py-1 my-5 font-medium text-[#1F1F1F]/[0.6] text-[15px] flex"> 
                                                                                                <div className="w-[30px] h-[30px] border-2 border-[#ccc]/[0.7] rounded-full p-[5px] mx-2">
                                                                                                        <div className="bg-[#fff] h-full w-full rounded-full border-2"></div>
                                                                                                </div> 
                                                                                                <div className="border-2 border-[#ccc]/[0.7] rounded-2xl px-2 py-1"> (A) Lorem ipsum dolor sit ame </div> 
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        
                                                        </div>

                                                </div>
                                        </div>

                                </div>
                        </div>
                </>
        )
}