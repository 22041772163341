import React, { useState } from 'react'
import { faqs } from './mockup'
import { IoIosArrowDown } from 'react-icons/io';

function LandingFAQs() {

    const [answersShow, setAnswersShow] = useState(Array.from({ length: faqs.length }, () => false));

    const handleToggle = (index: number) => {
        const newList = [...answersShow]
        newList[index] = !newList[index]
        setAnswersShow(newList)
    }




    return (
        <div className='px-5 pb-4'>
            <h2 className='text-4xl text-center font-bold text-[#05729d]'>FAQs</h2>

            <div className='my-4 flex flex-col items-center justify-center'>
                {faqs.map(({ Q, A }, idx) => {
                    return (<div
                        data-aos={idx % 2 === 0 ? "fade-right" : "fade-left"}
                        key={idx} className='w-full sm:w-[80%] my-7'>
                        <button
                            onClick={() => {
                                handleToggle(idx)
                            }}
                            className='flex justify-between p-2.5 sm:p-4 w-full
                            rounded-xl bg-gray-200 shadow-lg shadow-gray-500 text-left sm:text-xl'>
                            <span className=''>Q: {Q}</span>
                            <IoIosArrowDown className={`ml-2 text-3xl my-auto transition-transform
                             duration-300
                        ${answersShow[idx] ? 'transform rotate-180' : ''} `} />
                        </button>

                        <div
                            className={`overflow-hidden transition-[max-height] duration-300
                                 ease-in-out
                         ${answersShow[idx] ? 'max-h-96' : 'max-h-0'}
                         `}>
                            <div className='my-6 p-4'>
                                <p className='sm:text-xl'>A: {A}</p>
                            </div>

                        </div>

                    </div>)
                })}
            </div>
        </div>
    )
}

export default LandingFAQs
