import React from 'react'
import BackButton from '../components/backButton'
import { TopAdminNav } from '../layouts/TopAdminNav'
import { RegisterCard } from '../components/RegisterCard'
import ReportCardGenerator from '../components/ReportCard/Primary/ReportCardGenerator'

function GenerateReportCard() {
  return (
    <div style={{ position: "relative" }}>
    <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Generate Academic Report" />
    </div>

    <div className="flex">
        <div className="block w-full">
            <div className="flex">
                <div className="w-full">
                    <RegisterCard
                        title="Generate Pupil's Academic report"
                        details=""
                    />
                </div>
            </div>

            <div>
                <ReportCardGenerator />
            </div>

        </div>
    </div>


</div>
  )
}

export default GenerateReportCard