import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { Link } from "react-router-dom";
import "../assets/css/main.css";
import { useState } from "react";
import BackButton from "../components/backButton";

export const LessonNoteUpload = () => {
  const [activities, setActivities] = useState([
    {
      subject: "Mathematics",
      mon: "",
      tue: "",
      wed: "",
      thur: "",
      fri: "",
    },
  ]);

  const addSubject =()=> {
    setActivities(prev=> [...prev, {
      subject: "subject",
      mon: "",
      tue: "",
      wed: "",
      thur: "",
      fri: "",
    }])
  }

  const handleInputChange =(value: string, activityIndex: number, property: string)=> {
    const stateCopy = [...activities];
    stateCopy[activityIndex][property as keyof typeof activities[0]] = value;
    console.log(stateCopy);
    setActivities(stateCopy);

  }

  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Upload Activities" />
      </div>

      <div className="flex">
        <div className="block w-full h-[87vh] overflow-auto register-scroll">
          <div className="w-full">
            <RegisterCard
              title="Upload your Activities"
              details="Curate and upload your weekly activities"
            />
          </div>

          <div className="studentData mt-7">
            <div className="activitiTop px-1">
              <div className="actfor">
                <span>Activity For</span>
                <p className="!rounded-lg">12-03-2023</p>
              </div>
              <div className="actfor">
                <span>To</span>
                <p className="!rounded-lg">27-03-2023</p>
              </div>

              <div className="actfor">
                <span>Week No</span>
                <p className="!rounded-lg">3</p>
              </div>
            </div>

            <div className="">
              <div className="flex">
                <div className="rounded bg-[#012063] m-1 w-1/6 min-w-max p-2 text-center text-white"><p>Subject</p></div>
                <div className="rounded bg-[#0096D1] m-1 w-1/6 min-w-max p-2 text-center text-white"><p>Mon</p></div>
                <div className="rounded bg-[#0096D1] m-1 w-1/6 min-w-max p-2 text-center text-white"><p>Tues</p></div>
                <div className="rounded bg-[#0096D1] m-1 w-1/6 min-w-max p-2 text-center text-white"><p>Wed</p></div>
                <div className="rounded bg-[#0096D1] m-1 w-1/6 min-w-max p-2 text-center text-white"><p>Thurs</p></div>
                <div className="rounded bg-[#0096D1] m-1 w-1/6 min-w-max p-2 text-center text-white"><p>Fri</p></div>
              </div>
              <div>
                {/**Map all activities as it is created #D0DFFF*/}
                <div>
                {
                  activities?.map((activity, index)=>{
                    return (
                      <div className="flex">
                        <div className="rounded bg-[#D0DFFF] m-1 w-1/6 max-w-1/6 text-[#1F1F1F]"><input onChange={(e)=> handleInputChange(e.target.value, index, "subject")} value={activity.subject} className="p-2 text-sm text-center w-full bg-transparent focus:outline-none"/></div>
                        <div className="rounded bg-[#f5f5f5] m-1 w-1/6 max-w-1/6 text-[#1F1F1F]"><input onChange={(e)=> handleInputChange(e.target.value, index, "mon")} value={activity.mon} className="p-2 text-sm text-center w-full bg-transparent focus:outline-none"/></div>
                        <div className="rounded bg-[#f5f5f5] m-1 w-1/6 max-w-1/6 text-[#1F1F1F]"><input onChange={(e)=> handleInputChange(e.target.value, index, "tue")} value={activity.tue} className="p-2 text-sm text-center w-full bg-transparent focus:outline-none"/></div>
                        <div className="rounded bg-[#f5f5f5] m-1 w-1/6 max-w-1/6 text-[#1F1F1F]"><input onChange={(e)=> handleInputChange(e.target.value, index, "wed")} value={activity.wed} className="p-2 text-sm text-center w-full bg-transparent focus:outline-none"/></div>
                        <div className="rounded bg-[#f5f5f5] m-1 w-1/6 max-w-1/6 text-[#1F1F1F]"><input onChange={(e)=> handleInputChange(e.target.value, index, "thur")} value={activity.thur} className="p-2 text-sm text-center w-full bg-transparent focus:outline-none"/></div>
                        <div className="rounded bg-[#f5f5f5] m-1 w-1/6 max-w-1/6 text-[#1F1F1F]"><input onChange={(e)=> handleInputChange(e.target.value, index, "fri")} value={activity.fri} className="p-2 text-sm text-center w-full bg-transparent focus:outline-none"/></div>
                      </div>
                    )
                  })
                }
                </div>
                <span onClick={addSubject} className="underline m-1 text-[14px] text-[#FA614C]">Add Subject</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
