import { AiOutlineBell } from "react-icons/ai";
import { TopAdminNav } from "./TopAdminNav";
import staffImage from '../assets/image/staff-image.svg'
import useWindowDimensions from "../hooks/useWindowSize";

interface IAdminTopBar {
  title: string;
  name?: string;
}
const AdminTopBar = (props: IAdminTopBar) => {
  const { title, name } = props;
  const { width } = useWindowDimensions();
  const isMobileScreen = width < 520;
  return (
    <div className="admin-nav !mb-2 !pr-2 !border-b-[1px]">
      <p>{title}</p>

      <div className="" style={{display: `${isMobileScreen ? 'none' :'flex'}`}}>
        <button className="!max-w-[171px] !h-[60px]">
          <AiOutlineBell className="navIcon" />
          <span>Notifications</span>
        </button>

        <button className="!max-w-[250px] !h-[60px]">
          <div className="rounded-full h-[50px] w-[50px] overflow-hidden">
            {/* <AdvancedImage style={{height: '100%', width: '100%'}} cldImg={myImage} /> */}
            <img
              style={{ height: "100%", width: "100%" }}
              src={staffImage}
              alt="staff"
            />
          </div>
          <span className="text-xs max-w-max">{name}</span>
        </button>
      </div>
    </div>
  );
};

export default AdminTopBar;
