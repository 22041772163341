import cardCircle from "../assets/circle-card.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
        faArrowsRotate,
        faGear,
        faCaretDown,
 } from '@fortawesome/free-solid-svg-icons'


interface AdminDashboardCardProps {
        title?: string,
        icon?: JSX.Element,
        isFullWidth?: boolean,
        hideOnXL?: boolean,
        first: string,
        second?: string,
        children: JSX.Element,
        link_to?: string
}

export const StaffDashboardCard = ({title, icon, isFullWidth, hideOnXL,
         first, second, children, link_to}:AdminDashboardCardProps) => {
        return (
                <div className={`max-w-[100%] w-[300px] md:w-[280px] xl:w-[350px] md:min-w-[180px]
                 ${isFullWidth? "lg:w-[96%] max-w-[96%] hidden lg:block" : "border-2 border-blue-50 "}
                  h-[300px] md:h-[300px] rounded-[20px] bg-[#f3fafd] bg-opacity-50 relative
                  overflow-hidden px-2 py-5 mx-2 mt-5 shadow-lg`}>
                        {!isFullWidth &&
                        <div className="absolute -top-7 -right-5 opacity-90">
                                <img src={cardCircle} alt="" className="z-0" />
                                <span className="absolute top-[55px] right-[70px] h-9 w-9">{icon??null}</span>
                        </div>}
                        <div className="block z-10">
                                <div className="flex items-center justify-between ">
                                        <div className="">
                                                <h2 className="text-[15px] md:text-[18px]
                                                 font-semibold text-[#012063]">{title || first} </h2>
                                                <h2 className="text-[15px] md:text-[18px]
                                                font-semibold text-[#012063]">{second??null}</h2>
                                        </div>
                                        {isFullWidth &&
                                        <div className=" flex items-center px-2">
                                                <p className="text-sm text-gray-500 mr-4">
                                                Last refreshed: <span>07:04:50 PM</span></p>
                                                <div><FontAwesomeIcon icon={faArrowsRotate} /></div>
                                                <button className="rounded bg-[#012063]
                                                 opacity-80 ml-4 p-2 text-[13px] text-gray-200 ">
                                                Options
                                                 <span className="ml-2"><FontAwesomeIcon icon={faGear} />
                                                 <FontAwesomeIcon icon={faCaretDown} /></span></button>
                                        </div>}
                                </div>
                        </div>
                        <div className="flex items-center z-10">
                                {children}
                        </div>
                </div>
        )
};
