import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/main.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaPen } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BackButton from "../components/backButton";
import { StaffDataType } from "./StaffData";
import CustomModal from "../components/CustomModal/CustomModal";
import { EditClassForm } from "../layouts/editForms/classroom";
import { defaultUrl } from "../features/actions";
import toast from "react-hot-toast";
import TableLoader from "../components/TableLoader";
import { makeFetchRequest, makePostRequest } from "../utilities/common";

export interface Classroom {
  id: string;
  school_id?: string;
  teachers: StaffDataType[];
  name?: string;
  number_of_students?: number;
}

export const Classes = () => {
  const [editMode, setEditMode] = useState(false);
  const [editForm, setEditForm] = useState<Classroom>({ id: "", teachers: [] });
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**DATA FETCHING */
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);
  const [staffData, setStaffData] = useState<StaffDataType[]>([]);


  /** FILTERINGS */
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(classrooms);

  async function fetchStaffData() {
    const response = await makeFetchRequest(`${defaultUrl}/teachers`, false, true);
    setStaffData(response);
    return response;
  }

  const fetchClassrooms = async () => {
    setIsLoading(true);
    try {
      const response = await makeFetchRequest(`${defaultUrl}/classroom`);
      toast.remove()
      toast.success("Success")
      // console.log(response)
      const staffList = await fetchStaffData();
      const filtered =
        response &&
        response.filter((data: any) => {
          if (!searchTerm.length) {
            return data;
          }
          return data?.name?.toLowerCase().includes(searchTerm.toLowerCase());
        });

      // SORT RESPONSE BY CLASS NAME
      const sortByName =
        filtered &&
        filtered.sort(function (a: any, b: any) {
          return a?.name.localeCompare(b.name);
        });

      const data =
        sortByName &&
        sortByName.map((item: any) => {
          return {
            ...item,
            teachers:
              item.teachers.map(
                (staffID: any) =>
                  staffList &&
                  staffList.find((staff: any) => staff?.id === staffID)
              ) || [],
          };
        });

      setIsLoading(false);

      setFilteredData(data);
      return setClassrooms(data || []);
    } catch (err: any) {
      setIsLoading(false);
      toast.error(
        "Failed to fetch Classrooms, Please refresh this page to try again."
      );
    }
  };



  useEffect(() => {
    const searchBar = document.getElementById("searchAttendanceInput");
    searchBar?.focus();
    fetchClassrooms();

  }, [searchTerm, isSaving]);


  /** ACTIONS */
  function handleClickEdit(data: any) {
    setEditForm(data);
    setEditMode(true);
  }

  function handleInputChange({ target: { name, value, files } }: any) {
    setEditForm({
      ...editForm,
      [name]: files ? URL.createObjectURL(files[0]) : value,
    });
    return;
  }


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSaving(true);

    let unfilteredTeachers =
      typeof editForm.teachers === "string"
        ? [editForm?.teachers]
        : [editForm?.teachers[0]?.id];

    let teachers = unfilteredTeachers && unfilteredTeachers.filter(Boolean)


    if(teachers.length > 0){
      await makePostRequest(`${defaultUrl}/classroom/${editForm.id}`,{ ...editForm,teachers,})
      fetchClassrooms();
      setEditForm({ id: "", teachers: [] });
      setEditMode(false);
    }else{
      toast.error("You need to assign a staff.")
    }
    setIsSaving(false);
  };



  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Classrooms" />
      </div>

      <div className="flex">
        <div className="block w-full h-[87vh] overflow-auto register-scroll">
          <div className="flex items-center justify-between">
            <div className="w-10/12 mr-3">
              <RegisterCard
                title="Manage Classrooms"
                details="View, Edit and Create classrooms"
                activeNumber={10}
                activeDetails="Active Class"
              />
            </div>
            <div className="w-2/12 upBtn">
              <Link to="/admin/class/add" className="">
                <button className="bg-[#0096D1] text-white float-right rounded-[12px]">
                  {" "}
                  <img
                    className="inline"
                    src="../../icons/edit.png"
                    alt="edit"
                  />
                  Add Class
                </button>
              </Link>
            </div>
          </div>

          <div className="rounded-lg border-2 border-gray-100 px-3 !pb-0 !pt-[0.05px] mt-7 attendance_bg">
            <div className="flex flex-wrap justify-start px-2 md:justify-between text-[#1f1f1f]/[0.7] md:mt-6 mb-3 w-[98%] ">
              <div className="float-left w-1/2 min-w-max mt-6 md:mt-0">
                <div className="flex md:justify-end">
                  <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 w-10/12 min-w-[200px]">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                      id="searchAttendanceInput"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search name"
                      className="rounded-2xl pl-4 p-2 w-full focus:outline-none "
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-[#012063CC] mt-1 p-4 px-2">
              <div className=" w-full">
                <div className="w-full text-center">
                  <div className="flex w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
                    <div className="w-4/12 text-[0.9em] font-bold flex justify-center">
                      Class Name
                    </div>
                    <div className="w-3/12 text-[0.9em] font-bold flex justify-center">
                      Number of pupils
                    </div>
                    <div className="w-3/12 text-[0.9em] font-bold flex justify-center">
                      Assigned To
                    </div>
                    <div className="w-2/12 min-w-[70px] text-[0.8em]"></div>
                  </div>
                  <div className="h-[58vh] w-[98%] !min-w-[700px]  overflow-auto register-scroll">
                    {isLoading ? (
                      <div className="mt-6 flex justify-center">
                        <TableLoader />
                      </div>
                    ) : (
                      classrooms.length > 0 &&
                      filteredData.map((item, index) => {
                        let teacher_string = "";
                        // CHECK THE NUMBER OF CLASSROOM A TEACHER HAS
                        item?.teachers &&
                          item?.teachers.length > 0 &&
                          item?.teachers.map((item: any) => {
                            if (item) {
                              return (teacher_string += `${
                                item?.firstname && item?.firstname
                              } ${item.surname && item.surname} ${
                                item.othername && item.othername
                              }, `);
                            }
                          });

                        // Some teachers might be repeated, so let us split the string by comma
                        let splittedByComma = teacher_string.split(","); //Now an Array

                        // Trim whitespace, remove empty strings, and eliminate duplicates using new Set()
                        const uniqueValues = Array.from(
                          new Set(
                            splittedByComma
                              .map((item) => item.trim())
                              .filter((item) => item !== "")
                          )
                        );

                        // CONVERT THE ARRAY OF TEACHERS TO EACH TEACHERS SEPARATED BY COMMA
                        let uniqueTeachers_string = "";
                        uniqueValues &&
                          uniqueValues.map((item) => {
                            return (uniqueTeachers_string += `${item}, `);
                          });

                        return (
                          <div
                            key={item.id}
                            className="border !min-w-[700px] text-center font-semibold text-[#012063]/[0.7] text-gray-600 bg-white py-3 rounded-xl mt-[5px]"
                          >
                            <div className="flex items-center w-full border-blue-500">
                              <div className="w-4/12">
                                <p className="text-center text-[15px] text-[#012063CC]">
                                  {item.name}
                                </p>
                              </div>
                              <div className="w-3/12">
                                <p className="text-center text-[0.9em] min-w-max">
                                  {item.number_of_students}
                                </p>
                              </div>
                              <div className="w-3/12">
                                <p className="text-center text-[0.9em]">
                                  {uniqueTeachers_string.length > 3 &&
                                    (uniqueTeachers_string.slice(0, -2) ||
                                      "teacher name")}
                                </p>
                              </div>
                              <div className="flex justify-end px-3 w-2/12 min-w-[70px] text-[15px] ">
                                <button
                                  onClick={() => handleClickEdit(item)}
                                  className="flex items-center px-4 py-2 rounded bg-[#0096D1] text-gray-200"
                                >
                                  <FaPen className="mr-1" /> Edit
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {editMode && (
        <div className="">
          <CustomModal
            title="Edit Classroom"
            width={75}
            children={
              <EditClassForm
                form={editForm}
                teachers={staffData}
                handleChange={(e: any) => handleInputChange(e)}
                handleSubmit={handleSubmit}
                isLoading={isSaving}
              />
            }
            hideModal={() => setEditMode((prev) => !prev)}
          />
        </div>
      )}
    </>
  );
};
