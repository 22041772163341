import React from "react";
import { CiCircleRemove } from "react-icons/ci";
import loader from "../../assets/loader.gif";

export interface IParentForm {
  email: string;
  name: string;
  phone: number;
  relationship: string;
  phone_number: any;
  home_number: any;
  onChange?: (event: any) => void;
  onAction?: () => void;
  index?: any;
  validateLoading?: string;
  existingParents?: any[] | undefined;
  selectedParent?: any;
  setSelectedParent?: any;
  parentId?: any;
  parentIdArray?: string[];
  setParentIdArray?: any;
  isParentExist?: any[];
  setIsParentExist?: any[];
  newExistingData?: any;
  setNewSelected?: any;
  newSelected?: string[];
}

const ParentForm = ({
  email,
  name,
  relationship,
  phone_number,
  phone,
  home_number,
  onChange,
  index,
  onAction,
  parentId,
  parentIdArray,
  setParentIdArray,
  isParentExist,
  validateLoading,
  existingParents,
  selectedParent,
  setSelectedParent,
  newSelected,
  setNewSelected,
  newExistingData,
}: IParentForm) => {
  return (
    <div>
      <div className="border-[0.5px] rounded-md px-4 py-10 mx-3 mb-6 relative">
        <div className="studentFormContent mt-4">
          <h4>Email<span className="text-red-600">*</span></h4>
          <div
            className="nameForm"
            style={{
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={onChange}
              // onBlur={(event)=> {handleEmailBlur(idx, event)}}
              required
            />
            {parentId?.[0] && (
              <button
                onClick={() =>
                  setParentIdArray((prev: any) => [...prev, parentId?.[0]])
                }
              >
                parent
              </button>
            )}

{(index === 0 || index === 1) && validateLoading?.toLowerCase() === "loading" ? (
              <div className={`flex gap-3 items-center`}>
                <div className="h-[20px] w-[20px]">
                  <img src={loader} alt="loader" />
                </div>
                <p className="text-xs">checking if parent exists..</p>
              </div>
            ) : (
              isParentExist !== undefined &&
              isParentExist?.[index].status && (
                <div>
                  <p className="text-[12px] text-green-700">Parent Found:</p>
                  {newExistingData[index]?.map((item: any) => {
                    return (
                      <div key={item.id} className="rounded border bg-white p-4 flex flex-wrap items-center gap-4">
                        <p className="text-gray-800 font-bold capitalize text-[16px]">
                          {item.name}
                        </p>
                        <button
                          className="rounded bg-green-800 text-white text-xs py-2 px-4"
                          onClick={(e) => {
                            e.preventDefault();
                            setNewSelected((prev: any) => [...prev, item.id]);
                          }}
                        >
                          {newSelected !== undefined && newSelected[index]
                            ? "Selected!"
                            : "Click to Select"}
                        </button>
                      </div>
                    );
                  })}
                </div>
              )
            )}


          </div>
        </div>


        {isParentExist !== undefined &&
          isParentExist?.[index].status !== true && (
            <div>
              <div className="studentFormContent">
                <h4>Name<span className="text-red-600">*</span></h4>
                <div className="nameForm">
                  <input
                    type="text"
                    placeholder="Full Name"
                    name="name"
                    value={name}
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="studentFormContent">
                <h4>Mobile Number<span className="text-red-600">*</span></h4>
                <div className="nameForm">
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    name="phone"
                    value={phone}
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="studentFormContent">
                <h4>Other Phones</h4>

                <div className="phoneOption">
                  <input
                    type="tel"
                    placeholder="Home"
                    name="home_number"
                    value={home_number}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="studentFormContent">
                <h4>Relation<span className="text-red-600">*</span></h4>
                <div className="genderForm">
                  <span>
                  <input
                      type="radio"
                      name={`relationship-${index}`}
                      value="Parent"
                      onChange={onChange}
                    />
                    Parent
                  </span>
                  <span>
                    <input
                      type="radio"
                      name={`relationship-${index}`}
                      value="Guardian"
                      onChange={onChange}
                    />
                    Guardian
                  </span>
                </div>
              </div>
            </div>
          )}

        {index > 0 && (
          <div
            className="absolute top-3 right-3 cursor-pointer"
            onClick={onAction}
          >
            <CiCircleRemove className="text-2xl text-red-500" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ParentForm;
