import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";

export const RegisterClass = () => {
  return (
    <>
      <div className="w-full px-4">
        <TopAdminNav name="Jane Doe" title="Create Teacher Form" />
      </div>

      <div className="flex">
        
        <div className="px-5 block w-[700px] h-[87vh] overflow-auto register-scroll w-8/12 ">
          <RegisterCard
            title="Class"
            details="Enter Class data"
            activeDetails="Active Classes"
            activeNumber={10}
          />
          <div className="text-[#012063]">
            <div>
              <h2 className="font-medium text-center">Teacher Profile</h2>
              <div className="">
                <div className="flex">
                  <span className="text-[#012063]/[.7] w-2/12 text-right pt-4 font-medium text-[15px]">
                    Name
                  </span>
                  <div className="w-10/12">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="placeholder:#1F1F1F/[0.6] border-2 border-[#ccc] capitalize rounded-[5px] m-2 px-5 py-2 text-[14px] w-[250px]"
                    />
                  </div>
                </div>

                <div className="flex">
                  <span className="text-[#012063]/[.7] w-2/12 text-right pt-4 font-medium text-[15px]">
                    Class
                  </span>
                  <div className="w-10/12">
                    <select
                      name="day"
                      className="border-2 px-5 py-2 m-2 rounded-md w-[250px]"
                    >
                      <option value={35}>35</option>
                      <option value={13}>13</option>
                      <option value={14}>14</option>
                      <option value={15}>15</option>
                      <option value={16}>16</option>
                    </select>
                  </div>
                </div>

                <div className="flex">
                  <span className="text-[#012063]/[.7] w-2/12 text-right pt-4 font-medium text-[15px]">
                    Class Teacher
                  </span>
                  <div className="w-10/12">
                    <input
                      type="text"
                      placeholder="Name"
                      className="placeholder:#1F1F1F/[0.6] border-2 border-[#ccc] capitalize rounded-[5px] m-2 px-5 py-2 text-[14px] w-[250px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex mt-8 mb-5">
            <div className="w-4/12"></div>

            <button className="text-white px-12 py-3 bg-[#012063] rounded-md my-1 flex">
              Save
            </button>
          </div>
        </div>
        <div className="w-4/12">
          {/* <AdminCalRec />                                                                   */}
        </div>
      </div>
    </>
  );
};
