import React from 'react'

function StudentHeader() {
  return (
    <div className="flex w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
    <div className="w-2/12 flex justify-center min-h-[20px]">
      Photo
    </div>
    <div className="w-4/12 text-[0.9em] font-bold flex justify-center min-h-[20px]">
      <p>Name</p>
    </div>
    <div className="w-2/12 text-[0.9em] font-bold flex justify-center min-h-[20px]">
      <p>Date Of Birth</p>
    </div>
    <div className="w-2/12 text-[0.9em] font-bold flex justify-center min-h-[20px]">
      <p>Classroom</p>
    </div>

    <div className="w-2/12">Action</div>
  </div>
  )
}

export default StudentHeader
