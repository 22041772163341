

import { Link } from "react-router-dom"
import { RegisterCard } from "../components/RegisterCard"
import { TopAdminNav } from "../layouts/TopAdminNav"



export const ClassSubject = () => {
        return (
                <>
                        <div className='w-full px-4'>
                                <TopAdminNav name="Jane Doe" title="Create Subject Entry"  />
                        </div>

                        <div className='flex'>
                                <div className='px-5 block w-full h-[87vh] overflow-auto register-scroll'>
                                        <div className="flex">
                                                <div className="w-7/12">
                                                        <RegisterCard title="Subject" details="Enter Class data" activeNumber={10} activeDetails="Active Class" />
                                                </div>
                                        </div>
                                        <div className='text-[#012063] attendance_bg m-5 mt-1 p-5'>
                                                <div>
                                                        <h2 className="font-bold text-[17px] text-center"><span className="border-b-4 border-black pb-2">Subject Entry</span></h2>
                                                </div>
                                                <br />
                                                <div>
                                                        
                                                        <div className='flex'>
                                                                <span className='text-[#012063]/[.7] w-2/12 text-right pt-5 font-medium text-[15px]'>Type</span>
                                                                <div className='w-10/12 py-2 m-3 '>
                                                                        <span className='border-2 py-2 rounded-md bg-white'>
                                                                                <span className='m-2'>
                                                                                        <input type="radio" name="type" className="p-2" /> Creche
                                                                                </span>
                                                                                <span className='m-2'>
                                                                                        <input type="radio" name="type" /> Primary
                                                                                </span>
                                                                                <span className='m-2'>
                                                                                        <input type="radio" name="type" /> Junior
                                                                                </span>
                                                                                <span className='m-2'>
                                                                                        <input type="radio" name="type" /> Senior
                                                                                </span>
                                                                        </span>
                                                                </div>
                                                        </div>
                                                        <div className='flex'>
                                                                <span className='text-[#012063]/[.7] w-2/12 text-right pt-5 font-medium text-[15px]'>Sub-Type</span>
                                                                <div className='w-10/12 py-2 m-3 '>
                                                                        <span className='border-2 py-2 rounded-md bg-white'>
                                                                                <span className='m-2'>
                                                                                        <input type="checkbox" className="p-2" /> Science
                                                                                </span>
                                                                                <span className='m-2'>
                                                                                        <input type="checkbox" /> Art
                                                                                </span>
                                                                                <span className='m-2'>
                                                                                        <input type="checkbox" /> Commercial 
                                                                                </span>
                                                                                
                                                                        </span>
                                                                </div>
                                                        </div>
                                                        <div className='flex'>
                                                                <span className='text-[#012063]/[.7] w-2/12 text-right pt-5 font-medium text-[15px]'>Subject</span>
                                                                <div className='w-10/12 py-2 m-3 '>
                                                                        <div>
                                                                                <span className='border-2 py-2 rounded-md bg-white'>
                                                                                        <span className='m-2'>
                                                                                                <input type="checkbox" className="p-2" /> Mathematics
                                                                                        </span>
                                                                                        <span className='m-2'>
                                                                                                <input type="checkbox" /> English
                                                                                        </span>
                                                                                        <span className='m-2'>
                                                                                                <input type="checkbox" /> Physics 
                                                                                        </span>
                                                                                        <span className='m-2'>
                                                                                                <input type="checkbox" className="p-2" /> Biology
                                                                                        </span>
                                                                                        <span className='m-2'>
                                                                                                <input type="checkbox" /> Chemistry
                                                                                        </span>
                                                                                        <span className='m-2'>
                                                                                                <input type="checkbox" /> Agric Science 
                                                                                        </span>
                                                                                        
                                                                                </span>

                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className='flex'>
                                                                <span className='text-[#012063]/[.7] w-2/12 text-right pt-5 font-medium text-[15px]'>Others</span>
                                                                <div className='w-10/12 py-2 m-3 '>
                                                                        <span className='border-2 py-2 rounded-md bg-white'>
                                                                                <span className='m-2'>
                                                                                        <input type="checkbox" className="p-2" /> French
                                                                                </span>
                                                                                <span className='m-2'>
                                                                                        <input type="checkbox" /> Yoruba
                                                                                </span>
                                                                                <span className='m-2'>
                                                                                        <input type="checkbox" /> Fine Art 
                                                                                </span>
                                                                                <span className='m-2'>
                                                                                        <input type="checkbox" /> Civc 
                                                                                </span>
                                                                                
                                                                        </span>
                                                                </div>
                                                        </div>
                                                        
                                                        <div className='flex'>
                                                                <span className='text-[#012063]/[.7] w-2/12 text-right pt-4 font-medium text-[15px]'>Add new</span>
                                                                <div className='w-3/12'>
                                                                        <input type="text" placeholder='Subject' className='placeholder:#1F1F1F/[0.6] border-2 border-[#ccc] capitalize rounded-[5px] m-2 px-5 py-2 text-[14px] w-[250px]' />
                                                                </div>
                                                                <div className="w-[130px] pt-2">
                                                                        <button className="bg-[#0096D1] flex px-7 py-2 rounded text-white"><img src="../../icons/addcircle.png" alt="add_circle" className="pr-2 font-medium" /> Add</button>
                                                                </div>
                                                                <div className="w-2/12 pt-2">
                                                                        <button className="bg-gradient-to-b from-[#FA614C] to-[#D7320D] flex px-5 py-2 rounded text-white"><img src="../../icons/forbidden2.png" alt="add_circle" className="font-medium" /></button>
                                                                </div>
                                                        </div>
                                                        <div className="flex justify-center">
                                                                <button className='text-white px-20 py-3 bg-[#012063] rounded-md my-1 flex' onClick={()=> {}}>
                                                                        save
                                                                </button>
                                                        </div>
                                                        
                                                </div>
                                        </div>

                                </div>
                        </div>

                </>
        )
}
