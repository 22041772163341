import React from 'react'
import DocsHeader from '../components/ReportCard/Docs/DocsHeader'
import DocsAddingData from '../components/ReportCard/Docs/DocsAddingData'
import DocsComment from '../components/ReportCard/Docs/DocsComment'
import DocsOthers from '../components/ReportCard/Docs/DocsOthers'

function ReportCardDocs() {
  return (
    <div className='p-5'>
      <DocsHeader />
      <DocsAddingData />
      <DocsComment />
      <DocsOthers />
    </div>
  )
}

export default ReportCardDocs
