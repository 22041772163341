import { createSlice, createSelector } from '@reduxjs/toolkit';

type State = {
    isRendered: boolean,
    reportCardData : any
};
const initialState : State ={
    isRendered: false,
    reportCardData : {},
}

const sharedSlice = createSlice({
  name: 'shared_useEffect',
  initialState,
  reducers: {
    setSharedState: (state) => {
        state.isRendered =  !state.isRendered
    },
    populateReportCardData : (state, action) => {
      state.reportCardData = action.payload || {}
    }


  },
});


export const selectA = (state : any) => state.shared_useEffect;   //shared_useEffect from reducer name

//check shared_useEffect value which is setSharedState
export const selectRe_RenderedState = createSelector([selectA], x => x.isRendered)
export const reportCardInfo = createSelector([selectA], x => x.reportCardData)


export const { setSharedState, populateReportCardData } = sharedSlice.actions;
export default sharedSlice.reducer;
