import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

export const makeFetchRequest = async (url: string, paginateDataNeeded?: boolean,
     removeToast?: boolean, firsthandToken?: any) => {

    const token = sessionStorage.getItem("token")?.replace(/['"]+/g, "");


    const headersConfig = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json"
    }

    // OR
    const headers1 = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${firsthandToken}`,
        "Accept": "application/json"
    }


    if (!removeToast) {
        toast.loading("Fetching Data...")
    }

    return await axios.get(url, { headers: firsthandToken !== undefined ? headers1 : headersConfig })

        .then((response) => {
            if (response.status === 200 || response.status === 201 || response.status === 202) {
                toast.remove()
                if (paginateDataNeeded === true) {
                    return response
                } else {
                    const data = response?.data?.data
                    return data
                }

            } else {
                toast.remove()
            }
        }
        )
        .catch((err) => {
            // console.error(err);
            toast.remove()
            toast.error("Network Error. Please refresh this page")

        })

}


export const makePutRequest = async (url: string, body?: any) => {
    const token = sessionStorage.getItem("token")?.replace(/['"]+/g, "");
    const headersConfig = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json"
    }

    try {
        toast.remove()
        toast.loading("Processing...")
        let request: any;

        if (!body) {
            request = await axios.put(url, {}, { headers: headersConfig })
        } else {
            request = await axios.put(url, body, { headers: headersConfig })
        }
        if (request.status === 200 || request.status === 201 || request.status === 202) {
            toast.remove()

            const response = request?.data
            Swal.fire({
                title: `Success👍`,
                text: `${response.message}`,
                icon: "success",
                showCancelButton: false,
            })

            return response?.data
        } else {
            return Swal.fire({
                title: `Ooops, something went wrong!`,
                text: `${request?.data?.message}`,
                icon: "error",
                showCancelButton: false,
            })
        }


    } catch (err: any) {
        // console.error(err);
        toast.remove()
        Swal.fire({
            title: `Error`,
            text: err?.response?.data?.message || "Network Error.",
            icon: "error",
            showCancelButton: false,
        })

    }


}


export const makeDeleteRequest = async (url: string) => {
    const token = sessionStorage.getItem("token")?.replace(/['"]+/g, "");
    const headersConfig = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json"
    }
    toast.remove()
    try {
        toast.remove()
        toast.loading("Processing...")

        const request = await axios.delete(url, { headers: headersConfig })

        if (request.status === 200 || request.status === 201 || request.status === 202) {
            toast.remove()
            const response = request?.data
            Swal.fire({
                title: `Success👍`,
                text: `${response.message}`,
                icon: "success",
                showCancelButton: false,
            })

            return response?.data
        } else {
            return Swal.fire({
                title: `Ooops, something went wrong!`,
                text: `${request?.data?.message}`,
                icon: "error",
                showCancelButton: false,
            })
        }


    } catch (err: any) {
        // console.error(err);
        toast.remove()
        Swal.fire({
            title: `Error`,
            text: err?.response?.data?.message || "Network Error.",
            icon: "error",
            showCancelButton: false,
        })

    }


}

export const makePostRequest = async (url: string, body: any,
    successMsg?: string, failureMsg?: string) => {

    const token = sessionStorage.getItem("token")?.replace(/['"]+/g, "");
    const headersConfig = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json"
    }

    toast.remove()
    toast.loading("Processing...")

    return await axios.post(url, body, { headers: headersConfig })
        .then((res: any) => {
            if (res.status === 200 || res.status === 201 || res.status === 202) {
                toast.remove()
                const response = res?.data
                Swal.fire({
                    title: `Success👍`,
                    text: successMsg || `${response.message}`,
                    icon: "success",
                    showCancelButton: false,
                })

                return response?.data
            } else {
                return Swal.fire({
                    title: `Ooops, something went wrong!`,
                    text: failureMsg || `${res?.data?.message}`,
                    icon: "error",
                    showCancelButton: false,
                })
            }
        })
        .catch((err: any) => {
            // console.error(err?.response?.data?.message);
            toast.remove()
            Swal.fire({
                title: `Error`,
                text: err?.response?.data?.message || "Network Error.",
                icon: "error",
                showCancelButton: false,
            })

        })

}



export const readableDate = (date: any) => {
    const currentDate = new Date(date);
    // Array of month names
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Get the date components
    const year = currentDate.getFullYear();
    const monthIndex = currentDate.getMonth();
    const day = currentDate.getDate().toString().padStart(2, '0');

    // Get the time components
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');

    // Create a readable date and time string with the month name
    let formattedDateTime = `${monthNames[monthIndex]} ${day}, ${year}.   ${hours}:${minutes} AM`

    if (Number(hours) > 12) {
        formattedDateTime = `${monthNames[monthIndex]} ${day}, ${year}.   ${Number(hours) - 12}:${minutes} PM`;
    }


    return formattedDateTime

}
