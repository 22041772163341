import { RegisterCard } from "../../components/RegisterCard";
import { AiTwotoneCamera, AiFillVideoCamera } from "react-icons/ai";
import { useState } from "react";
import axios from "axios";
import { globalToken } from "../AdminHome";
import { MediaUploadInput } from "../../components/MediaUploadInput";
import { CircleLoader } from "../../components/CircleLoader";


interface Activity {
  type?: string;
  student_id?: string[];
  description?: string;
  time?: string;
  image?: string[];
  video?: string[];
  meal?: string;
  quantity?: string;
  health?: string;
  observation?: string;
  liquid?: string;
  mood?: string;
  morale?: string;
  sport_type?: string;
  items_needed?: string;
  toilet_type?: string;
  condition?: string;
  temperature?: string;
}

export const CAactivities = ({ formData, onFormChange, onFormSubmit, loading}: any) => {
  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="Home Work"
          details="Activity will be sent to parent/ guardians"
        />
        <br />
        <form onSubmit={onFormSubmit}>
          <div className="flex flew-wrap items-center justify-between
           h-[90vh] overflow-y-scroll my-4 px-2.5 w-full">
            <div className="!p-0 md:w-2/3">
              <div className="w-full flex items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/3">
                  Description
                </h4>
                <div className="w-2/3 pl-6">
                  <textarea
                    defaultValue={formData["description"]}
                    onChange={(e) =>
                      onFormChange("description", e.target.value)
                    }
                    className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"
                  ></textarea>
                </div>
              </div>
              <div className="w-full flex mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/3">
                  Photo or Video
                </h4>
                <MediaUploadInput
                  singleOption={false}
                  onChangeFn={(e: any, type: any) =>
                    onFormChange(
                      type === "video" ? "video" : "image",
                      e ? e.target.files[0] : ""
                    )
                  }
                  className="w-2/3 pl-6 flex flex-col items-center"
                />
              </div>
              <div className="w-full flex items-center mt-6"   style={{visibility: "hidden"}}>
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/3">
                  Activities
                </h4>
                <div className="w-2/3 pl-6">
                  <input
                  style={{border: "none", outline: "none", visibility: "hidden"}}
                    value="home work"
                    required
                    defaultValue={formData["activity"]}
                    onChange={(e) =>
                      onFormChange("activity", e.target.value)
                    }
                    className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"
                  />

                  <select required defaultValue={formData["activity"]} onChange={(e)=> onFormChange("activity", e.target.value)} name="" id="" className="p-2 w-80 rounded-lg border focus:outline-none">
                    <option  value="">Select</option>
                    <option value="learning">Learning</option>
                    <option value="playing">Playing</option>
                  </select>
                </div>
              </div>

              <div className="mt-10 flex h-12 w-full">
                <div className="w-1/3"></div>
                <div className="w-2/3 pl-6">
                  <button
                    type="submit"
                    className="flex items-center justify-center text-white w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]"
                  >
                      {loading ? <CircleLoader /> : "Add"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile">
              <img
                src="/img/sticky-note.svg"
                alt="login"
                className="w-40 h-40"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
