import AdminTopBar from "../../layouts/AdminTopBar";

const Suspend = () => {
  return (
    <div>
      <AdminTopBar title="User Data" name="John Doe" />
    </div>
  );
};

export default Suspend;
