import React, { useState, useEffect } from "react";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { RegisterCard } from "../components/RegisterCard";
import "../assets/css/calendar.css";
import Calendar from "react-calendar";
import CustomModal from "../components/CustomModal/CustomModal";
import CreateEvent from "./CreateEvent";
import EventList from "./EventList";
import Swal from "sweetalert2";
import { defaultUrl } from "../features/actions";
import { makeDeleteRequest, makeFetchRequest } from "../utilities/common";
import { formatTime } from "../utilities/importantFns";
import toast from "react-hot-toast";
import {
  selectRe_RenderedState,
  setSharedState,
} from "../features/sharedStateSlices/shareState";
import { useDispatch, useSelector } from "react-redux";
import { addYears, format, parse } from "date-fns";

export interface EventItem {
  id?: string;
  title?: string;
  description?: string;
  school_id?: string;
  start_date?: string;
  end_date?: string;
  time?: string;
  user_type?: string;
}

const Events = () => {
  /** COMPONENT MOUNT STATE */
  const dispatch = useDispatch();
  const today = new Date();
  const [dateValue, setDateValue] = useState(today);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showEventList, setShowEventList] = useState(false);
  const [isEventEditMode, setIsEventEditMode] = useState(false);
  const [eventEditData, setEventEditData] = useState({});
  const [selectedDay, setSelectedDay] = useState<Date>(dateValue);
  const [endDate, setEndDate] = useState<Date>(dateValue);
  const [schedules, setSchedules] = useState<EventItem[]>([]);

  const is_Rendered = useSelector((state) => selectRe_RenderedState(state));

  /** DATA FETCHING */
  useEffect(() => {
    fetchSchedules();
  }, [showFormModal, is_Rendered]);

  const fetchSchedules = async () => {
    const fmtStartDate = `${selectedDay.getFullYear()}-${
      selectedDay.getMonth() + 1 < 10
        ? `0${selectedDay.getMonth() + 1}`
        : selectedDay.getMonth() + 1
    }`; //-${selectedDay.getDate() < 10? `0${selectedDay.getDate()}` : selectedDay.getDate()}
    const fmtEndDate = `${endDate.getFullYear()}-${
      endDate.getMonth() + 1 < 10
        ? `0${endDate.getMonth() + 1}`
        : endDate.getMonth() + 1
    }`;

    const parsedDate = parse(fmtStartDate, "yyyy-MM", new Date());
    const nextTwoYears = addYears(parsedDate, 2);
    const nextTwoYearString = format(nextTwoYears, "yyyy-MM");

    //  GET EVENTS
    const response = await makeFetchRequest(
      `${defaultUrl}/schedule?start_date=${fmtStartDate}&end_date=${nextTwoYearString}`
    );

    setSchedules(response);
  };

  /** ACTIONS */
  function showModal(modalTitle: string) {
    toast.remove();
    if (modalTitle === "form") {
      setShowEventList(false);
      setShowFormModal(true);
    }
    if (modalTitle === "list") {
      setShowFormModal(false);
      setShowEventList(true);
    }
  }
  function closeModal() {
    setShowFormModal(false);
    setShowEventList(false);
    setEventEditData({});
    setIsEventEditMode(false);
    setSelectedDay(dateValue);
  }

  async function deleteEvent(eventID: string) {
      const result = await Swal.fire({
        title: `Please Confirm Delete`,
        text: `This event will be deleted and this action is irreversible`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "red",
        cancelButtonColor: "gray",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        toast.remove();
        toast.loading("Processing...");
        await makeDeleteRequest(`${defaultUrl}/schedule/${eventID}`)

       closeModal()
        dispatch(setSharedState());
      }

  }

  return (
    <div>
      <div className="w-full">
        <TopAdminNav name="Jane Doe" title="Events and Calendar" />
      </div>
      <div className="">
        <div className="photoCardTop">
          <div className="w-full ">
            <RegisterCard
              title="Let’s share it."
              details="All pictures shared on platform"
            />
          </div>
        </div>

        <div className="w-full mt-4 overflow-auto register-scroll h-[81vh] min-h-max">
          <div className="mx-auto h-full w-full md:w-[80%] lg:w-[96%]">
            <Calendar
              onChange={(e) => {
                // console.log(e)
              }}
              value={dateValue}
              tileContent={({ activeStartDate, date }) => {
                // LOOP THROUGH THE DATES FROM THE SCHEDULES(DATABASE RESULT) AND GET THE DATES
                // THAT MATCHES THE CURRENT CALENDAR DATE
                const isToday =
                  schedules &&
                  schedules.find((item: any) => {
                    return item.start_date.split(" ")[0] === formatTime(date).split(" ")[0]
                  });


                let activityName = isToday && isToday.title;
                let displayed;
                if (activityName && activityName.length > 10) {
                  displayed = (
                    <p className="mt-1 text-sm font-bold">
                      {activityName.substring(0, 9)}...
                    </p>
                  );
                } else {
                  displayed = (
                    <p className="mt-1 text-sm font-bold">{activityName}</p>
                  );
                }
                if (date.getTime() >= today.getTime() - 100000000) {
                  return (
                    <div
                      onClick={() => {
                        setEndDate(date);
                        setShowFormModal(true);
                      }}
                      className="relative py-2"
                    >
                      {displayed}
                      <div
                        role="button"
                        aria-pressed="false"
                        tabIndex={0}
                        className={`absolute -bottom-7 right-0 flex items-center justify-center rounded-full text-[#012063] bg-[#0096D1] w-7 h-7 hover:w-8 hover:h-8 font-xs`}
                      >
                        +
                      </div>
                    </div>
                  );
                }
                return (
                  <div
                    onClick={() => setEndDate(date)}
                    className="relative py-2"
                  >
                    {displayed}
                    <div
                      role="button"
                      aria-pressed="false"
                      tabIndex={0}
                      className={`absolute -bottom-7 right-0 flex items-center justify-center rounded-full text-gray-100 bg-gray-300 w-5 h-5 font-xs`}
                    >
                      +
                    </div>
                  </div>
                );
              }}
              tileClassName={``}
              onClickDay={(value) => {
                if (value.getTime() >= today.getTime() - 100000000) {
                  setSelectedDay(value);
                  showModal("list");
                } else {
                  Swal.fire(
                    "No time machine available",
                    "You cannot schedule events for past days, let it go.",
                    "info"
                  );
                }
              }}
              className="react-calendar"
            />
          </div>
        </div>
      </div>

      {showFormModal && (
        <CustomModal
          title={`Create Event`}
          children={
            <CreateEvent
              selectedDate={selectedDay}
              editMode={isEventEditMode}
              editData={eventEditData}
              closeModal={closeModal}
            />
          }
          hideModal={closeModal}
        />
      )}

      {showEventList && (
        <CustomModal
          title={`Events`}
          children={
            <EventList
              events={schedules}
              onClick={{
                create: () => showModal("form"),
                edit: (data: EventItem) => {
                  setIsEventEditMode(true);
                  setEventEditData(data);
                  showModal("form");
                },
                delete: (id: string) => deleteEvent(id),
              }}
              selectedDate={selectedDay}
            />
          }
          hideModal={closeModal}
        />
      )}
    </div>
  );
};

export default Events;
