import { RegisterCard } from "../components/RegisterCard"
import { TopAdminNav } from "../layouts/TopAdminNav"
import '../assets/css/main.css'


export const CreateImportQuiz = () => {
        return (
                
                <>
                        <div className='w-full px-4'>
                                <TopAdminNav name="Jane Doe" title="Create Quiz"  />
                        </div>

                        <div className='flex'>
                                <div className='px-5 block w-full h-[87vh] overflow-auto register-scroll'>
                                        <div className="flex">
                                                <div className="w-7/12">
                                                        <RegisterCard title="Upload your quiz" details="Quiz for student now"  />
                                                </div>
                                                <div className="w-5/12 h-full">
                                                        <div className="flex">
                                                                <div className="w-1/6"></div>
                                                                <div className="w-4/6 h-full">
                                                                        <div className="flex items-end justify-center h-full">
                                                                                <button className="py-4 px-12 m-5 mb-0 bg-[#0096D1] text-white rounded-xl">
                                                                                        Import Quiz
                                                                                </button>
                                                                        </div>
                                                                        
                                                                </div>
                                                                <div className="w-1/6"></div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div className='text-[#012063] attendance_bg m-5 mt-1 p-5'>
                                                <div className="flex text-[#1f1f1f]/[0.7] mb-7 w-full text-center"> 
                                                        <div className="w-3/12 ">
                                                                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center" >
                                                                        <option>All Created</option>
                                                                </select>
                                                        </div>
                                                        <div className="w-3/12 ">
                                                                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center" >
                                                                        <option>2021/2022</option>
                                                                </select>
                                                        </div>
                                                        <div className="w-3/12 ">
                                                                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center" >
                                                                        <option>Class</option>
                                                                </select>
                                                        </div>
                                                        <div className="w-3/12 ">
                                                                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center" >
                                                                        <option>First Term</option>
                                                                </select>
                                                        </div>

                                                </div>
                                                <div className="w-full flex">
                                                        <div className="w-2/12"></div>
                                                        <div className="w-8/12 bg-white border-[#ccc]/[0.7] border-2 rounded-xl">
                                                                <div className="flex justify-end m-2">
                                                                        <div className="rounded-full border-2 border-[#000]/[0.7] w-[30px] h-[30px]"><h2 className="text-[#000]/[0.7] text-center">x</h2></div>
                                                                </div>
                                                                <div className="m-3 mx-8 px-0 py-2">
                                                                        <div className="flex mb-3">
                                                                                <label className="text-[#012063]/[0.7] w-2/6 text-right font-bold pr-8 pt-3 text-[17px]">Subject</label>
                                                                                <div className="w-4/6">
                                                                                        <div className="flex">
                                                                                                <input type="text" className="w-9/12 border-2 border-[#ccc]/[0.7] rounded-xl px-4 py-3" />
                                                                                                <div className="w-3/12"></div>
                                                                                        </div>

                                                                                </div>
                                                                        </div>
                                                                        <div className="flex mb-3">
                                                                                <label className="text-[#012063]/[0.7] w-2/6 text-right font-bold pr-8 pt-3 text-[17px]">Number</label>
                                                                                <div className="w-4/6">
                                                                                        <div className="flex">
                                                                                                <input type="text" className="w-4/12 border-2 border-[#ccc]/[0.7] rounded-xl px-4 py-3" />
                                                                                                <div className="w-3/12"></div>
                                                                                        </div>

                                                                                </div>
                                                                        </div>
                                                                        <div className="flex mb-3">
                                                                                <label className="text-[#012063]/[0.7] w-2/6 text-right font-bold pr-8 pt-3 text-[17px]">Class</label>
                                                                                <div className="w-4/6">
                                                                                        <div className="flex">
                                                                                                <input type="text" className="w-7/12 border-2 border-[#ccc]/[0.7] rounded-xl px-4 py-3" />
                                                                                                <div className="w-3/12"></div>
                                                                                        </div>

                                                                                </div>
                                                                        </div>
                                                                        <div className="flex mb-3">
                                                                                <label className="text-[#012063]/[0.7] w-2/6 text-right font-bold pr-8 pt-3 text-[17px]">Date Created</label>
                                                                                <div className="w-4/6">
                                                                                        <div className="flex">
                                                                                                <input type="text" className="w-4/12 border-2 border-[#ccc]/[0.7] rounded-xl px-4 py-3 placeholder:text-[#1F1F1F]/[0.6] text-[#1F1F1F]/[0.7] font-medium" placeholder="Auto Date" value="Auto Date" />
                                                                                                <div className="w-3/12"></div>
                                                                                        </div>

                                                                                </div>
                                                                        </div>
                                                                        <div className="flex mb-3">
                                                                                <label className="text-[#012063]/[0.7] w-2/6 text-right font-bold pr-8 pt-3 text-[17px]">Deadline Date</label>
                                                                                <div className="w-4/6">
                                                                                        <div className="flex">
                                                                                                <input type="text" className="w-4/12 border-2 border-[#ccc]/[0.7] rounded-xl px-4 py-3 placeholder:text-[#1F1F1F]/[0.6] text-[#1F1F1F]/[0.7] font-medium" placeholder="Date" value="Date" />
                                                                                                <div className="w-1/12"></div>
                                                                                                <input type="text" className="w-4/12 border-2 border-[#ccc]/[0.7] rounded-xl px-4 py-3 placeholder:text-[#1F1F1F]/[0.6] text-[#1F1F1F]/[0.7] font-medium" placeholder="Time" value="Time" />
                                                                                                <div className="w-3/12"></div>
                                                                                        </div>

                                                                                </div>
                                                                        </div>
                                                                        <div className="flex mb-3">
                                                                                <label className="text-[#012063]/[0.7] w-2/6 text-right font-bold pr-8 pt-3 text-[17px]">Teacher Name</label>
                                                                                <div className="w-4/6">
                                                                                        <div className="flex">
                                                                                                <input type="text" className="w-9/12 border-2 border-[#ccc]/[0.7] rounded-xl px-4 py-3 placeholder:text-[#1F1F1F]/[0.6] text-[#1F1F1F]/[0.7] font-medium" placeholder="Auto Account Name" value="Auto Account Name" />
                                                                                                <div className="w-3/12"></div>
                                                                                        </div>

                                                                                </div>
                                                                        </div>
                                                                        <div className="flex mb-3">
                                                                                <label className="text-[#012063]/[0.7] w-2/6 text-right font-bold pr-8 pt-3 text-[17px]"></label>
                                                                                <div className="w-4/6">
                                                                                        <div className="flex">
                                                                                                {/* <input type="text" className="w-9/12 border-2 border-[#ccc]/[0.7] rounded-xl px-4 py-3 placeholder:text-[#1F1F1F]/[0.6] font-medium" placeholder="Auto Account Name" /> */}
                                                                                                <button className="w-9/12 border-2 border-[#ccc]/[0.7] rounded-xl px-4 py-3 text-[#1F1F1F]/[0.7] font-medium">Import Quiz</button>
                                                                                                <div className="w-3/12"></div>
                                                                                        </div>

                                                                                </div>
                                                                        </div>
                                                                        <div className="flex mb-3">
                                                                                <div className="w-1/6"></div>
                                                                                <div className="w-5/6">
                                                                                        <div className="flex">

                                                                                                <button className="bg-gradient-to-b from-[#0096D1] to-[#013678] w-9/12 text-white px-3 py-4 rounded-xl font-bold text-[18px] mt-5"> upload</button>
                                                                                                <div className="w-3/12"></div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className="w-2/12"></div>

                                                </div>
                                        </div>

                                </div>
                        </div>
                </>
        )
}