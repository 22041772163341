function StaffArchive({unArchive_Teacher,archived_TeachersPerPage,pageIndex_Teachers}: any) {
  return (
    <div className="w-full text-center">
    <div className="grid grid-cols-5 w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
      <div className="col-span-1">Name</div>
      <div className="col-span-1 text-[0.9em] text-gray-600 font-bold flex justify-center">
        Classrooms
      </div>
      <div className="col-span-1 text-[0.9em] text-gray-600 font-bold flex justify-center">
        Contact
      </div>
      <div className="col-span-1 text-[0.9em] text-gray-600 font-bold flex justify-center">
        Degree
      </div>
      {/* <div className="col-span-1 text-[0.9em] text-green-600 font-bold flex justify-center">
        Action
      </div> */}
      {/* <div className="col-span-1 text-[0.9em] font-bold flex justify-start"></div> */}
    </div>

{archived_TeachersPerPage &&
    <div className="h-full max-h-[64vh] w-[98%] !min-w-[700px]  overflow-y-scroll register-scroll">
      {archived_TeachersPerPage.length >= 1 ? archived_TeachersPerPage[pageIndex_Teachers].map((item: any, index: number) => {
        let class_string = ""

        // CHECK THE NUMBER OF CLASSROOM A TEACHER HAS
        item.classrooms.length > 0 && item.classrooms.map((item: any) => {
          //  CONCATINATE EACH CLASSROOMS BY ", "
          if (item.classrooms > 1) {
            return class_string += item.name + ", "
          }
          return class_string += item.name
        })

        return (
          <div key={index}
          className="grid grid-cols-5 w-[98%] !min-w-[700px]
          mb-2 py-4 bg-[#F4F7FE] rounded-xl border">
            <div className="col-span-1">{item.firstname} {item.surname} {item.othername}</div>
            <div className="col-span-1 text-[0.9em] text-gray-600  flex justify-center">
              {class_string}
            </div>
            <div className="col-span-1 text-[0.9em] text-gray-600  flex justify-center">
            <p>
              {item.phone_number}
              <br />

              <b>{item?.email?.slice(0,15)}
              <br />
              {item?.email?.slice(15)}</b></p>
            </div>
            <div className="col-span-1 text-[0.9em] text-gray-600  flex justify-center">
              {item.degree}
            </div>
            <div className="col-span-1 mx-2.5 text-[0.9em] flex justify-center">
              <button className="bg-green-600 rounded-md hover:opacity-80 text-white p-2 my-auto h-[50px" onClick={() => {
                unArchive_Teacher(item.id)
              }}>Unarchive</button>
            </div>
          </div>
        );
      })
      :
      <p className="text-[#012063CC] py-3 text-xl text-center">No data found...</p>
      }
    </div>}
  </div>
  )
}

export default StaffArchive
