import { CircleLoader } from "../../components/CircleLoader";

export const EditClassForm = ({
  form,
  handleChange,
  handleSubmit,
  teachers,
  isLoading,
}: any) => {
  return (
    <form onSubmit={handleSubmit} className="regStudentForm class">
      <div>
        <h2>Enter details</h2>

        <div className="studentFormContent">
          <h4>Class Name</h4>
          <div className="nameForm">
            <input
              type="text"
              placeholder="Enter Classroom name"
              name="name"
              value={form.name}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="studentFormContent">
          <h4>Assign To</h4>
          <select
            id=""
            value={form.teachers[0]?.id}
            name="teachers"
            className="!text-[14px] !h-10"
            onChange={handleChange}
          >
            <option value="">Select a staff</option>
            {teachers.map((user: any, idx: number) => (
              <option key={idx} value={user?.id}>{user?.surname}</option>
            ))}
          </select>
        </div>

        <div className="flex justify-center">
          <button className="!h-14 py-1 text-center">
            {isLoading ? (
              <div className="flex items-center justify-center gap-2">
                <CircleLoader /> Saving...
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};
