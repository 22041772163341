import { Outlet } from "react-router-dom";
import { TopAdminNav } from "./TopAdminNav";

export function SettingLayout() {
  return (
    <div className="block px-4">
      <TopAdminNav title="Settings" name="Jane Doe" />
      <div className="">
        <Outlet />
      </div>
    </div>
  );
}
