import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { CircleLoader } from "../../components/CircleLoader";
import { EmbeddedParentForm } from "./embedded_parents";
import { useSelector } from "react-redux";
import { selectRe_RenderedState } from "../../features/sharedStateSlices/shareState";

export const EditStudentForm = ({
  form,
  handleChange,
  handleSubmit,
  classrooms,
  isLoading,
}: any) => {
  // console.log({ form });

const [parentsData, setParentData] = useState(form ? form.parents : [] )
const [isChangeSaved, setIsChangeSaved] = useState(false)
const reloaded = useSelector(state => selectRe_RenderedState(state))



  const handleParentInputChange = (
    index: number,
    { target: { name, value } }: any
  ) => {
    setParentData((parent: any) => {
      parent[index][name] = value;
      return [...parent];
    });
  };


  useEffect(() => {

  },[reloaded])






  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-wrap items-start">
        <div className="">
          <div className="studentFormContent mt-2">
            <h4>First Name</h4>
            <div className="nameForm">
              <input
                type="text"
                placeholder="First name"
                name="firstname"
                value={form?.firstname || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="studentFormContent mt-2">
            <h4>Last Name</h4>
            <div className="nameForm">
              <input
                type="text"
                placeholder="Surname"
                name="surname"
                value={form?.surname || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="studentFormContent mt-2">
            <h4>Other Name</h4>
            <div className="nameForm">
              <input
                type="text"
                placeholder="other names"
                name="othername"
                value={form?.othername || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="studentFormContent">
            <h4>Gender</h4>
            <div className="genderForm">
              <span>
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  checked={form?.gender === "male"}
                  onChange={handleChange}
                  required
                />{" "}
                Boy
              </span>
              <span>
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={form?.gender === "female"}
                  onChange={handleChange}
                  required
                />{" "}
                Girl
              </span>
            </div>
          </div>
          <div className="studentFormContent">
            <h4>Date of Birth</h4>
            <div className="nameForm">
              <input
                type="date"
                name="date_of_birth"
                value={form?.date_of_birth || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>


          <div className="studentFormContent">
            <h4>Class</h4>
            <div className="nameForm">
              <select
                name="classroom_id"
                onChange={handleChange}
                defaultValue={form.classroom_id || ""}
                required
              >
                <option value="">Select class</option>
                {classrooms.map((user: any, idx: number) => (
                    <option key={idx} value={user?.id}>{user?.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="studentFormContent">
            <h4>Admission Date</h4>
            <div className="nameForm">
              <input
                type="date"
                name="admission_date"
                value={form?.admission_date || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {/* <div className="">
              <div className="studentFormContent">
                <h4>Contact</h4>
                <div className="nameForm">
                  <input
                    type="text"
                    placeholder="Name"
                    name="contact_name"
                    value={form.contact_name}
                    onChange={handleChange}
                  />
                  <input
                    type="tel"
                    placeholder="Number"
                    name="phone"
                    value={form.phone}
                    onChange={handleChange}
                  />
                  <select
                    name={`relationship`}
                    value={form.relationship}
                    onChange={handleChange}
                  >
                    <option value="">Relationship</option>
                    <option>Father</option>
                    <option>Mother</option>
                    <option>Sister</option>
                    <option>Brother</option>
                    <option>Uncle</option>
                    <option>Aunty</option>
                    <option>Nanny</option>
                    <option>Others</option>
                  </select>
                </div>
              </div>
            </div> */}
          {/* <div className="">
              <div className="studentFormContent">
                <h4>Condition</h4>
                <div className="medForm">
                  <select
                    className="!text-[14px]"
                    name="condition"
                    value={form.condition}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Allergy / Medical</option>
                    <option value="none" className="font-[600]">None</option>
                    <option value={"water"}>Water</option>
                    <option value="Food">Food</option>
                    <option value="Water">Drug</option>
                    <option value="Soda">Soda</option>
                  </select>
                  <div className="genderForm">
                    <span>
                      <input
                        type="radio"
                        name="extent"
                        value="mild"
                        checked={form?.gender == "mild"}
                        onChange={handleChange}
                      />
                      Mild
                    </span>
                    <span>
                      <input
                        type="radio"
                        name="extent"
                        checked={form?.gender == "moderate"}
                        onChange={handleChange}
                        required
                        value="moderate"
                      />
                      Moderate
                    </span>
                    <span>
                      <input
                        type="radio"
                        checked={form?.gender == "severe"}
                        onChange={handleChange}
                        required
                        name="extent"
                        value="severe"
                      />
                      Severe
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
        </div>

        <div className="min-w-[200px] p-4">
          <label className="h-40 w-40 flex flex-col">
            <input
              type="file"
              name="profile_picture"
              className="hidden"
              onChange={handleChange}
              defaultValue={""}
            />
            {!form.profile_picture && (
              <div className="imageOutline">
                <AiOutlinePlus className="imageIcon" />
              </div>
            )}
            {form.profile_picture && (
              <div className="w-full h-full overflow-hidden rounded-full">
                {form.profile_picture && form.profile_picture.name ? (
                  <img
                    src={URL.createObjectURL(form.profile_picture)}
                    alt="preview"
                  />
                ) : form.profile_picture == "string" ? (
                  <img src={form.profile_picture} alt="preview" />
                ) : (
                  form.profile_picture
                )}
              </div>
            )}
          </label>
        </div>
      </div>

      {/* PARENTS EDIT FORM */}
      {form.parents &&
      <EmbeddedParentForm
       numberOfParents={form?.parents?.length}
       form={form.parents}
       handleParentChange={(index: number, e: any) =>
        handleParentInputChange(index, e)
      }
      studentEdittingSaved={isChangeSaved}
       />}



      <div className="w-full flex justify-center mb-5">
        <button onClick={() => {
          setIsChangeSaved(true)
        }}
        className="rounded w-[300px] max-w-full mx-2 px-4 py-2 bg-cyan-900 text-white font-semibold bg-opacity-80 flex justify-center">
          {isLoading ? (
            <div className="flex gap-3 items-center">
              <CircleLoader /> Saving...
            </div>
          ) : (
            "Save all changes"
          )}
        </button>
      </div>
    </form>
  );
};
