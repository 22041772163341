import { createSlice } from "@reduxjs/toolkit";
import { studentSliceInterface } from "../../types/interfaces";
import { registerStudentThunk } from "../actions";



// firstname, othername, gender, dateOfBirth, classname

const initialState:studentSliceInterface = {
        studentList: [],
        studentRegister: {
                surname:"", //
                firstname:"", //
                othername:"", //
                gender:"male", //
                date_of_birth:"2004-04-02", //
                email:"ajoseholabisi@gmail.com",  //
                phone_number:"09075487068",
                school_id:"adfsfasdfd",
                classroom_id:"afdgfdfgb", //
                house_address:"",
                house_address2:"",
                city:"",
                state:"",
                country:"Nigeria",
                admission_date:"2004-04-02", //
                zip_code:"",
                profile_picture:"sdfgsdfgsdg",
                parent_id: ["22382c31-0d49-4d5f-95a3-f88c69593fab"],
                contact_name:["Tunji"],
                phone:["08156727272"],
                relationship:["Father"],
                condition:[],
                extent:[]
                        
        },
        selectedStudent: "",
        count: 0,
        status: '',
        studentReceivedData : {
                message: "",
                data: {
                        student_id: ""
                }
        },

}

const studentSlice = createSlice({
        name: "student",
        initialState,
        reducers:{
                registerStudent: (state, {payload}) => {
                        state.count += 1;

                        state.studentRegister.surname = payload.surname
                        state.studentRegister.firstname = payload.firstname
                        state.studentRegister.othername = payload.othername
                        // state.studentRegister.phone_number = payload.phone_number
                        state.studentRegister.house_address = payload.address
                        state.studentRegister.house_address2 = payload.address2
                        state.studentRegister.city = payload.city
                        state.studentRegister.state = payload.statedata
                        state.studentRegister.zip_code = payload.zipcode

                },
                selectStudent: (state, {payload}) => {
                        state.selectedStudent = payload
                }
        },
        extraReducers(builders)  {
                builders
                        .addCase(registerStudentThunk.pending, (state)  => {
                                state.status = 'pending'
                        })
                        .addCase(registerStudentThunk.fulfilled, (state, {payload}) => {
                                state.status = 'fulfilled'
                                state.studentReceivedData = payload
                        })
                        .addCase(registerStudentThunk.rejected, (state, {payload}:any) => {
                                state.status = 'rejected'
                                state.studentReceivedData = payload
                        })
        }
})



export const  {registerStudent, selectStudent} = studentSlice.actions

export default studentSlice.reducer;