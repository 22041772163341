import { Outlet } from "react-router-dom";
import { TopAdminNav } from "./TopAdminNav";

export function ClassActivitiesLayout() {
  return (
    <div className="block px-4">
      <TopAdminNav title="Class Activities" name="Jane Doe" />
      <div className="">
        <Outlet />
      </div>
    </div>
  );
}
