import React, { useState, useEffect } from "react";
import "../assets/css/calendar.css";
import { EventItem } from "./EventAndCalendar";
import { TimeSelect } from "../components/TimeSelect";
import { defaultUrl } from "../features/actions";
import {
  selectRe_RenderedState,
  setSharedState,
} from "../features/sharedStateSlices/shareState";
import { useDispatch, useSelector } from "react-redux";
import { makePostRequest, makePutRequest } from "../utilities/common";

const CreateEvent = ({
  selectedDate: value,
  editMode,
  editData,
  closeModal,
}: {
  selectedDate: Date;
  editMode: boolean;
  editData: EventItem;
  closeModal: any;
}) => {
  const selectedDate = `${value.getFullYear()}-${
    value.getMonth() + 1 < 10
      ? `0${value.getMonth() + 1}`
      : value.getMonth() + 1
  }-${value.getDate() < 10 ? `0${value.getDate()}` : value.getDate()} 09`;

  const selectedDaystring = value.toDateString();

  const [formData, setFormData] = useState(
    editMode === true
      ? editData
      : {
          id: "",
          title: "",
          description: "",
          school_id: "",
          start_time: "09:00",
          start_date: selectedDate,
          end_date: selectedDate,
          user_type: "",
          time: "09:00",
          frequency: "yearly",
        }
  );

  const dispatch = useDispatch();
  const is_Rendered = useSelector((state) => selectRe_RenderedState(state));

 const handleSubmit = async (e: any) => {
    e.preventDefault();

    const eventStartingDate = formData && formData.start_date;
    const timeinHr = formData && formData.time;
    let timeCreated =
      eventStartingDate && `${eventStartingDate.slice(0, 10)} ${timeinHr}`;

    const newData = {
      id: formData.id,
      title: formData.title,
      description: formData.description,
      start_date: timeCreated,
      end_date: timeCreated,
      time: formData.time,
      start_time: formData.time,
      school_id: formData.school_id,
      created_at: timeCreated,
      updated_at: timeCreated,
      user_type: formData.user_type,
      frequency: null,
    };

      if (editMode) {
       await makePutRequest(`${defaultUrl}/schedule/${formData.id}`, newData)
       dispatch(setSharedState());
      } else {
        await makePostRequest(`${defaultUrl}/schedule/create`, newData)
      }
      closeModal()
  };

  useEffect(() => {}, [is_Rendered]);

  return (
    <div className="w-[50vw]">
      <div className={`my-2`}>
        <h1 className={`text-[#012063B2] font-[600] text-[25px] text-center`}>
          {selectedDaystring}
        </h1>
      </div>
      <div className="h-[50vh] w-full">
        <form
          onSubmit={handleSubmit}
          className={`py-6 bg-[#f5f5f5] h-full w-full max-w-full`}
        >
          <div className="!p-0 w-full ">
            <div className="w-full flex items-center">
              <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/5">
                Event Name
              </h4>
              <div className="w-4/5 pl-6">
                <input
                  required
                  type="text"
                  name="title"
                  placeholder="What will you call this event?"
                  value={formData.title}
                  onChange={(e) => {
                    setFormData((prev) => ({ ...prev, title: e.target.value }));
                  }}
                  className="p-2 w-80 max-w-full text-sm rounded-lg border focus:outline-none"
                />
              </div>
            </div>
            {/* <div className={`w-full flex items-center ${selectedDate?.length > 0 && 'hidden'}`}>
              <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/5">Event Date</h4>
              <div className="w-4/5 pl-6">
                <input required type="date" name="start_date" placeholder="What will you call this event?" value={formData.start_date} onChange={(e)=> {setFormData(prev=> ({...prev, start_date: e.target.value, end_date: e.target.value}))}} className="p-2 w-80 text-sm rounded-lg border focus:outline-none" />
              </div>
            </div> */}
            <div className="w-full flex items-center mt-7">
              <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/5">
                Description
              </h4>
              <div className="w-4/5 pl-6">
                <textarea
                  required
                  name="description"
                  placeholder="A brief summary about this event"
                  value={formData.description}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  className="p-2 w-80 max-w-full text-sm rounded-lg border focus:outline-none"
                />
              </div>
            </div>
            <div className="w-full flex items-center mt-6">
              <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/5">
                To be seen by
              </h4>
              <div className="w-4/5 pl-6 ">
                <div className="bg-white text-gray-600 w-max max-w-full flex items-center justify-evenly rounded border p-4 py-3">
                  {/* <label className="flex items-center mr-4 text-[14px]">
                    <input
                      name="seenBy"
                      // defaultChecked={editMode && editData?.user_type === "all"}
                      checked={formData.user_type === "all"}
                      onChange={(e) => {
                        setFormData((prev) => ({ ...prev, user_type: "all" }));
                      }}
                      type="checkbox"
                      className="form-input checkbox mr-2"
                    />
                    All
                  </label> */}
                  <label className="flex items-center mr-4 text-[14px]">
                    <input
                      name="seenBy"
                      // defaultChecked={
                      //   editMode && editData?.user_type === "teacher"
                      // }
                      checked={
                        // formData.user_type === "all" ||
                        formData.user_type === "teacher"
                      }
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          user_type: "teacher",
                        }));
                      }}
                      type="checkbox"
                      className="form-input checkbox mr-2"
                    />
                    Teachers only
                  </label>
                  <label className="flex items-center mr-4 text-[14px]">
                    <input
                      name="seenBy"
                      // defaultChecked={
                      //   editMode && editData?.user_type === "parent"
                      // }
                      checked={
                        // formData.user_type === "all" ||
                        formData.user_type === "parent"
                      }
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          user_type: "parent",
                        }));
                      }}
                      type="checkbox"
                      className="form-input checkbox mr-2"
                    />
                    Parents and Teachers
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full flex items-center mt-6">
              <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/5">
                Reminder
              </h4>
              <div className="w-4/5 pl-6 flex items-center">
                <div className="w-80 min-w-max h-12 mr-1 pb-1 rounded-lg border">
                  <TimeSelect
                    value={formData.time ?? "09:00"}
                    onChange={(val: string) => {
                      setFormData((prev) => ({
                        ...prev,
                        time: val,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-10 flex h-12 w-full">
              <div className="w-1/5"></div>
              <div className="w-4/5 pl-6">
                <button
                  type="submit"
                  className="flex items-center justify-center text-white w-[200px] h-full font-bold rounded bg-[#0096D1]"
                >
                  {editMode ? "Save" : "Create"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEvent;
