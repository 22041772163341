import { useState } from 'react';
import Calendar from 'react-calendar';
import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from "@bjarkehs/react-nice-dates";
import '@bjarkehs/react-nice-dates/build/style.css'
import { Link } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { useSelector } from 'react-redux';

export const AdminCalRec = () => {
        const user = JSON.parse(sessionStorage.getItem("user") as string);
        const myDate = new Date();
        const today = myDate.toUTCString().split(" ").slice(0, 4).join(" ");
        const [date, setDate] = useState(myDate);
        const { conversations } = useSelector((state: any) => state.message);


        const recentMessages = (
                <div className="flex py-1 pt-2">
                        <img className="rounded-full mr-4" src="sfsda" alt="imae" />
                        <div>
                                <h2 className="font-bold tracking-wide text-[15px]">From Mrs Ajayi</h2>
                                <p className="tracking-tight leading-tight text-[14px]">
                                        Lorem ipsum dolor sit amer. Nunnjskatc vulputate libero et velit
                                        interdum, sociosqu ad litora...
                                </p>
                                <div className="flex justify-between text-[#4d4d4d]/[0.67] text-[14px] italic">
                                        <span>2 hours ago</span>
                                        <span className="font-bold">Kenny A.</span>
                                </div>
                        </div>
                </div>
        );

        return (
                <div className="flex flex-col items-center justify-between pt-5 h-full min-w-[380px] text-[#012063]">
                        <div className={`h-[50vh] w-full overflow-hidden bg-white border border-[#ccc] rounded-[15px] p-5`}>
                                <div className="mb-3 mt-1">
                                        <h4 className="text-[#012063]/[.7] text-[15px] font-medium">{today}</h4>
                                        <h1 className="text-[#012063] text-[18px] font-medium">Today</h1>
                                </div>
                                <div className="w-[90%] h-full mx-auto text-[14px]">
                                        <DatePickerCalendar date={date} locale={enGB} />
                                </div>
                        </div>
                        <div className="h-[25vh] w-full overflow-y-hidden bg-white border border-[#ccc] rounded-[15px] p-5 mt-5">
                                <div>
                                        <span>
                                                <Link to={"/admin/message"} className="text-[13px] font-bold p-2 px-3 border-2 border-[#ccc] rounded-md ">Recent Messages</Link>
                                        </span>
                                </div>
                                <div className='min-w-full'>
                                        {conversations && conversations?.map((item: any, idx: number) => {
                                                // console.log(item);
                                                return (
                                                        <div className='mt-4' key={idx}>
                                                                <h2 className="font-bold tracking-wide text-[15px]">{item.recipient}</h2>
                                                                <p className="tracking-tight leading-tight text-[14px]">
                                                                        {item.last_message}
                                                                </p>
                                                                <div className="flex justify-between text-[#4d4d4d]/[0.67] text-[14px] italic">
                                                                        <span>{item.last_message_at}</span>
                                                                        <span className="font-bold">{item.recipient_type}</span>
                                                                </div>
                                                        </div>
                                                )
                                        })
                                        }
                                </div>
                        </div>
                </div>
        )
}
