import React from "react";
import UserTableRow, { IUserData } from "./userTableRow";

const UserTableBody = ({data}:{data:IUserData[]}) => {
  return (
    <div className="w-full">
      {data.map((item, index)=>{
        return <UserTableRow key={`${item.address}-${index}`} {...item}/>
      })}
      
    </div>
  );
};

export default UserTableBody;
