
function LandingAbout() {
  return (
    <div>
              <section className="landingAbout sm:h-[400px] py-5 flex justify-between flex-wrap px-5">
    <div className="w-full sm:w-[35%]">
    <img src="/11.svg" alt="" className="landingAboutimg" />
    <img src="/11m.svg" alt="" className="landingAboutimg2" />
    </div>

    <div data-aos="flip-up" className="flex items-center w-full sm:w-[65%] text-center">
    <p className="sm:text-xl">
        <span> Pendulum Digital</span> is dedicated to reshaping the future of education
          by offering a multifaceted digital solution that makes practical strategy and alignment
           with learning seamless for educators and pupils while equipping parents with real-time
            information.
            <br />
            <span className="!my-3 !font-normal !text-[16px] sm:!text-xl">
              Pendulum Digital empowers all stakeholders to succeed in an evolving digital world.
              </span>
        </p>
    </div>
      </section>


      <section className="landingHow">
        <h2>How Pendulum works</h2>
        <div className="howContent !mt-7  ">
          <div className="howContentCap">
            <div className="howDoll">
              <img data-aos="fade-right" src="/4.png" alt="" />
            </div>
            <div  className="howText">
              <img data-aos="fade-left" src="/5.png" alt="" className="" />
              <p >
              A robust childcare app that enable parents to keep track of school activities
              </p>
            </div>
          </div>

          <div className="howContentCap2">
            <div className="howText">
              <img data-aos="fade-up" src="/21.svg" alt="" />
              <p>
              A robust childcare app that enable parents to keep track of school activities
              </p>
            </div>
            <div className="howDoll">
              <img data-aos="fade-down" src="/4.png" alt="" />
            </div>
          </div>
          <div className="howContentCap2">
            <div className="howText">
              <img src="/22.svg" data-aos="fade-up" alt="" className="!h-[450px]" />
              <p> A digital platform that simplifies instructive alignment with learning via gamified resources.
                <br />
              As well as a secured and seamless pick up authorization portal
              </p>

              <p></p>
            </div>
            <div className="howDoll">
              <img data-aos="fade-down" src="/6.png" alt="" />
            </div>
          </div>


          <div className="howContentCap2 my-6">
            <div className="howText">
              <img src="/23.svg" data-aos="fade-up" alt="" />
              <p>
              A school to parent communication portal with instant messaging app
              <br />
              A virtual calendar that improves parental engagement as per school events
              </p>
            </div>
            <div className="howDoll">
              <img data-aos="fade-down" src="/3.svg" alt="" />
            </div>
          </div>

          <div className="howContentCap">
            <div className="howText2">
              <img data-aos="fade-right" src="/8.png" alt="" />
              <p> A digital platform that simplifies instructive alignment with learning via gamified resources.
                <br />
              As well as a secured and seamless pick up authorization portal

              </p>
            </div>
            <div className="howDoll2" >
              <img data-aos="fade-left" src="/6.png" alt="" />
            </div>
          </div>


          <div className="howContentCap mb-5">
            <div className="howText2">
              <img data-aos="fade-right" src="/2.svg" alt="" />
              <p>
              A school to parent communication portal with instant messaging app
              </p>
            </div>
            <div className="howDoll22">
              <img data-aos="zoom-in" src="/3.svg" alt="" />
            </div>
            <div className="howText">
              <img data-aos="fade-left" src="/1.svg" alt="" />
              <p>
              A virtual calendar that improves parental engagement as per school events
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LandingAbout
