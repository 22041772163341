import React from 'react'

import img1 from "../../../assets/image/report docs/x1.png"
import img2 from "../../../assets/image/report docs/x2.png"
import img3 from "../../../assets/image/report docs/x3.png"
import img4 from "../../../assets/image/report docs/x8.png"


function DocsHeader() {
  return (
    <div className='my-16'>
      <h3 className='font-bold text-xl text-center mb-4'>HOW TO GENERATE A REPORT SHEET</h3>
      <p className='my-2'>
      In this module, you will learn how to use the Pendulum app to generate an academic report sheet.
       First, you need to have typed in your report information in an Excel file,
        a report information example;
      </p>

      <div className='my-8'>
        <b>For preschool or nursery classes:</b>
        <div className='my-5'>
        <img src={img1} alt='' className='rounded-md h-full w-full' />
        </div>
        <div className='my-5'>
        <img src={img2} alt='' className='rounded-md h-full w-full' />
        </div>
      </div>


      <div className='my-8'>
        <b>Primary Class:</b>
        <div className='my-5'>
        <img src={img3} alt='' className='rounded-md h-full w-full' />
        </div>
      </div>

      <div className='my-8'>
        <b>Primary Class (Attitude report example):</b>
        <div className='my-5'>
        <img src={img4} alt='' className='rounded-md h-full w-full' />
        </div>
      </div>
    </div>
  )
}

export default DocsHeader
