import React, { useState } from 'react'
import { defaultUrl } from '../features/actions'
import { toast } from 'react-hot-toast'
import { makePostRequest } from '../utilities/common'
import { useNavigate } from 'react-router-dom'

const ChanPasssord = () => {
  let navigate = useNavigate()
  let [formData, setFormData] = useState({
    current_password:"",
    password: "",
    password_confirmation: ""
  });

  function cancelSave(){
    setFormData({
      current_password:"",
      password: "",
      password_confirmation: ""
    })
  }

  async function saveNewPassword() {
    if (formData.current_password.length<3 || formData.password.length<3 || formData.password_confirmation.length<3) {
      toast.error("All input fields must be filled!");
      return;
    }

    try{
       // Check if the user has changed their password
       if (formData.current_password === formData.password ) {
        toast.error("Your old password is the same with the new password");
        return;
      }
      else if (formData.password_confirmation !== formData.password) {
        toast.error("Please ensure that the new password matches the password confirmation");
        return;
      }
      toast.loading("Please wait...")
      await makePostRequest(`${defaultUrl}/update-password`, formData,)
      cancelSave()
      toast.remove();
      toast.success("Password updated successfully")
      // navigate home
      setTimeout(() => {
        navigate("/")
      },3000)

    } catch(e:any) {
      // console.log({error: e.response.data || e})
      toast.remove();
      toast.error("Failed to update password, Please try again")
    }
  }



  return (
     <div className="schProfile">
      <div className="inputDv">
        <div className="input h-20">
          <label className='!text-sm'>Old Password</label>
          <input
            className='p-2 focus:outline-none'
            onChange={(e)=> setFormData((prev)=> {
              return { ...prev, current_password: e.target.value}
            })}
            type="text"
            value={formData.current_password}
          />
        </div>
        <div className="input h-20">
          <label className='!text-sm'>New Password</label>
          <input
            className='p-2 focus:outline-none'
            onChange={(e)=> setFormData((prev)=> {
              return { ...prev, password: e.target.value}
            })}
            type="text"
            value={formData.password}
          />
        </div>
        <div className="input h-20">
          <label className='!text-sm'>Confirm Password</label>
          <input
            className='p-2 focus:outline-none'
            onChange={(e)=> setFormData((prev)=> {
              return { ...prev, password_confirmation: e.target.value}
            })}
            type="text"
            value={formData.password_confirmation}
          />
        </div>
        <div className="cameraBtn1 flex flex-wrap">
          <button onClick={saveNewPassword} className='!text-sm m-4 ml-0 !h-12 max-w-[200px]'>Save Changes</button>
          <button onClick={cancelSave} className="btn2 !text-sm m-4 mr-0 !h-12 max-w-[200px]">Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default ChanPasssord
