import SearchBar from "../Reusables/SearchBar";

export const StudentFilter = ({ classrooms, setClassRoomFilter, classRoomFilter,
                                 setSearchTerm, filterByBirthday, setFilterByBirthday
                               }: any) => {
  return (
    <div className="flex flex-wrap justify-start mr-6 md:justify-between
    text-[#1f1f1f]/[0.7] md:mt-6 mb-3 w-[98%] ">
         <div className="flex items-center w-full max-w-[240px] min-w-max">
           <select
             onChange={(e) => {
               setClassRoomFilter(e.target.value);
             }}
             className={`border-2 border-gray-200 rounded-2xl w-full max-w-[240px] min-w-[214px] mr-2 p-2 py-2 text-center focus:outline-none ${
               classRoomFilter.length && "border-blue-600"
             }`}
             defaultValue={"one"}
           >
             <option value="">All Classrooms</option>
             {classrooms?.map((clas: any, idx: number) => (
               <option key={idx} value={clas.name}>
                 {clas.name}
               </option>
             ))}
           </select>
           <label
             className={`px-2 pl-4 py-2 rounded-2xl border-2 border-gray-200 flex items-center min-w-max ${
               filterByBirthday && "border-blue-600"
             }`}
           >
             <input
               type="checkbox"
               className={`mr-2`}
               onChange={() => setFilterByBirthday(!filterByBirthday)}
             />
             Birthday is Today
           </label>
         </div>
         <div className="w-4/12 min-w-max mt-6 md:mt-0">
         <SearchBar onChange={(e: any) => setSearchTerm(e.target.value)} />
         </div>
       </div>
  );
};
