import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/main.css";
import BackButton from "../components/backButton";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { defaultUrl } from "../features/actions";
import Pagination from "../components/pagination";
import { IPaginationParams } from "../types/interfaces";
import loader from "../assets/loader.gif";
import { makeFetchRequest, makePostRequest } from "../utilities/common";
import CustomModal from "../components/CustomModal/CustomModal";
import useWindowDimensions from "../hooks/useWindowSize";
import SearchBar from "../components/Reusables/SearchBar";
import toast from "react-hot-toast";
import BroadcastForm from "../components/Messages/BroadcastForm";

export const SelectReceiver = () => {
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 440;
  const navigate = useNavigate();
  const [getData, setGetData] = useState([]);
  const [getValue, setGetValue] = useState("parents");
  const [paginationParams, setPaginationParams] = useState<IPaginationParams>({
    page: 1,
    totalPages: 1,
    totalItems: 1,
    from: 1,
    to: 1,
  });
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [popUp, setPopUp] = useState(false)
  const [messageToSend, setMmssageToSend] = useState("")
  const [selected, setSelected] = useState([{}])
  const [filteredData, setFilteredData] = useState(getData);
  const [isSelected, setIsSelected] = useState(Array.from({ length: getData.length }, () => false));



  async function queryGetData() {
    setIsLoading(true);
    try {
      const res = await makeFetchRequest(`${defaultUrl}/${getValue}`, true)
      setGetData(res.data.data);
      // console.log("response", res);
      setPaginationParams({
        ...paginationParams,
        totalPages: res?.data?.meta?.last_page,
        from: res?.data?.meta?.from,
        to: res?.data?.meta?.to,
        totalItems: res?.data?.meta?.total,
      });
      const filtered = res.data.data.filter((data: any) => {
        if (!searchTerm.length) {
          return data;
        }
        // console.log(searchTerm, data.name.includes(searchTerm));
        return data.name.toLowerCase().includes(searchTerm.toLowerCase());
      });

      setTotalPage(res?.data?.meta?.last_page);
      setFilteredData(filtered);
      setIsLoading(false);
      return filtered;
      // console.log(getValue, res.data.data);
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    queryGetData();
  }, [searchTerm, page]);


  const handleMessageId = async (item: any, index: number) => {
    // Make a copy of the previous arrayState which are set to true/false,
    // and assign to a new variable newArray
    const newArray = [...isSelected];
    // Assign each index of the clicked to
    // opposite of the initial value (i.e., True becomes false and vice versa)
    newArray[index] = !newArray[index];
    setIsSelected(newArray); // Set the new state.

    setSelected((prevValue) => {
      // Check if the item is already selected
      if (prevValue.includes(item)) {
        // Item is already selected, so we remove it
        return prevValue.filter(prevItem => prevItem !== item);
      } else {
        // Item is not selected, so we add it
        return [...prevValue, item];
      }
    });
  };



  const showMsgPopUp = () => {
    setPopUp(true)
  }

  const handleBCMessage = (e: any) => {
    e.preventDefault()
    if (messageToSend === "") {
      toast.error("Message cannot be empty")
    } else {
      setPopUp(false)
      selected.shift()  //Remove first element
      selected.map(async (item: any) => {
        const body = {
          message: messageToSend,
          reciever_id: item.id,
          reciever_type: "parent",
        }
        await makePostRequest(`${defaultUrl}/messages`, body)
      })
      navigate("/admin/message")
    }

  }


  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Message" />
      </div>

      <div className="flex-1 h-[98vh]">
        <div className="block w-full h-full overflow-auto register-scroll">
          <div className="flex items-center justify-between">
            <div className="w-full mr-3">
              <RegisterCard
                title="Select Receipient"
                details="Select who you want to send message to."
                activeNumber={10}
                activeDetails="Active Class"
              />
            </div>
          </div>

          <div className="rounded-lg md:px-3 !pb-0 !pt-[0.05px] mt-7 h-[90%] attendance_bg">
            <div className="flex flex-wrap gap-5 justify-start px-2
            md:justify-between text-[#1f1f1f]/[0.7] md:mt-6 mb-3 w-[98%] ">

              <SearchBar onChange={(e: any) => setSearchTerm(e.target.value)} />
            </div>

            <div className="text-[#012063CC] mt-1 md:p-4 px-2">
              <div className="w-full text-center flex-1 overflow-hidden md:overflow-x-auto">
                <div className="hidden md:flex w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
                  <div className="w-1/12 text-[0.9em] font-bold flex justify-center">
                    S/N
                  </div>
                  <div className="w-6/12 text-[0.9em] font-bold flex justify-center">
                    Name
                  </div>
                  <div className="w-4/12 text-[0.9em] font-bold flex justify-center">
                    Message
                  </div>
                  <div className="w-1/12 text-[0.8em]"></div>
                </div>
                <div className="h-[58vh] w-[98%]  overflow-auto register-scroll">
                  {isLoading ? (
                    <div className="flex justify-center m-4">
                      <div>
                        <img src={loader} alt="loader" className="" />
                      </div>
                    </div>
                  ) : (
                    <div>
                    {filteredData.length === 0
                      ?
                      <div
                      className="w-full text-center
                      font-semibold text-[#012063]/[0.7] text-gray-600
                      py-3 rounded-xl mt-[5px]"
                    >
                      <p>No Result</p>
                    </div>
                    :
                    // Display fetched result
                    <>
                    {filteredData && filteredData.map((item: any, index: number) => {
                      // console.log(filteredData)
                      return (
                        <div
                          key={item.id}
                          className="w-full border text-center
                          font-semibold text-[#012063]/[0.7] text-gray-600 bg-white
                          py-3 rounded-xl mt-[5px]"
                        >
                          <div className="flex flex-wrap items-center w-full border-blue-500">
                            <div className="w-1/12 flex justify-center items-center">
                              <div
                                onClick={() => handleMessageId(item, index)}
                                className={`${isSelected[index] && 'bg-[#0096D1]'} rounded-full h-3 w-3 ${item.isOnline
                                  ? "bg-green-500"
                                  : `border border-gray-400`
                                  }`}
                              ></div>
                            </div>

                            <div className="w-6/12">
                              {!item.relationship ? (
                                <p
                                  className={`text-center text-[14px] text-[#012063CC] font-semibold ${!item.isRead && "font-bold text-blue-800"
                                    }`}
                                >
                                  {item.surname + " " + item.othername ?? null}
                                </p>
                              ) : (
                                <p
                                  className={`text-center text-[14px] text-[#012063CC] font-semibold ${!item.isRead && "font-bold text-blue-800"
                                    }`}
                                >
                                  {item.name ?? null}
                                </p>
                              )}

                            </div>
                            <div className="w-4/12 text-[15px] flex justify-center">
                              <button
                                onClick={() => handleMessageId(item, index)}
                                className="px-4 py-2 rounded bg-[#0096D1] h-[30px] text-xs text-center text-gray-200 align-center"
                              >
                                Select
                              </button>
                            </div>

                          </div>
                        </div>
                      );
                    })
                    }
                      </>

                    }
                    </div>
                  )



                  }
                </div>


                <div className="flex justify-end p-4 m-4">
                  <button disabled={selected.length <= 1}
                    onClick={showMsgPopUp}
                    className="bg-[#0096D1] px-4 py-2 text-gray-200">Proceed</button>
                </div>

                {/* SHOW BC MESSAGE POPUP */}
                {popUp && <CustomModal
                  title={`Message`}
                  children={
                      <BroadcastForm
                      mobileScreen={isMobileScreen}
                      messageToSend={messageToSend}
                      onChange={(e: any) => {
                        setMmssageToSend(e.target.value)
                      }}
                       onSubmit={handleBCMessage}
                      />
                  }
                  hideModal={() => {
                    setPopUp(false)
                  }}
                />}


                <div className="my-5">
                  <Pagination
                    page={page}
                    totalPages={totalPage}
                    setPage={setPage}
                    paginationParams={paginationParams}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
