// column chart
import ReactApexChart from 'react-apexcharts';
import './ColumnChart.css';

const GroupedBarChart = ({ data, xaxis, titleText, className }) => {
	const state = {
		series: data.map((item) => {
			return {
				name: item.name,
				data: item.data
			}
		}),
		options: {
			chart: {
				height: 430,
				width: '100%',
				type: 'bar',
				animation: {
					enabled: "true",
					easing: "easeinout",
					speed: 800,
					animateGradually: {
						enabled: true,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 350
					}
				},
			},
			colors: data.map(item=> item.colorCode),
			plotOptions: {
				bar: {
					columnWidth: '50%',
					distributed: false,
					horizontal: false,
				},
			},
			stroke: {
			  show: true,
			  width: 1.5,
			  colors: ['#fff']
			},
			tooltip: {
			  shared: false,
			  intersect: true
			},
			dataLabels: {
				enabled: false,
			},
			legend: {
				show: true,
			},
			xaxis: {
				categories: xaxis,
				colors: ['#fff', '#afafaf', '#afafaf', '#ccc'],
				labels: {
					show: true,
					style: {
						colors: [],
						fontSize: '12px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 400,
						cssClass: 'apexcharts-xaxis-label',
					},
				},
			},
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: ['gray'],
						fontSize: '18px',
						fontFamily: 'lato, Arial, sans-serif',
						fontWeight: 600,
						cssClass: 'y-axis-column-text',
					},
				},
			},
			title: {
				text: titleText? titleText : '',
				floating: true,
				style: {
					color: 'navy',
					fontFamily: 'roboto, Arial, sans-serif',
					fontSize: '20px',
					fontWeight: 900,
				}
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: '100%',
							height: 400,
						},
						plotOptions: {
							bar: {
								columnWidth: '50%',
								distributed: false,
								horizontal: true,
							},
						},			
						colors: data.map(item=> item.colorCode),
						dataLabels: {
							enabled: false,
						},
						legend: {
							position: 'top',
						},
						title: {
							text: titleText? titleText : '',
						},
					},
				},
			],
		},
	};

	return (
		<div className={className}>
			<ReactApexChart options={state.options} series={state.series} type="bar" height={380} />
		</div>
	);
};

export default GroupedBarChart;