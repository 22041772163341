import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/main.css";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { globalToken } from "./AdminHome";
import BackButton from "../components/backButton";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import Swal from "sweetalert2";
import useWindowDimensions from "../hooks/useWindowSize";
import { defaultUrl } from "../features/actions";

interface listItem {
  user_id: string;
  name: string;
  type: string;
  image?: string;
  logged_in: string;
  logged_out: string;
}

export const Attendance = () => {
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 440;
  const [list, setList] = useState<listItem[]>([]);
  useEffect(() => {
    const searchBar = document.getElementById("searchAttendanceInput");
    searchBar?.focus();

    fetchList();
  }, []);
  const fetchList = async () => {
    try {
      const response = await axios.get(
        `${defaultUrl}/attendance`,
        {
          headers: {
            Authorization: "Bearer " + globalToken,
          },
        }
      );
      if (response?.data?.data?.length) {
        console.log({ response: response.data.data });
        setList(response.data.data);
      }
    } catch (err: any) {
      console.log({ err });
    }
  };

  /** FILTERINGS */
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [filteredData, setFilteredData] = useState(list);
  useEffect(() => {
    filterData();
  }, [typeFilter, searchTerm, list]);
  function filterData() {
    const filtered = list
      .filter((data) => {
        if (!searchTerm.length) {
          return data;
        }
        console.log(searchTerm, data.name.includes(searchTerm));
        return data.name.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .filter((data) => {
        if (!typeFilter.length) {
          return data;
        }

        return data.type.toLowerCase() === typeFilter.toLowerCase();
      });

    console.log({ filtered });
    setFilteredData(filtered);
  }

  /** ACTIONS */
  async function login(id: string) {
    try {
      const confirmAction = await Swal.fire({
        title: `Confirm Action`,
        text: `Log in this pupil?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "green",
        confirmButtonText: "Log in",
      });
      if (confirmAction.isConfirmed) {
        const result = await axios.post(
          `${defaultUrl}/attendance/login`,
          {
            user_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${globalToken}`,
            },
          }
        );
        // if successful, go back
        if (result.data.message) {
          Swal.fire({
            title: `Success!`,
            text: `${result.data.message}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "green",
            confirmButtonText: "Continue",
          }).then(() => {
            fetchList();
          });
        }
        return result;
      }
    } catch (error: any) {
      if (error.response.data.message) {
        Swal.fire({
          title: `Oops!`,
          text: `${error.response.data.message}`,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "gray",
          confirmButtonText: "Continue",
        }).then(() => {
          fetchList();
        });
      }
    }
  }
  async function logout(id: string) {
    try {
      const confirmAction = await Swal.fire({
        title: `Confirm Action`,
        text: `Log out this pupil?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "navy",
        confirmButtonText: "Log out",
      });
      if (confirmAction.isConfirmed) {
        const result = await axios.post(
          `${defaultUrl}/attendance/logout`,
          {
            user_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${globalToken}`,
            },
          }
        );
        // if successful, go back
        if (result.data.message) {
          Swal.fire({
            title: `Success!`,
            text: `${result.data.message}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "green",
            confirmButtonText: "Continue",
          }).then(() => {
            fetchList();
          });
        }
        return result;
      }
    } catch (error: any) {
      if (error.response.data.message) {
        Swal.fire({
          title: `Oops!`,
          text: `${error.response.data.message}`,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "gray",
          confirmButtonText: "Continue",
        }).then(() => {
          fetchList();
        });
      }
    }
  }

  return (
    <section>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Attendance Report" />
      </div>

      <div className="flex">
        <div
          className={`block w-full h-[87vh] ${
            isMobileScreen ? "h-[89vh] overflow-hidden" : "h-[87vh]"
          }`}
        >
          <div className="flex w-full ">
            <div className="w-full">
              <RegisterCard
                title="View and manage attendance"
                details="Lets mark it now"
              />
            </div>
          </div>
          {isMobileScreen ? (
            <div className="flex justify-between text-[#1f1f1f]/[0.7] my-3 w-full mx-auto ">
              <div className="w-full">
                <div className="flex">
                  <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 w-full ">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                      id="searchAttendanceInput"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="search by name"
                      className="rounded-2xl pl-4 p-2 w-full focus:outline-none "
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-between text-[#1f1f1f]/[0.7] my-7 w-full mx-auto ">
              <div className="w-3/12 ">
                <select
                  defaultValue={""}
                  onChange={(e) => setTypeFilter(e.target.value)}
                  className="border-2 border-gray-200 rounded-2xl w-full mx-2 p-2 text-center focus:outline-none"
                >
                  <option value="">All, Staff, Student</option>
                  {["teacher", "student", "others"].map((classroom, index) => {
                    return (
                      <option
                        className="capitalize"
                        value={classroom}
                        key={index}
                      >
                        {classroom}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="w-2/12 ">
                <select className="border-2 border-gray-200 rounded-2xl w-10/12 p-2">
                  <option>Export XLS</option>
                  <option>Export CSV</option>
                </select>
              </div>
              <div className="w-1/12">
                <div className="flex ">
                  <span className="border-2 border-gray-200 rounded-2xl mx-2 text-center static">
                    <img
                      src="../../img/fingerscan.png"
                      alt="fi"
                      className="inline p-3 py-2"
                    />
                  </span>
                </div>
              </div>
              <div className="w-4/12">
                <div className="flex">
                  <div className="w-1/12"></div>
                  <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 w-10/12 mx-2 ">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                      id="searchAttendanceInput"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="search by name"
                      className="rounded-2xl pl-4 p-2 w-full focus:outline-none "
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="border-4 text-[#012063] attendance_bg my-5 mt-1 p-5 shadow-xl rounded border-t border-gray-100 h-[72vh]">
            {isMobileScreen ? (
              <div className=" w-full h-[71vh]">
                <div className="w-full text-center">
                  <div className="h-[66vh] overflow-auto register-scroll">
                    {list.length ? (
                      filteredData.map((item, index) => {
                        if (item.type === "student")
                          return (
                            <div className="border min-w-[282px] w-full h-[120px] text-center text-[#012063]/[0.7] text-gray-600 bg-white p-1 rounded-[25px] mt-2 mx-auto">
                              <div className="relative flex w-full h-full">
                                <div className="w-2/5 min-w-20 flex flex-col justify-start items-start">
                                  <div className="w-full items-center flex justify-center ">
                                    <img
                                      src={item.image ?? ""}
                                      alt=""
                                      className="rounded-full border h-16 w-16 bg-gray-200"
                                    />
                                  </div>
                                  <div className="w-full h-full flex justify-center items-center text-[14px] font-semibold capitalize">
                                    <p>{item.name}</p>
                                  </div>
                                </div>
                                <div className="w-3/5 flex flex-col justify-start items-start text-[12px]">
                                  <div className="w-max px-1 rounded items-center flex justify-start font-semibold text-white bg-gray-500">
                                    <p>{item.type}</p>
                                  </div>
                                  <div className="w-full mt-1 items-center flex justify-start">
                                    <p>
                                      login:{" "}
                                      <span className="text-[#25D366]  w-full">
                                        {item.logged_in ?? "---"}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="w-full mt-1 items-center flex justify-start">
                                    <p>
                                      logout:{" "}
                                      <span className="text-[#FA614C] w-full">
                                        {item.logged_out ?? "---"}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="absolute right-1 bottom-1 w-max items-center flex justify-center w-[140px] focus:">
                                  <button
                                    onClick={() => {
                                      login(item.user_id);
                                    }}
                                    className={`flex items-center justify-center rounded px-2 py-0.5 bg-green-600 text-center text-white text-[13px] ml-1`}
                                  >
                                    Log In
                                  </button>
                                  <button
                                    onClick={() => {
                                      logout(item.user_id);
                                    }}
                                    className={`flex items-center justify-center rounded px-2 py-0.5 bg-[#012063] bg-opacity-80 text-center text-white text-[13px] ml-1`}
                                  >
                                    Log Out
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                      })
                    ) : (
                      <div className="rounded-xl border p-12 animate-pulse">
                        <h1>No Data... yet</h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className=" w-full h-[71vh]">
                <div className="w-full text-center">
                  <div className="flex w-[98%] pl-4 mb-2 font-semibold ">
                    <div className="w-1/12">S/N</div>
                    <div className="w-1/12"></div>
                    <div className="w-3/12"></div>
                    <div className="w-2/12"></div>
                    <div className="w-1/12"></div>
                    <div className="w-2/12 text-[0.8em]">Logged in</div>
                    <div className="w-2/12 text-[0.8em]">Logged out </div>
                  </div>
                  <div className="h-[66vh] overflow-auto register-scroll">
                    {list.length ? (
                      filteredData.map((item, index) => {
                        return (
                          <div className="border w-[98%] h-[60px] text-center text-[#012063]/[0.7] text-gray-600 bg-white p-1 m-2 rounded-[25px] mt-2">
                            <div className="flex w-full h-full">
                              <div className="w-1/12 items-center flex justify-center ">
                                {index + 1}
                              </div>
                              <div className="w-1/12 items-center flex justify-center rounded-[12px]">
                                <img
                                  src={item.image ?? ""}
                                  alt=""
                                  className="rounded-full w-[50px] w-[50px]"
                                />
                              </div>
                              <div className="w-3/12 items-center flex justify-center text-[15px]  font-semibold capitalize">
                                {item.name}
                              </div>
                              <div className="w-2/12 items-center flex justify-center text-[15px] capitalize">
                                {item.type}
                              </div>
                              <div className="w-1/12 items-center flex justify-center w-[140px] focus:">
                                <Menu
                                  menuButton={
                                    <MenuButton
                                      className={`flex items-center rounded px-4 py-1 bg-[#F8FCFD] text-[#012063]/[0.6] text-[15px]`}
                                    >
                                      action
                                      <img
                                        src="../../img/arrow_down.png"
                                        alt="arrow_down"
                                        className="inline pl-4 w-6"
                                      />
                                    </MenuButton>
                                  }
                                >
                                  <div className="border-2 w-44 bg-white">
                                    <MenuItem
                                      onClick={() => {
                                        login(item.user_id);
                                      }}
                                      className={`bg-white border-2 m-1 text-[14px] font-normal`}
                                    >
                                      Log In
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        logout(item.user_id);
                                      }}
                                      className={`bg-white border-2 m-1 text-[14px] font-normal`}
                                    >
                                      Log Out
                                    </MenuItem>
                                  </div>
                                </Menu>
                              </div>
                              <div className="w-2/12 items-center flex justify-center text-[#25D366] text-[15px]">
                                <p>{item.logged_in ?? "---"}</p>
                              </div>
                              <div className="w-2/12 items-center flex justify-center text-[#FA614C] text-[15px]">
                                <p>{item.logged_out ?? "---"}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="rounded-xl border p-12 animate-pulse">
                        <h1>No Data... yet</h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
