import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/main.css";
import "../assets/css/student.css";
import { useEffect, useState } from "react";
import useWindowDimensions from "../hooks/useWindowSize";
import toast from "react-hot-toast";
import { defaultUrl } from "../features/actions";
import { makeFetchRequest } from "../utilities/common";
import { StudentDataInterface } from "../types/interfaces";
import RenderStudentList from "./staff_components/staff_student_data/StudentList";
import StudentListInput from "./staff_components/staff_student_data/StudentListInput";




export const StaffStudentData = () => {
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 440;


  useEffect(() => {
    const searchBar = document.getElementById("searchAttendanceInput");
    searchBar?.focus();
    toast.remove(); toast.loading("loading pupil data...");
    fetchStudentData()
    toast.remove(); toast.success("loading complete");
  }, [])

  /** DATA FETCHING */
  const [studentData, setStudentData] = useState<StudentDataInterface[]>([]);
  const [classes, setClasses] = useState<any>()

  const fetchStudentData = async () => {
    // GET STUDENTS
    const response = await makeFetchRequest(`${defaultUrl}/students`)
    const students = response && response.map((student: any) => {
      return {
        ...student,
        profile_picture: <img style={{ height: '100%', width: '100%' }}
          src={student.profile_picture} alt="pupil" />,
      }
    })
    // GET CLASSROOMS
    const classroomsData = await makeFetchRequest(`${defaultUrl}/classroom`)
    setStudentData(students);
    setClasses(classroomsData)
  }


  /** FILTERINGS **/
  const [searchTerm, setSearchTerm] = useState("");
  const [classRoomFilter, setClassRoomFilter] = useState("");
  const [filteredData, setFilteredData] = useState(studentData);

  useEffect(() => {
    setFilteredData(filterData());
  }, [studentData, searchTerm, classRoomFilter])

  function filterData() {
    const filtered = studentData && studentData
      .filter(data => {
        if (!searchTerm.length) {
          return data;
        }
        const fullName = `${data.firstname} ${data.othername} ${data.surname}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .filter(data => {
        if (!classRoomFilter.length) {
          return data;
        }

        return data.classroom?.name.toLowerCase() === classRoomFilter.toLowerCase();
      });
    return filtered;
  }


  return (
    <>
      <div className="w-full flex items-center">
        <TopAdminNav name="Jane Doe" title="Pupil Data" />
      </div>

      <div className="flex">
        <div className="block w-full h-[90vh] overflow-hidden">
          <div className="flex">
            <div className="w-full">
              <RegisterCard title="Pupil Management" details="Update on pupil" />
            </div>
          </div>

          <div className="mt-2 attendance_bg">
            <StudentListInput
              mobileScreen={isMobileScreen}
              onChange={
                (e: any) => setSearchTerm(e.target.value)
              }
            />
            <div className={`text-[#012063CC] mt-1
              ${isMobileScreen ? 'mx-auto overflow-y-scroll !pb-[80px] h-[72vh] md:h-[60vh]' : 'p-4 px-2'}`}>
              {isMobileScreen ?
                <RenderStudentList
                  data={filteredData}
                  data2={classes}
                  mobilescreen={isMobileScreen}
                />
                : (
                  <>
                    <div className="w-full overflow-x-scroll">
                      <div className="flex w-[98%] !min-w-[700px] mb-2 py-4
                     bg-[#F4F7FE] rounded-xl font-semibold border">
                        <div className="w-2/12 flex justify-center min-h-[20px]" />
                        <div className="w-4/12 text-[0.9em]
                      font-bold flex justify-center min-h-[20px]">
                          <p>Name</p>
                        </div>
                        <div className="w-2/12 text-[0.9em]
                       font-bold flex justify-center min-h-[20px]">
                          <p>Date Of Birth</p></div>
                        <div className="w-2/12 text-[0.9em] font-bold
                      flex justify-center min-h-[20px]">
                          <p>Classroom</p></div>
                        <div className="w-2/12" />
                      </div>
                      <div className="h-[60vh] w-[98%] !min-w-[700px] overflow-y-scroll !pb-[80px]
                     register-scroll">
                        <RenderStudentList
                          data={filteredData}
                          data2={classes}
                          mobilescreen={isMobileScreen}
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
