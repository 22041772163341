export interface ITableData {
  school: string;
  subject: string;
  messageContent: string;
  time: string;
  index?: number | undefined;
}
const TableRow = (props: ITableData) => {
  const { school, subject, messageContent, time, index } = props;
  return (
    <tr
      className={`font-medium text-xs lg:text-sm text-center  ${
        ((index as number) + 1) % 2 == 0
          ? " bg-[#E9F6FC]  border-t border-b border-[#0096D12E]"
          : "bg-[#FCFCFC]"
      }`}
    >
      <td className="py-3 text-[#012063d5]">{school}</td>
      <td className="py-3">
        <span className="text-[#5F5454]">{subject} : </span>
        <span className="text-[#A89F9F]">{messageContent}</span>
      </td>
      <td className="py-3 text-[#5F5454]">{time}</td>
    </tr>
  );
};

export default TableRow
