import { createSlice } from "@reduxjs/toolkit";
import { registerParentThunk } from "../actions";

const initialState = {
        parentIncomingData: [
                {
                        name: "", 
                        email: "", 
                        mobilePhone: "", 
                        otherPhones: "", 
                        relationship: ""
                }
        ],
        status: "",
        errMessage: ""
}

const parentSlice = createSlice({
        name: 'parent',
        initialState,
        reducers: {

        },
        extraReducers(builders) {
                builders
                        .addCase(registerParentThunk.pending, ({status}) => {
                                status = 'pending'
                        })
                        .addCase(registerParentThunk.fulfilled, ({status},{payload}) => {
                                status = 'fulfilled'
                        })
                        .addCase(registerParentThunk.rejected, ({status, errMessage}, {payload}:any)=> {
                                status = 'rejected'
                                errMessage = payload
                        })
        }
})


export default parentSlice.reducer