import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { useState, useEffect } from "react";
import BackButton from "../components/backButton";
import Swal from "sweetalert2";
import { formatTime } from "../utilities/importantFns";
import { defaultUrl } from "../features/actions";
import { StaffDataType } from "../types/interfaces";
import { makeFetchRequest, makePostRequest } from "../utilities/common";
import StudentListInput from "../staff_pages/staff_components/staff_student_data/StudentListInput";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


export const AuthurizePickup = () => {
  const date = new Date();
  const dateString = formatTime(date,true)
  const [staffData, setStaffData] = useState<StaffDataType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currentChild, setCurrentChild] = useState()

 /** FILTERINGS */
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(staffData);

  async function fetchData() {
    const pickUps = await makeFetchRequest(`${defaultUrl}/getAuthorizePickup`)
    const result = pickUps && pickUps.map((staff: any) => {
      return {
        ...staff,
        profile_picture: (
          <LazyLoadImage
            style={{ height: "100%", width: "100%" }}
            src={staff.picker_image}
            effect="blur"
            wrapperProps={{
                style: {transitionDelay: "1s"},
            }}
            alt=""
          />
        ),
      };
    })
    setStaffData(result && result.reverse())
  }


  function filterData() {
    const filtered = staffData && staffData
      .filter((data) => {
        if (!searchTerm.length) {
          return data;
        }
        const token = data.picker_token;
        return token.toLowerCase().includes(searchTerm.toLowerCase());
      })
    setFilteredData(filtered);
    return filtered;
  }

  // Show pop up to authorize pickup
  const authorize = () => {
    setOpenModal(!openModal);
  };

  // Confirm pickup
  const confirmAuthrize = async (id: any, user_id: any) => {
    await makePostRequest(`${defaultUrl}/picked/${id}`,{}, "Pickup Confirmed")
    setRefresh(!refresh)
    handleLogout(user_id);
  };

  // Show pickup warning dialog
  const callDialog = () => {
    const { id, picker_token, student} : any = currentChild
    Swal.fire({
      title: `Confirn pickup with code: <b>${picker_token}</b>`,
      html: `You are confirming <b> ${student?.firstname || ""} ${student?.surname || ""}
      ${student?.othername || ""}</b> to be picked`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm it!"
    }).then((result) => {
      if (result.isConfirmed) {
        confirmAuthrize(id, student?.id)
      }
      setOpenModal(false)
    });
  }

  // Log child out if pick up is confirmed
  const handleLogout = async (user_id: any) => {
    const logOutData = {
      type: "logout",
      student_id: [user_id],
      time:dateString,
      image: ["null"],
      video: "null",
      description: "picked"
      }

    await makePostRequest(`${defaultUrl}/activity_feed/create`,logOutData, "Student Logged out")
  };




  useEffect(() => {
    filterData();

  // eslint-disable-next-line
  }, [searchTerm, staffData]);


  useEffect(() => {
    const searchBar = document.getElementById("searchStaffInput");
    searchBar?.focus();
    fetchData()
  }, [refresh])




  return (
    <div style={{ position: "relative" }}>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Authorised Pick Up" />
      </div>

      <div className="flex">
        <div className="block w-full h-[87vh] overflow-auto register-scroll">
          <div className="flex">
            <div className="w-full">
              <RegisterCard
                title="Authorize Pick Up "
                details="View and Manage all child pickup"
              />
            </div>
          </div>
          <div className="staffData border border-gray-100 !pb-0 !pt-[0.05px] mt-7 ">
                  <StudentListInput
                  searchType="by Token"
                  onChange={(e: any) => setSearchTerm(e.target.value)}
                  />
            <div className="text-[#012063CC] mt-1 p-4 px-2">
              <div className=" w-full">
                <div className="w-full text-center overflow-auto">
                  <div className="flex w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
                    <div className="w-4/12 text-[0.9em] font-bold flex justify-center">
                      Child Name
                    </div>

                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Contact Name
                    </div>
                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Contact Picture
                    </div>

                    <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                      Pickup Token
                    </div>
                    <div className="w-2/12 min-w-[70px] text-[0.8em]"></div>
                  </div>
                  <div className="h-[58vh] w-[98%] !min-w-[700px]  overflow-auto register-scroll">
                    {staffData && staffData.length > 0
                    ?
                    <>
                    {console.log(filteredData[0])}
                    {staffData &&
                      filteredData.map((item: any, index) => {

                        return (
                          <div
                            key={index}
                            className="border !min-w-[700px] text-center text-[#012063]/[0.7] text-gray-600 bg-white py-1 rounded-xl mt-[5px]"
                          >
                            <div className="flex items-center w-full border-blue-500">
                              <div className="w-4/12">
                                <p className="text-center text-[0.85em]">
                                  {item.student.firstname} {item.student.surname}
                                </p>
                              </div>
                              <div className="w-2/12">
                                <p className="text-center text-[14px] text-[#012063CC] capitalize font-semibold">
                                  {item.emergency_contact.contact_name}
                                </p>
                              </div>
                              <div className="w-2/12 text-[0.9em] font-bold flex justify-center">
                                <div className="h-20 w-20 overflow-auto rounded-full">
                                  {item?.profile_picture}
                                </div>
                              </div>

                              <div className="w-2/12">
                                <p className="text-center text-[1.1em] min-w-max capitalize font-black">
                                  {item.picker_token}
                                </p>
                              </div>

                              <div className="w-/12 min-w-[100px] ml-1 overflow-hidden text-[14px] items-center flex justify-center text-[#fff]">
                                <button
                                  onClick={() => {
                                    setCurrentChild(item)
                                    authorize()
                                  }}
                                  className={`rounded px-4 py-1 bg-[#0096D1] text-gray-100`}
                                >
                                  Pick
                                </button>
                              </div>



                              {openModal ? (
                                <>
                                {callDialog()}
                                </>
                              ) : null}

                            </div>
                          </div>
                        );
                      })}
                    </>
                    :
                    (
                    <p className="text-[#012063CC] py-3 text-xl text-center">No data found...</p>)
                  }


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};
