import React from "react";
import FormOutlinedInput from "../../components/pendulumAdmin/FormOutlinedInput";
import AdminTopBar from "../../layouts/AdminTopBar";
import uploadLogoIcon from "../../assets/image/upload-logo-icon.svg";
import HeaderCard from "../../components/pendulumAdmin/headerCard";
import { IUserData } from "../../components/pendulumAdmin/userTableRow";
import { dummyUserData } from "./UserData";
import { useParams } from "react-router-dom";

const EditSchool = () => {
  const {id} = useParams()
  const school = dummyUserData.find((item)=>item.id === id)
  console.log(school)
  const [formData, setFormData] = React.useState<{ schoolName: string }>({
    schoolName: school?.schoolName as string || '',
  });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  return (
    <div>
      <AdminTopBar title="Enroll Student Form" name="John Doe" />
      <HeaderCard
        title="Student"
        details="Enter student data"
        activeDetails="Active Enrolled Students"
        activeNumber={30}
      />
      <div className="w-full border mt-4 bg-gradient-to-b from-white via-[#D0ECF6] to-white py-4 px-8">
        <div className="flex justify-center">
          <button className="h-[50px] w-[50px] rounded-full bg-[#012063] flex justify-center items-center text-white text-xl">
            1
          </button>
        </div>
        <form className="mt-9 grid grid-cols-12 gap-8">
          <div className="col-span-8">
            <FormOutlinedInput
              label="Name"
              name="schoolName"
              placeholder="School name"
              value={formData.schoolName}
              onChange={()=>handleChange}
              width={539}
              required={true}
            />
            <div className="flex items-center gap-2">
              <div className="max-w-[127px] w-full">
                <h4 className="font-medium text-sm text-right text-[#012063]/[0.7]">
                  email
                </h4>
              </div>
              <div className="gap-4 w-full grid grid-cols-2">
                <FormOutlinedInput
                  name="schoolName"
                  placeholder="Email address"
                  required={true}
                  width={360}
                />
                <FormOutlinedInput
                  name="email"
                  placeholder="Retype email address"
                  required={true}
                  width={360}
                />
              </div>
            </div>

            <FormOutlinedInput
              label="address"
              name="address"
              placeholder="address"
              required={true}
              width={539}
            />
            <FormOutlinedInput
              label="Phone number"
              name="phoneNumber"
              placeholder="+234"
              required={true}
              width={246}
            />
            <FormOutlinedInput
              label="No of Staffs"
              name="numberOfStaffs"
              placeholder="30"
              required={true}
              width={95}
            />
            <FormOutlinedInput
              label="No of students"
              name="numberOfStudents"
              placeholder="400"
              required={true}
              width={95}
            />
            <div className="flex items-center gap-2">
              <div className=" max-w-[127px] w-full">
                <h4 className="font-medium text-sm text-right text-[#012063]/[0.7]">
                  Select plan
                </h4>
              </div>
              <div className="bg-white px-2 py-2 flex gap-2 items-center rounded-[5px] border shadow-md">
                <div className="flex items-center gap-1">
                  <input type="checkbox" id="premium" className="" />
                  <label htmlFor="premium" className="text-[#1F1F1F]/[0.8]">
                    Premium
                  </label>
                </div>
                <div className="flex items-center gap-1">
                  <input type="checkbox" id="standard" className="" />
                  <label htmlFor="standard" className="text-[#1F1F1F]/[0.8]">
                    Standard
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="border shadow-sm rounded-lg max-w-[300px] max-h-[300px] w-full h-full flex justify-center items-center bg-white relative">
              <button className="flex justify-center items-center flex-col relative cursor-pointer">
                <img
                  src={uploadLogoIcon}
                  alt="logo"
                  className="cursor-pointer"
                />
                <input
                  type="file"
                  className="absolute top-9 left-[10%] scale-[3] w-[80px] cursor-pointer opacity-0"
                />
                <p className="mt-4 text-[#1F1F1F] text-sm cursor-pointer">
                  click here to upload
                </p>
              </button>
            </div>
          </div>
        </form>
        <div className="flex justify-center mt-6">
          <button className="bg-gradient-to-b from-[#0096D1] to-[#012063] h-16 max-w-[286px] w-full rounded text-white">
            Create School
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSchool;
