import { RegisterCard } from "../components/RegisterCard"
import { TopAdminNav } from "../layouts/TopAdminNav"
import '../assets/css/main.css'
import { Link } from "react-router-dom"


export const CreateQuiz = () => {
        return (
                
                <>
                        <div className='w-full px-4'>
                                <TopAdminNav name="Jane Doe" title="Create Quiz"  />
                        </div>

                        <div className='flex'>
                                <div className='px-5 block w-full h-[87vh] overflow-auto register-scroll'>
                                        <div className="flex">
                                                <div className="w-7/12">
                                                        <RegisterCard title="Upload your quiz" details="Quiz for student now"  />
                                                </div>
                                                <div className="w-5/12 h-full">
                                                        <div className="flex">
                                                                <div className="w-1/6"></div>
                                                                <div className="w-4/6 h-full">
                                                                        <div className="flex items-end justify-center h-full">
                                                                                <Link to="/admin/class/quiz/import">
                                                                                        <button className="py-4 px-12 m-5 mb-0 bg-[#0096D1] text-white rounded-xl">
                                                                                                Import Quiz
                                                                                        </button>
                                                                                </Link>
                                                                        </div>
                                                                        
                                                                </div>
                                                                <div className="w-1/6"></div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div className='text-[#012063] attendance_bg m-5 mt-1 p-5'>
                                                <div className="flex text-[#1f1f1f]/[0.7] mb-7 w-full text-center"> 
                                                        <div className="w-3/12 ">
                                                                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center" >
                                                                        <option>All Created</option>
                                                                </select>
                                                        </div>
                                                        <div className="w-3/12 ">
                                                                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center" >
                                                                        <option>2021/2022</option>
                                                                </select>
                                                        </div>
                                                        <div className="w-3/12 ">
                                                                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center" >
                                                                        <option>Class</option>
                                                                </select>
                                                        </div>
                                                        <div className="w-3/12 ">
                                                                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center" >
                                                                        <option>First Term</option>
                                                                </select>
                                                        </div>

                                                </div>
                                                <div className="w-full">
                                                        <div className="w-full text-center">
                                                                
                                                                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 mb-5 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2">
                                                                        <div className="flex w-full py-3">
                                                                                <div className="w-1/6 items-center flex justify-center">Subject</div>
                                                                                <div className="w-1/6 items-center flex justify-center rounded-[6px]">Number</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">Class</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">Date Created</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">Deadline Date</div>
                                                                                <div className="w-1/6 items-center flex justify-center">Teacher Name</div>

                                                                        </div>
                                                                </div>

                                                                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2 text-[15px]">
                                                                        <div className="flex w-full py-3">
                                                                                <div className="w-1/6 items-center flex justify-center">Mathematics</div>
                                                                                <div className="w-1/6 items-center flex justify-center rounded-[6px]">20</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">Basic 3</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">21-Nov-2022</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">22-Nov-2022</div>
                                                                                <div className="w-1/6 items-center flex justify-center">Mr Zoe Makun</div>

                                                                        </div>
                                                                </div>
                                                                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2 text-[15px]">
                                                                        <div className="flex w-full py-3">
                                                                                <div className="w-1/6 items-center flex justify-center">Mathematics</div>
                                                                                <div className="w-1/6 items-center flex justify-center rounded-[6px]">12</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">SS 2</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">21-Nov-2022</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">22-Nov-2022</div>
                                                                                <div className="w-1/6 items-center flex justify-center">Mr Zoe Makun</div>

                                                                        </div>
                                                                </div>
                                                                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2 text-[15px]">
                                                                        <div className="flex w-full py-3">
                                                                                <div className="w-1/6 items-center flex justify-center">Mathematics</div>
                                                                                <div className="w-1/6 items-center flex justify-center rounded-[6px]">15</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">Basic 1</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">21-Nov-2022</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">22-Nov-2022</div>
                                                                                <div className="w-1/6 items-center flex justify-center">Mr Zoe Makun</div>

                                                                        </div>
                                                                </div>
                                                                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2 text-[15px]">
                                                                        <div className="flex w-full py-3">
                                                                                <div className="w-1/6 items-center flex justify-center">Mathematics</div>
                                                                                <div className="w-1/6 items-center flex justify-center rounded-[6px]">10</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">JSS 2</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">21-Nov-2022</div>
                                                                                <div className="w-1/6 items-center flex justify-center text-[#1F1F1F]/[0.7]">22-Nov-2022</div>
                                                                                <div className="w-1/6 items-center flex justify-center">Mr Zoe Makun</div>

                                                                        </div>
                                                                </div>
                                                        
                                                        </div>

                                                </div>
                                        </div>

                                </div>
                        </div>
                </>
        )
}