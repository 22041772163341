/* eslint-disable import/no-anonymous-default-export */

import { useEffect, useState } from "react";
import { RegisterCard } from "../components/RegisterCard"
import { TopAdminNav } from "../layouts/TopAdminNav"
import EventList from "./EventList"
import { EventItem } from "./EventAndCalendar";
import { defaultUrl } from "../features/actions";
import { makeFetchRequest } from "../utilities/common";

export default ()=> {
    /** COMPONENT MOUNT STATE */
    const today = new Date();
    const [dateValue] = useState(today);
    const [showFormModal] = useState(false);
    const [selectedDay] = useState<Date>(dateValue);
    const [endDate] = useState<Date>(dateValue);
    const [schedules, setSchedules] = useState<EventItem[]>([]);

    /** DATA FETCHING */
    useEffect(()=>{
        fetchSchedules();
        // eslint-disable-next-line
    }, [showFormModal]);

    const fetchSchedules = async()=>{
          const fmtStartDate = `${selectedDay.getFullYear()}-${selectedDay.getMonth()+1 < 10? `0${selectedDay.getMonth()+1}` : selectedDay.getMonth()+1}`;
          const fmtEndDate = `${endDate.getFullYear()}-${endDate.getMonth()+1 < 10 && endDate.getMonth()+1 !== 9 ?  `0${endDate.getMonth() + 1}` : endDate.getMonth() + 2}`;

          const response = await makeFetchRequest(`${defaultUrl}/schedule?start_date=${fmtStartDate}&end_date=${fmtEndDate}`)
          setSchedules(response);
    };

    return (
        <div>
            <TopAdminNav
                title="Events"
            />
            <div>
                <div className="photoCardTop">
                    <div className="w-full">
                        <RegisterCard
                        title="Events, schedules and information"
                        details="All events scheduled by the school"
                        />
                    </div>
                </div>
                <div className="nt-4 flex h-full w-full justify-center items-center">

                    <div>
                        <EventList events={schedules} selectedDate={selectedDay} />
                    </div>
                </div>
            </div>
        </div>
    )
}
