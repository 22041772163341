import AdminTopBar from "../../layouts/AdminTopBar";
import dashboardCardBg from "../../assets/image/dashboard-card-bg.svg";
import { TfiReload } from "react-icons/tfi";
import { IRecentMessage } from "../../components/pendulumAdmin/recentMessageCard";
import RecentMessageCard from "../../components/pendulumAdmin/recentMessageCard";
import MessageTable from "../../components/pendulumAdmin/messageTable";

interface ITableData {
  school: string;
  subject: string;
  messageContent: string;
  time: string;
}

const recentMessageDummy: IRecentMessage[] = [
  {
    sender: "Mrs Ajayi",
    messageContent:
      "Lorem ipsum dolor sit amer. nnjskatc vulputate libero et velit interdum, sociosqu ad litora...",
    time: "2 hours",
    receiver: "Kenny A",
  },
  {
    sender: "Mrs Ajayi",
    messageContent:
      "Lorem ipsum dolor sit amer. nnjskatc vulputate libero et velit interdum, sociosqu ad litora...",
    time: "2 hours",
    receiver: "Kenny A",
  },
];

const tableDummyData: ITableData[] = [
  {
    school: "Montessori Model",
    subject: "Entrance Examination",
    messageContent: "Content of the message received",
    time: "05:30am",
  },
  {
    school: "Montessori Model",
    subject: "Entrance Examination",
    messageContent: "Content of the message received",
    time: "05:30am",
  },
  {
    school: "Montessori Model",
    subject: "Entrance Examination",
    messageContent: "Content of the message received",
    time: "05:30am",
  },
  {
    school: "Montessori Model",
    subject: "Entrance Examination",
    messageContent: "Content of the message received",
    time: "05:30am",
  },
];

const PendulumAdminDashboard = () => {
  return (
    <div>
      <AdminTopBar title="Welcome Home" name="John Doe" />
      <div className="grid grid-cols-1 xl:grid-cols-12 gap-4 mr-2">
        <div className="xl:col-span-9">
          <div className="flex flex-col md:flex-row items-center md:items-start gap-4">
            <div className="max-w-[319px] w-full h-[265px] relative rounded-[20px] overflow-hidden bg-[#F3FAFD] shadow-md p-4 pt-5">
              <div className="flex flex-col justify-between h-full">
                <p className="text-base lg:text-[22px] text-[#012063] font-bold max-w-[120px]">
                  Registered Schools
                </p>

                <div className="flex flex-col gap-2">
                  <div className="flex gap-3">
                    <span className="w-[35px] h-[35px] rounded-[5px] bg-white shadow-md text-[15px] font-semibold text-[#1f1f1fad] flex justify-center items-center">
                      40
                    </span>
                    <span className="text-lg text-[#324B82] font-medium">
                      Total Users
                    </span>
                  </div>
                  <div className="flex gap-3">
                    <span className="w-[35px] h-[35px] rounded-[5px] bg-white shadow-md text-[15px] font-semibold text-[#1f1f1fad] flex justify-center items-center">
                      40
                    </span>
                    <span className="text-lg text-[#324B82] font-medium">
                      Active Users
                    </span>
                  </div>
                </div>
              </div>

              <img
                src={dashboardCardBg}
                alt="card bg"
                className="absolute top-0 right-0"
              />
            </div>
            <div className="max-w-[319px] w-full h-[265px] relative rounded-[20px] overflow-hidden bg-[#F3FAFD] p-4 pt-5 shadow-md">
              <div className="flex flex-col justify-between h-full">
                <p className="text-base lg:text-[22px] text-[#012063] font-bold max-w-[120px]">
                  Activities
                </p>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-3">
                    <span className="w-[35px] h-[35px] rounded-[5px] bg-white shadow-md text-[15px] font-semibold text-[#1f1f1fad] flex justify-center items-center">
                      40
                    </span>
                    <span className="text-lg text-[#324B82] font-medium">
                      Messages
                    </span>
                  </div>
                  <div className="flex gap-3">
                    <span className="w-[35px] h-[35px] rounded-[5px] bg-white shadow-md text-[15px] font-semibold text-[#1f1f1fad] flex justify-center items-center">
                      40
                    </span>
                    <span className="text-lg text-[#324B82] font-medium">
                      Second One
                    </span>
                  </div>
                </div>
              </div>

              <img
                src={dashboardCardBg}
                alt="card bg"
                className="absolute top-0 right-0"
              />
            </div>
          </div>
          <div className="flex justify-between item-center mt-10 mb-4">
            <p className="text-base lg:text-[22px] text-[#012063] font-bold">
              Recent Messages
            </p>
            <div className="flex items-center gap-2">
              <span className="text-[#5F5454] font-medium text-[15px]">
                last refreshed: 07.05.04PM
              </span>
              <TfiReload className="font-bold cursor-pointer" />
              <button className="border-0 bg-[#012063] px-2 py-1 text-white font-medium text-xs lg:text-[15px]">
                View all
              </button>
            </div>
          </div>

          {/* Table */}
          <div className="overflow-x-scroll w-full">
            <MessageTable data={tableDummyData} />
          </div>
        </div>
        <div className="xl:col-span-3 w-full bg-gradient-to-b from-white via-[#D0ECF6]/[0.5] to-white p-3 border shadow-md">
          <div className="bg-white rounded-lg border py-3 px-2 shadow-md">
            <div className="rounded-lg border p-2 mb-4 text-[#012063] font-bold">
              Recent Messages
            </div>
            <div className="flex flex-col gap-3">
              {recentMessageDummy.map((item, index) => {
                return (
                  <RecentMessageCard
                    key={`${item.messageContent}-${index}`}
                    {...item}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendulumAdminDashboard;
