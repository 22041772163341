import { createSlice } from "@reduxjs/toolkit";
import { loginThunk, resetPasswordThunk } from "./actions";

interface loginSliceInterface {
  status: string;
  data: { token?: string, data?: any};
  errMessage: string | unknown;
}

const initialState: loginSliceInterface = {
  status: "",
  data: { },
  errMessage: "",
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    
  },
  extraReducers(builder) {
    builder
      // login
      .addCase(loginThunk.pending, (state, _) => {
        state.status = "pending";
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.data = action.payload;
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.status = "rejected";
        state.errMessage = action.payload;
      });
  },
});

export default loginSlice.reducer;
