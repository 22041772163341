import toast from "react-hot-toast";

export function formatTime(userDate?: any, noSecond?: boolean) {
  // get the current date object
  let date = new Date();
  if(userDate){
    date = userDate
  }
  // get the year, month, day, hour and minute
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // months are zero-based
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds()
  // pad with leading zeros if needed
  let fmtmonth = month < 10 ? "0" + month : month;
  let fmtday = day < 10 ? "0" + day : day;
  let fmthour = hour < 10 ? "0" + hour : hour;
  let fmtminute = minute < 10 ? "0" + minute : minute;
  let fmtsecond = second < 10 ? "0" + second : second;

  if(noSecond){
    return `${year}-${fmtmonth}-${fmtday} ${fmthour}:${fmtminute}`;
  }else{
  // return the formatted string
  return `${year}-${fmtmonth}-${fmtday} ${fmthour}:${fmtminute}:${fmtsecond}`;
  }

}

export const getHeader = () => {
  const token = sessionStorage.getItem("token")?.replace(/['"]+/g, "");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    headers: headers,
  };
};

export const parseDateString = (dateString : any) => {
  const [datePart, timePart] = dateString.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hours, minutes] = timePart.split(':').map(Number);

  // Create a new Date object from the provided string
  return new Date(year, month - 1, day, hours, minutes);
};



export const validatePhoneNumber = (phoneNumber: string) => {
  const phoneNumberRegex =
    /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907)([0-9]{7})$/;
  const isValid: boolean = phoneNumberRegex.test(phoneNumber);
  return isValid;
};


export function agoToDateString(agoString: string): string {
  // Regular expression to parse the input string
  const regex = /(\d+)\s*(seconds?|minutes?|hours?|days?|weeks?|months?|years?)\s*ago/i;
  const match = agoString.match(regex);

  if (!match) {
    throw new Error("Invalid input format");
  }

  const quantity = parseInt(match[1], 10);
  const unit = match[2].toLowerCase();
  const now = new Date();

  switch (unit) {
    case 'second':
    case 'seconds':
      now.setSeconds(now.getSeconds() - quantity);
      break;
    case 'minute':
    case 'minutes':
      now.setMinutes(now.getMinutes() - quantity);
      break;
    case 'hour':
    case 'hours':
      now.setHours(now.getHours() - quantity);
      break;
    case 'day':
    case 'days':
      now.setDate(now.getDate() - quantity);
      break;
    case 'week':
    case 'weeks':
      now.setDate(now.getDate() - quantity * 7);
      break;
    case 'month':
    case 'months':
      now.setMonth(now.getMonth() - quantity);
      break;
    case 'year':
    case 'years':
      now.setFullYear(now.getFullYear() - quantity);
      break;
    default:
      throw new Error("Invalid time unit");
  }

  return formatDateTime(now);
}

export function formatDateTime(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Pad single digits with leading zeros
  const monthStr = month < 10 ? `0${month}` : month.toString();
  const dayStr = day < 10 ? `0${day}` : day.toString();
  const hoursStr = hours < 10 ? `0${hours}` : hours.toString();
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes.toString();
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds.toString();

  return `${year}-${monthStr}-${dayStr} ${hoursStr}:${minutesStr}:${secondsStr}`;
}

export function dateToAge(dateOfBirth: string) {
  // Parse the input date
  const dob = new Date(dateOfBirth);
  
  // Format date of birth
  const formattedDOB = dob.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
  });
  
  // Calculate age
  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();
  
  // Adjust age if birthday hasn't occurred this year
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
  }
  
  return {
      formattedDate: formattedDOB,
      age: age >= 1 ? age : "Less than a year old",
      toString: function() {
          return `${this.formattedDate} (${this.age})`;
      }
  };
}


export const chunkArray = (array : any, size: number) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};


export const searchThroughNames = async (dataArray: any, searchTerm: string) => {
  const result = await dataArray && dataArray.filter((item: any) => {
    return (item?.firstname && item?.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (item?.surname && item?.surname.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (item?.othername && item?.othername.toLowerCase().includes(searchTerm.toLowerCase()))
})
  return result
}


const cloudName: string = "djztsnmej"
const uploadPreset: string = "njf1orrl"
export const uploadImageToCloudinary = async (file: any) => {

  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', uploadPreset);

const uploadFile = async (fileType : string) => {

  try {
    const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/${fileType}/upload`, {
      method: 'POST',
      body: formData
    });

    if (!response.ok) {
      throw new Error('Upload failed');
    }

    const data = await response.json();
    return data.secure_url;

  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}


  if(file?.type === "video/mp4"){
    if(file?.size <= 100000000){
      return uploadFile("video")
    }else{
      return toast.error(`File too large.`)
    }
  }else if(file?.type === "image/jpeg"
    || file?.type === "image/png"
    || file?.type === "image/jpg"
    || file?.type === "application/pdf"
  ){
    if(file?.size <= 10000000){
      return uploadFile("image")
    }else{
      return toast.error(`File too large.`)
    }
  }else{
    return toast.error(`${file.name} is an invalid file`)
  }



};
