const CustomModal = ({ title, children, hideModal, width }: { title:string, children:any, hideModal:any, width?:any}) => {
  return (
    <>
      <div className="flex justify-center items-center overflow-hidden relative
       inset-0 z-[50] outline-none focus:outline-none w-full h-screen">
        <div className={`fixed top-[42px] sm:top-4 left-[4%] lg:left-[30%] h-[50vh] w-[95%] lg:w-[50%]`}>
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full h-full min-h-max bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-3xl font-semibold">{title}</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={hideModal}
              >
                <span className="h-6 w-6 text-2xl block outline-none focus:outline-none text-red-500">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative flex-auto w-full pl-2.5 overflow-y-scroll
             h-[70vh] register-scroll lg:overflow-x-hidden ">{children}</div>
            {/*footer*/}
            <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={hideModal}
              >
                Close
              </button>
              {/* <button
								className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
								type="button"
								onClick={() => setShowModal(false)}
							>
								Save Changes
							</button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default CustomModal;
