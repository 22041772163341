import { StaffDashboardCard } from "../components/StaffDashboardCard"
import { ChartAttendance } from "../components/ChartAttendance"
import { AdminCalRec } from "../layouts/AdminCalRec"
import { TopAdminNav } from "../layouts/TopAdminNav"
import { useState,  useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import "../assets/css/dashboard.css";
import icon1 from "../assets/icon1.svg";
import icon2 from "../assets/icon2.svg";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import useWindowDimensions from "../hooks/useWindowSize";
import DashboardPhotoCard from "../components/DashboardPhotoCard";
import { defaultUrl } from "../features/actions";
import { useSelector } from "react-redux";
import { getMediaThunk } from "../features/mediaSlice";
import { useAppDispatch } from "../app/hooks";
import { makeFetchRequest } from "../utilities/common";


///////////////////////////////////////////////////////
// fetch API data and store result data in state
let token = "";
const stringifiedToken = sessionStorage.getItem('token');
if (typeof stringifiedToken === 'string') {
  token = stringifiedToken;
  // console.log(token);
}
export const globalToken = token;

interface Uplis {
  count: number,
  text: String,
  border?: boolean,
  redColor?: boolean,
  neutralColor?: boolean,
  blueColor?: boolean,
  orangeColor?: boolean,
  themeColor?: boolean,
}


const UpLis = ({count, text, border, redColor, neutralColor,
  blueColor, orangeColor, themeColor}:Uplis) => {
  return (
    <div className={`block m-1 w-full ${border && "shadow"}`}>
      <div className={`flex text-[13px] tracking-wide`}>
        <div className={`flex items-center justify-center bg-white
           text-[#012063] p-2 rounded-sm font-medium block w-[38px]
            text-center mb-1 ${neutralColor && "bg-gray-500 !text-white"}
             ${redColor && 'bg-[#d3331d] !text-white'}
              ${blueColor && '!bg-[#36a2eb] !text-white'}
              ${orangeColor && '!bg-[#ff6384] !text-white'}
               ${themeColor && '!bg-[#36a2eb] !text-white'}`}>{count}</div>
        <span className={`flex items-center px-2 py-0 text-[#012063]
           font-medium ${neutralColor && ('text-gray-800' || '!text-[#D7320D]')}
            ${redColor && ('text-gray-800' || '!text-[#D7320D]')}
             ${blueColor && ('text-gray-800' || '!text-[#36a2eb]')}
             ${orangeColor && ('text-gray-800' || '!text-[#ff6384]')}
             ${themeColor && ('text-gray-800' || '!text-grey-800')}`} > {text}</span>
      </div>
    </div>
  )
}




/** COMPONENT STARTS HERE **/

export const Home = () => {
  const location = useLocation();
  const token = location.state?.token;


  /** COMPONENT MOUNT */
  const user = JSON.parse(sessionStorage.getItem('user') as string);
  const { images:photos, videos } = useSelector((state:any):any=> {
    return state.photos
  }) || [];
  const {width: windowWidth} = useWindowDimensions();
  // const [showGrowthMonitor, setShowGrowthMonitor] =useState(false);
  const [summaryData, setSummaryData] = useState({
    absent: 0,
    birthdays: 0,
    children: 0,
    classrooms: 0,
    loggedin: 0,
    loggedout: 0,
    messages: 0,
    "non-teachers": 0,
    parent: 0,
    photosandvideos: 0,
    teachers: 0
  })
  const [classrooms, setClassrooms] = useState([]);

  let dispatch = useAppDispatch()
  useEffect(()=>{
    fetchDashboardData();
    dispatch(getMediaThunk());
  },[]);

  /** DATA FETCHING */
  const fetchDashboardData= async()=> {
    try{
      const data = await makeFetchRequest(`${defaultUrl}/user/dashboard`, false, true, token)
      setSummaryData(data);
    } catch(e:any){
      // console.log({err: e.response.data || e})
    }
  }

  const isMobileScreen = windowWidth < 440;


  return (
    <section className={`${isMobileScreen? "max-w-[98vw] h-[98vh]" : "w-[100%] xl:h-[90vh]"}`}>
      <div className='w-full h-max max-h-[7vh]'>
        <TopAdminNav name="Jone Doe" title={"Welcome " + user.name?.split(" ")[0]} />
      </div>

      <div className='h-full max-h-[90vh] w-[90%] mx-auto flex scrollbar-hide overflow-y-auto overflow-x-hide'>
        <div className='overflow-y-auto scrollbar-hide overflow-x-hide block *w-[700px]* md:w-full 2xl:w-8/12'>
          <div className='flex md:flex-row flex-wrap lg:flex-wrap-none justify-evenly'>
            <StaffDashboardCard first="Attendance" second="Report" icon={<img src={icon1} alt="" />} link_to="attendance">
              <div className="h-full w-full">
                <div className="flex mt-4 justify-between items-center w-full">
                  <div className="-mt-4"><ChartAttendance /></div>
                  <div className="flex flex-col px-1 w-full ml-4 mt-4 md:ml-0">
                    <UpLis count={summaryData.loggedin} text="Logged In" blueColor />
                    <UpLis count={summaryData.loggedout} text="Logged out" orangeColor />
                    <UpLis count={summaryData.absent} text="Absent" orangeColor />
                  </div>
                </div>
                {/* <div className="flex justify-between items-center p-2">
                  <Link to={`/admin/attendance`}>
                    <button className="text-[13px] py-1 px-3 min-w-[140px] rounded
                     bg-[#012063] opacity-80 text-slate-100">Mark Attendance</button>
                  </Link>
                </div> */}
              </div>
            </StaffDashboardCard>
            <StaffDashboardCard first="Activities" icon={<img src={icon2} alt="" />} link_to="/admin/attendance">
              <div className="flex w-full mt-11 flex-col">
                <UpLis border count={summaryData.messages} text="Messages"  themeColor/>
                <UpLis border count={summaryData.birthdays} text="Birthdays" themeColor/>
                <UpLis border count={summaryData.photosandvideos} text="Photos & Videos" themeColor/>
              </div>
            </StaffDashboardCard>
          </div>
          <div className="flex flex-col md:flex-row md:flex-wrap lg:flex-wrap-none justify-evenly">
            <div className='mt-8 pt-8 flex-1 border-t border-[#0096D133] '>
              <p className="underline text-xs text-[#012063]">Recently added</p>
              <div className="flex-1 flex flex-col md:flex-row md:flex-wrap justify-evenly items-center py-4 max-h-[400px] overflow-auto register-scroll">
              {photos && photos?.map((item:any, index:number) => (
                <div key={index} className="my-2"><DashboardPhotoCard item={item} /></div>
              ))}
              </div>
          </div>
          </div>
        </div>
        <div className="hidden relative 2xl:block md:w-4/12 bg-[#F3FAFD80] px-4 py-1 w-full">
          <div className="md:absolute">
            <AdminCalRec />
          </div>
        </div>
      </div>


    </section>
  )
}
