interface registerCardInterface {
  title: string;
  details: string;
  activeDetails?: string;
  activeNumber?: number;
}

const HeaderCard = ({
  title,
  details,
  activeDetails,
  activeNumber,
}: registerCardInterface) => {
  return (
    <div className="w-full registerCard rounded-[14px] text-white font-bold px-5 py-2">
      <div className="flex justify-between ">
        <h2 className="capitalize text-[14px] font-normal mb-2">{title}</h2>
        <h2 className="text-white/[0.8] capitalize ">
          {activeDetails}{activeDetails && ":"}
          <span className="text-white/[1]">{activeNumber}</span>
        </h2>
      </div>
      <p className="text-xs font-thin">{details}</p>
    </div>
  );
};

export default HeaderCard;
