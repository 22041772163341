import { useEffect, useState } from "react";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { RegisterCard } from "../components/RegisterCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { defaultUrl } from "../features/actions";
import { makeFetchRequest, makePutRequest } from "../utilities/common";
import { useDispatch, useSelector } from "react-redux";
import { selectRe_RenderedState, setSharedState } from "../features/sharedStateSlices/shareState";
import { chunkArray, searchThroughNames } from "../utilities/importantFns";
import { PagenateWithoutBackEnd } from "../components/pagination";
import StudentsArchive from "../layouts/archive/studentsArchive";
import StaffArchive from "../layouts/archive/StaffArchive";


const Archive = () => {
  const dispatch = useDispatch()
  const reRender = useSelector(state => selectRe_RenderedState(state))

  const [searchTerm, setSearchTerm] = useState("");
  const [state, setState] = useState(1)
  const [archivedChildren, setArchivedChildren] = useState([])
  const [archivedTeachers, setArchivedTeachers] = useState([])

  const [page, setPage] = useState(1)  //Pages for Students
  const [pageT, setPageT] = useState(1) //Pages for Teachers
  const itemsPerPage = 10;

  const archivedChildrenPerPage = chunkArray(archivedChildren, itemsPerPage)
  const archivedTeachersPerPage = chunkArray(archivedTeachers, itemsPerPage)

  const pageIndex = page - 1
  const pageIndexTeachers = pageT - 1


  const categorize = (e: any) => {
    if (e.target.value === "Students") {
      setState(1)
    } else if (e.target.value === "Staff") {
      setState(2)
    }
  }

  const fetchArchivedChildren = async () => {
    // GET CLASSROOMS
    const classrooms = await makeFetchRequest(`${defaultUrl}/classroom`, false, true)
    // GET STUDENTS IN ARCHIVE
    const childrenInArchive = await makeFetchRequest(`${defaultUrl}/students/archives`)

    // FILTER DATA BY SEARCH TERM
    const filteredSearchStudents = await searchThroughNames(childrenInArchive, searchTerm)

    const get_ClassName_Into_ChildrenData: any = []
    filteredSearchStudents && filteredSearchStudents.map((item: any) => {
      classrooms && classrooms.map(({ id, name }: any, idx: number) => {
        // CHECK IF THE CURRENT filteredSearchStudents CLASSROOM_ID IS EQUAL
        //  TO THE ID OF THE CURRENT CLASSROOM ID
        if (item.classroom_id === id) {
          // IF IT IS CREATE A NEW KEY IN THE CURRENT filteredSearchStudents OBJECT CALLED CLASSNAME
          // AND MAKE IT EQUAL TO THE CURRENT CLASSROOM NAME
          item.classname = name
          // PUSH EACH ITEM INTO A LIST/ARRAY
          get_ClassName_Into_ChildrenData.push(item)
        }
      })
    })
    const sortByName = get_ClassName_Into_ChildrenData.sort(
      (a: any, b: any) => a.firstname.localeCompare(b.firstname)
    )
    //CHANGE THE state OF archivedChildren
    setArchivedChildren(sortByName)
  }

  const fetchArchivedTeachers = async () => {
    // GET TEACHERS IN ARCHIVE
    const teachers = await makeFetchRequest(`${defaultUrl}/teachers/archives`)

    // FILTER THEM BY SEARCH TERM
    const filteredSearchStaff = await searchThroughNames(teachers, searchTerm)

    // ARRANGE THE DATA ALPHABETICALLY BY FIRST NAME
    const sortByName = filteredSearchStaff.sort(
      (a: any, b: any) => a.firstname.localeCompare(b.firstname)
    )
    setArchivedTeachers(sortByName)
  }

  const handleArchive = (id: string) => {
    makePutRequest(`${defaultUrl}/unArchiveStudent/${id}`)
    dispatch(
      setSharedState() //This rerenders the page
    )
  }


  const unArchiveTeacher = (id: string) => {
    makePutRequest(`${defaultUrl}/unArchiveTeacher/${id}`)
    dispatch(
      setSharedState() //This rerenders the page
    )
  }

  const changePage = (index: number) => {
    if (index === 0) {
      setPage(1)
    } else {
      setPage(index + 1)
    }
  }

  const changeStaffPage = (index: number) => {
    if (index === 0) {
      setPageT(1)
    } else {
      setPageT(index + 1)
    }
  }





  // STUDENTS CONTAINER
  const children = (<div className="text-[#012063CC] mt-1 w-full overflow-x-auto register-scroll">
    <h3 className="text-center text-lg font-extrabold">Pupils</h3>
    <StudentsArchive
      archived_ChildrenPerPage={archivedChildrenPerPage}
      page_Index={pageIndex}
      handle_Archive={handleArchive}
    />
    {/* PAGENATION */}
    <PagenateWithoutBackEnd
      array={archivedChildrenPerPage}
      page_change={changePage}
      pageNum={page}
      itemperPage={itemsPerPage}
      totalItems={archivedChildren && archivedChildren.length}
    />
  </div>)


  // STAFF CONTAINER
  const teachers = (<div className="text-[#012063CC] mt-1 w-full overflow-x-auto register-scroll">
    <h3 className="text-center text-lg font-extrabold">Staff</h3>
    <StaffArchive
      unArchive_Teacher={unArchiveTeacher}
      archived_TeachersPerPage={archivedTeachersPerPage}
      pageIndex_Teachers={pageIndexTeachers}
    />
    {/* PAGENATION */}
    <PagenateWithoutBackEnd
      array={archivedTeachersPerPage}
      page_change={changeStaffPage}
      pageNum={pageT}
      itemperPage={itemsPerPage}
      totalItems={archivedTeachers && archivedTeachers.length}
    />
  </div>)



  useEffect(() => {
    fetchArchivedChildren()
    fetchArchivedTeachers()


    // eslint-disable-next-line
  }, [state, reRender, page, searchTerm])


  // CHECK PAGE CURRENT state
  let renderedPage: any;
  if (state === 1) {
    renderedPage = children
  } else {
    renderedPage = teachers
  }

  // PAGE TO RENDER
  return (
    <div>
      <div className="w-full">
        <TopAdminNav title="Archive" />
      </div>
      <div className="flex overflow-y-auto register-scroll">
        <div className="block w-full h-[90vh] overflow-auto register-scroll">
          <div className="flex">
            <div className="w-full flex items-center md:pr-6">
              <div className="w-full">
                <RegisterCard
                  title="Contacts and Leads management"
                  details="View Pupil's Parents/Guardians Information"
                />
              </div>
            </div>
          </div>
          <div className="h-[80vh] rounded-lg border-2 border-gray-100 px-4 !pb-0 !pt-[0.05px] attendance_bg mt-2 mr-2 overflow-y-scroll register-scroll">
            <div className="flex flex-wrap justify-start mr-6 md:justify-between text-[#1f1f1f]/[0.7] md:mt-6 mb-3 w-[98%] ">
              <div className="flex justify-between items-center w-full mt-4 md:mt-0 pl-2">
                <select onChange={categorize} className="px-2 py-2 rounded-2xl border-2 border-gray-200">
                  <option value="Students">Pupils</option>
                  <option value="Staff">Staff</option>
                </select>
                <div className="flex">
                  <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 w-10/12 min-w-[200px] ">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                      id="searchAttendanceInput"
                      onChange={e => setSearchTerm(e.target.value)}
                      placeholder="Search name"
                      className="rounded-2xl text-[13px] pl-4 p-2 w-full focus:outline-none "
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            {renderedPage}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Archive;
