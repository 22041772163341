import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loginSlice from '../features/loginSlice';
import registerSlice from '../features/register/registerSlice';
import studentSlice from '../features/student/studentSlice';
import parentSlice from '../features/parent/parentSlice';
import messageSlice from '../features/message/messageSlice';
import mediaSlice from '../features/mediaSlice';
import shared_useEffect from '../features/sharedStateSlices/shareState';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    register: registerSlice,
    login: loginSlice,
    student: studentSlice,
    parent: parentSlice,
    message: messageSlice,
    photos: mediaSlice,
    shared_useEffect,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
