import { useState } from "react";
import { AiFillFilePdf, AiFillVideoCamera, AiTwotoneCamera } from "react-icons/ai";


export const MediaUploadInput = ({handleUpload, onChangeFn, onClick, className, videoNull, pdfNull}:any) => {

    const [feedBack, setFeedBack] = useState("")
    const [fileNames, setFileNames] = useState(["", "", ""]);

    const txt1 = `Image${!pdfNull ? ", and PDF" : ""} file size should not exceed 9MB`

    return (
        <div className={className || "w-2/3 pl-6 flex flex-col"}>
            <div className="flex items-center w-full">
                <label className="picCap mr-4">
                    <AiTwotoneCamera className="picIcon" />
                    <p>Photo</p>
                    <input type="file"
                        onChange={(e : any)=> {
                            setFeedBack("")

                            if(e.target.files[0]?.size >= 10000000){
                                setFeedBack("You can only upload an image file of less than 9MB");
                                return
                            }else{
                                setFileNames((prev)=> {
                                    prev[0] = (e.target.files)? (e.target.files[0]?.name) : "";
                                    return prev;
                                });
                                onChangeFn(e, "image")
                            }

                        }}
                        className="hidden"
                        name="photoUpload"
                        accept=".jpg, .jpeg, .png"
                    />
                </label>
               {!videoNull &&
               (<label className="picCap mr-4">
                    <AiFillVideoCamera className="picIcon" />
                    <p>Video</p>
                    <input type="file"
                        onChange={(e : any)=> {
                            setFeedBack("")
                            if(e.target.files[0]?.size >= 100000000){
                                setFeedBack("You can only upload a video file of less than 95MB");
                                return
                            }else{
                                setFileNames((prev)=> {
                                    prev[1] = (e.target.files)? (e.target.files[0]?.name) : "";
                                    return prev;
                                });
                                onChangeFn(e, "video")
                            }

                        }}
                        className="hidden"
                        name="videoUpload"
                        accept=".mp4"
                    />
                </label>)
                }

                {!pdfNull &&
                (<label className="picCap">
                    <AiFillFilePdf className="picIcon" />
                    <p>Doc</p>
                    <input type="file"
                        onChange={(e: any)=> {
                            setFeedBack("")
                            if (e.target.files[0] && e.target.files[0]?.type !== 'application/pdf') {
                                setFeedBack("You can only upload a PDF file");
                                return
                            }else if(e.target.files[0]?.size >= 10000000){
                                setFeedBack("You can only upload a PDF file of less than 9MB");
                                return
                            }else{
                                setFileNames((prev)=> {
                                    prev[2] = (e.target.files)? (e.target.files[0]?.name) : "";
                                    return prev;
                                });
                                onChangeFn(e, "doc")
                            }

                        }}
                        className="hidden"
                        name="doc"
                        accept=".pdf"
                    />
                </label>)
                }
                {fileNames[0].length>0 || fileNames[1].length>0 && <button onClick={()=>{ setFileNames(["", ""]); onChangeFn(null, "image"); onChangeFn(null, "video")}} className="ml-2 underline text-sm text-red-500">clear</button>}
            </div>

            {fileNames && <div className="text-[10px] w-full mt-1">
            {fileNames.map((fileName,idx)=> <p key={idx}>{fileName}</p>)}
            </div>}

            {feedBack && <p className="w-full my-3 text-red-800 sm:text-xs ">{feedBack}</p>}

            <p className="w-full my-5 text-red-800 sm:text-xs">
                <b>⚠️</b> {txt1}
                <br />
                ⚠️Video file size should not exceed 95MB
                </p>


        </div>
    )
}
