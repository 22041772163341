import { RegisterCard } from "../../components/RegisterCard";
import useWindowDimensions from "../../hooks/useWindowSize";
import { CircleLoader } from "../../components/CircleLoader";


export const CAhealth = ({ formData, onFormChange, onFormSubmit,loading }: any) => {
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 440;
  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="Health"
          details="Activity will be sent to parent/ guardians"
        />
        <br />
        <form onSubmit={onFormSubmit}>
          {isMobileScreen ? (
            <div
              className={`overflow-x-hidden flex items-center justify-between h-[90vh] py-4 overflow-y-scroll px-2.5 w-full`}
            >
              <div className="!p-0 max-w-full">
                <div className={`w-full flex flex-col items-start px-1 mt-6`}>
                  <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left w-1/4">
                    Health
                  </h4>
                  <div className="w-full">
                    <div className="flex items-center justify-evenly rounded border bg-[#f5f5f5] p-2">
                      <label className="flex items-center mr-4 text-[14px]">
                        <input
                          type="checkbox"
                          name="health"
                          checked={formData["health"] === "good"}
                          onChange={(e) => onFormChange("health", "good")}
                          className="form-input checkbox mr-2"
                        />
                        Good
                      </label>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input
                          type="checkbox"
                          name="health"
                          checked={formData["health"] === "sick"}
                          onChange={(e) => onFormChange("health", "sick")}
                          className="form-input checkbox mr-2"
                        />
                        Sick
                      </label>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input
                          type="checkbox"
                          name="health"
                          checked={formData["health"] === "injured"}
                          onChange={(e) => onFormChange("health", "injured")}
                          className="form-input checkbox mr-2"
                        />
                        Injured
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col items-start px-1 mt-6">
                  <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 textleft  w-1/4">
                    Observation
                  </h4>
                  <div className="w-full">
                    <select
                      required
                      value={formData.observation}
                      onChange={(e) =>
                        onFormChange("observation", e.target.value)
                      }
                      className="p-2 text-[14px] w-80 rounded-lg border focus:outline-none"
                    >
                      <option value="">Choose Observation</option>
                      <option value="">Normal Temperature</option>
                        <option value="">High Temperature</option>
                      <option value="">Pale</option>
                      <option value="">Running Nose</option>
                      <option value="">Fatigue </option>
                      <option value="">Appetite Loss</option>
                    </select>
                  </div>
                </div>
                <div className="w-full flex flex-col items-start px-1 mt-6">
                  <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 textleft  w-1/4">
                    Description
                  </h4>
                  <div className="w-full">
                    <textarea
                      defaultValue={formData["description"]}
                      onChange={(e) =>
                        onFormChange("description", e.target.value)
                      }
                      className="w-full border-2 p-2 focus:outline focus:outline-[#012063]"
                    ></textarea>
                  </div>
                </div>

                <div className="mt-10 flex h-12 w-full">
                  <div className="w-full">
                    <button
                      type="submit"
                      className="flex items-center justify-center text-white w-full h-11 font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]"
                    >
                          {loading ? <CircleLoader /> : "Add"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile reMobile">
                <img
                  src="/img/healthcare.svg"
                  alt="login"
                  className="w-40 h-40"
                />
              </div>
            </div>
          ) : (
            <div
              className={`overflow-auto flex flew-wrap items-center justify-between h-[90vh] py-4 overflow-y-scroll w-full`}
            >
              <div className="!p-0 md:w-2/3">
                <div className={`w-full flex items-center px-1`}>
                  <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/4">
                    Health
                  </h4>
                  <div className="w-3/4 pl-6">
                    <div className="flex items-center justify-evenly rounded border bg-[#f5f5f5] p-2">
                      <label className="flex items-center mr-4 text-[14px]">
                        <input
                          type="checkbox"
                          name="health"
                          checked={formData["health"] === "good"}
                          onChange={(e) => onFormChange("health", "good")}
                          className="form-input checkbox mr-2"
                        />
                        Good
                      </label>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input
                          type="checkbox"
                          name="health"
                          checked={formData["health"] === "sick"}
                          onChange={(e) => onFormChange("health", "sick")}
                          className="form-input checkbox mr-2"
                        />
                        Sick
                      </label>
                      <label className="flex items-center mr-4 text-[14px]">
                        <input
                          type="checkbox"
                          name="health"
                          checked={formData["health"] === "injured"}
                          onChange={(e) => onFormChange("health", "injured")}
                          className="form-input checkbox mr-2"
                        />
                        Injured
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center px-1 mt-6">
                  <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/4">
                    Observation
                  </h4>
                  <div className="w-3/4 pl-6">
                    <select
                      required
                      onChange={(e) =>
                        onFormChange("observation", e.target.value)
                      }
                      className="p-2 w-80 text-[14px] rounded-lg border focus:outline-none"
                    >
                      <option value="">Choose observation</option>
                      <option value="">Normal Temperature</option>
                      <option value="">High Appetite</option>
                      <option value="">Haemorrage</option>
                    </select>
                  </div>
                </div>
                <div className="w-full flex items-center px-1 mt-6">
                  <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-right w-1/4">
                    Description
                  </h4>
                  <div className="w-3/4 pl-6">
                    <textarea

                      defaultValue={formData["description"]}
                      onChange={(e) =>
                        onFormChange("description", e.target.value)
                      }
                      className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"
                    ></textarea>
                  </div>
                </div>

                <div className="mt-10 flex h-12 w-full">
                  <div className="w-1/3"></div>
                  <div className="w-3/4">
                    <button
                      type="submit"
                      className="flex items-center justify-center text-white w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile">
                <img
                  src="/img/healthcare.svg"
                  alt="login"
                  className="w-40 h-40"
                />
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
