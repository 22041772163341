import {useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FaPlus, FaTimes } from "react-icons/fa";
import ActivityCard from "../../components/ActivityCard";
import axios from "axios";
import { globalToken } from "../../pages/AdminHome";

export const AddPickupForm =({form, deleteEmergencyContact, pickups, addPickup, numberOfPickups, handlePickupSubmit, handlePickupChange}:any)=> {

const [isClicked, setIsClicked] = useState<boolean>(false)

  function removeFormPickup(index:number){
    addPickup((prev:any)=> {
      return prev.filter((item:any, indx:number)=> index !== indx)
    })
  };




  // console.log({pickups})

  return (
        <div className="regStudentForm">
        <form onSubmit={(e : any) => {
          e.preventDefault();
          handlePickupSubmit()
          setIsClicked(true)
        }
          } action="">
          {numberOfPickups > 0 && (
            <div className="flex justify-evenly flex-wrap mb-8">
              {pickups?.map((item:any, index:number)=> (
                <div key={index} >
                  <div onClick={()=> deleteEmergencyContact(item.id)}
                  className="flex rounded-full border p-2 w-max bg-red-500
                   relative -bottom-8 -right-32"><FaTimes className="cursor-pointer" size={14} color="white"/>
                   </div>
                   <div className="flex ">
                  <img src={item.photo} className="h-36 w-36 m-2" alt={item.name} />
                  <div className="mx-3 py-2">
                  <p className=" font-bold">{item.contact_name}</p>
                  <small>{item.relationship}</small>
                  <p className=" font-bold">{item.phone}</p>
                  </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {numberOfPickups< 2 && <h2>Emergency Contact</h2>}
          <div className="">
            {numberOfPickups < 2?
            form?.map((contact: any, index: number) => {
              return (
                <div key={index}>
                {index > 0 && (
                  <div
                    onClick={() => removeFormPickup(index)}
                    className="cancel h-8 w-8 ml-7"
                    style={{ marginBottom: 10 }}
                  >
                    <FaTimes className="canIcon" />
                  </div>
                )}
                <div className="studentFormContent flex-col lg:flex-row">

                  <h4 className="flex justify-start lg:justify-end">
                    <></>Contact {index + numberOfPickups + 1}
                  </h4>
                  <div className="nameForm flex-col items-start">
                    <input
                      type="text"
                      placeholder="Name"
                      name="contact_name"
                      value={contact.contact_name}
                      onChange={(e) => handlePickupChange(index, e)}
                    />
                    {/* required */}
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      name="phone"
                      value={contact.phone}
                      onChange={(e) => handlePickupChange(index, e)}
                    />
                    {/* required */}
                    <select
                      name={`relationship`}
                      value={contact.relationship}
                      onChange={(e) => handlePickupChange(index, e)}
                    >
                      <option value="">Relationship</option>
                      <option>Father</option>
                      <option>Mother</option>
                      <option>Sister</option>
                      <option>Brother</option>
                      <option>Uncle</option>
                      <option>Aunty</option>
                      <option>Nanny</option>
                      <option>Others</option>
                    </select>
                  </div>
                  <div className="imageOut h-44 w-44 !p-0 mx-auto">
                    <h2>Profile Picture</h2>
                    <label className="imageInput flex flex-col h-32 w-32 !mt-0">
                      <input
                        type="file"
                        name="photo"
                        className="hidden"
                        onChange={(e) => handlePickupChange(index, e)}
                      />
                      {!contact.photo && (
                        <div className="imageOutline">
                          <AiOutlinePlus className="imageIcon" />
                        </div>
                      )}
                      {contact.photo && (
                        <div className="w-full h-full flex justify-center items-center">
                          <img
                            src={URL.createObjectURL(contact.photo)}
                            alt="preview"
                          />
                        </div>
                      )}
                    </label>
                  </div>
                </div>
                </div>
              );
            })
            :
            <div>
              <p className="w-full text-center text-xl text-green-800 font-black">Emergency Contact limit reached!</p>
              <p className="w-full text-center ">This Pupil already has <strong>{numberOfPickups}</strong> contacts. </p>
            </div>}

            {(form?.length + numberOfPickups) < 2 && numberOfPickups < 2 &&
            (
              <div className="flex w-full justify-center">

                <button
                  type="button"
                  className="flex items-center rounded bg-green-600 text-white px-5 py-2"
                  onClick={() => {
                    addPickup((previousData: any) => [
                      ...previousData,
                      {
                        name: "",
                        number: "",
                        relationship: "",
                        photo: "",
                      },
                    ]);
                  }}
                >
                  <FaPlus className="canIcon mr-2" size={15} color="white" />
                  Add New
                </button>
              </div>
            )}
          </div>
          {numberOfPickups < 2 &&
          <button
            type="submit"
            disabled={isClicked}
            className={`studentRegBtn ${isClicked && 'opacity-50 cursor-not-allowed'}`}
          >
            {"Save Changes"}
          </button>}
        </form>
      </div>
    )
}
