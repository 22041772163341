import axios from "axios";
import { defaultUrl } from "../../features/actions";
import { globalToken } from "../../pages/AdminHome";
import { parentFromAPI } from "../../types/interfaces";
// import { useDispatch } from "react-redux";
// import { setSharedState } from "../../features/sharedStateSlices/shareState";

export const EmbeddedParentForm =  ({numberOfParents=0, form,  studentEdittingSaved, handleParentChange}:any)=> {

  let newFormWithParent_idField : Array<object> = []

    // Convert the form id field to parent_id
    form && form.map( (item : parentFromAPI) => {
        const newParentObject = {
            "parent_id":  item?.id ,
            "name":  item?.name,
            "email": item?.email,
            "phone":item?.phone ,
            "home_number":  item?.home_number,
            "work_number":  item?.work_number,
            "relationship":  item?.relationship
        }

        return newFormWithParent_idField.push(newParentObject)
    })


    const submitParentNewData = async () => {
        const headers = {
            'Accept': 'application/json',
            "Authorization": `Bearer ${globalToken}`,
          };

        try {
            const data =  await axios.put(`${defaultUrl}/parents`, {
              data: newFormWithParent_idField
            }, {
              headers: headers
            });
            if(data.status !== 200){
                console.error('Failed to update parent');
            }

          } catch (error) {
            console.error('Failed to update parent:', error);
          }
    }

// Check if User clicked "Save Changes" from ./student.tsx
 if(studentEdittingSaved){
    submitParentNewData()


 }

    return (
        <div className="regStudentForm">
        {/* <form> */}
          <div>
            {form.map((parent: parentFromAPI, idx:number) => (
              <div key={idx}>

                <h2>Parent / Guardian {(numberOfParents - (numberOfParents - idx)) + 1}</h2>
                <div className="studentFormContent">
                  <h4>Name</h4>
                  <div className="nameForm">
                    <input
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      value={parent?.name}
                      onChange={(event)=> {
                        handleParentChange(idx, event);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="studentFormContent">
                  <h4>Email</h4>
                  <div className="nameForm">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={parent?.email}
                      onChange={(event)=> {
                        handleParentChange(idx, event);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="studentFormContent">
                  <h4>Mobile Number</h4>
                  <div className="nameForm">
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      name="phone"
                      value={parent?.phone}
                      onChange={(event)=> {
                        handleParentChange(idx, event);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="studentFormContent">
                  <h4>Other Phones</h4>

                  <div className="phoneOption">
                    <input
                      type="tel"
                      placeholder="Home"
                      name="home_number"
                      value={parent?.home_number || ""}
                      onChange={(event)=> handleParentChange(idx, event)}
                    />
                    <input
                      type="tel"
                      placeholder="Home"
                      name="work_number"
                      value={parent?.work_number || ""}
                      onChange={(event)=> handleParentChange(idx, event)}
                    />
                  </div>
                </div>
                <div className="studentFormContent">
                  <h4>Relation</h4>
                  <div className="genderForm">
                    <span>
                      <input
                        type="radio"
                        name={`relationship_${idx}`}
                        value="Parent"
                        // checked={parent.relationship === "Parent"}
                        onChange={(event) => handleParentChange(idx, { target: { name: "relationship", value: "Parent" } })}
                      />
                      Parent
                    </span>
                    <span>
                      <input
                        type="radio"
                        name={`relationship_${idx}`}
                        value="Guardian"
                        // checked={parent?.relationship === "Guardian"}
                        onChange={(event) => handleParentChange(idx, { target: { name: "relationship", value: "Guardian" } })}
                      />
                      Guardian
                    </span>
                  </div>
                </div>
              </div>
            ))}

          </div>

        {/* </form> */}
      </div>
    )
}
