import { AdminDashboardCard } from "../components/AdminDashboardCard";
import { ChartAttendance } from "../components/ChartAttendance";
import { AdminCalRec } from "../layouts/AdminCalRec";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import "../assets/css/dashboard.css";
import icon1 from "../assets/icon1.svg";
import icon2 from "../assets/icon2.svg";
import icon3 from "../assets/overview.svg";
import { useLocation } from 'react-router-dom';
import CustomModal from "../components/CustomModal/CustomModal";
import { GrowthMonitor } from "./GrowthMonitor";
import useWindowDimensions from "../hooks/useWindowSize";
import { defaultUrl, fetchConversationsThunk } from "../features/actions";
import DashboardPhotoCard from "../components/DashboardPhotoCard";
import { useSelector } from "react-redux";
import { getMediaThunk } from "../features/mediaSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { makeFetchRequest } from "../utilities/common";
import { Uplis } from "../types/interfaces";

///////////////////////////////////////////////////////
// fetch API data and store result data in state
let token = "";
const stringifiedToken = sessionStorage.getItem("token");
if (typeof stringifiedToken === "string") {
  token = stringifiedToken;
}
export const globalToken = token;

const UpLis = ({
  count,
  text,
  border,
  redColor,
  neutralColor,
  blueColor,
  orangeColor,
  themeColor,
}: Uplis) => {
  return (
    <div className={`block m-1 w-full ${border && "shadow"}`}>
      <div className={`flex text-[13px] tracking-wide`}>
        <div
          className={`flex items-center justify-center bg-white text-[#012063] p-2 rounded-sm font-medium block w-[38px] text-center mb-1 ${
            neutralColor && "bg-gray-500 !text-white"
          } ${redColor && "bg-red-600 !text-white"} ${
            blueColor && "!bg-[#36a2eb] !text-white"
          }  ${orangeColor && "!bg-[#ff6384] !text-white"} ${
            themeColor && "!bg-[#36a2eb] !text-white"
          }`}
        >
          {count}
        </div>
        <span
          className={`flex items-center px-2 py-0 text-[#012063] font-medium ${
            neutralColor && ("text-gray-800" || "!text-[#D7320D]")
          } ${redColor && ("text-gray-800" || "!text-[#D7320D]")} ${
            blueColor && ("text-gray-800" || "!text-[#36a2eb]")
          } ${orangeColor && ("text-gray-800" || "!text-[#ff6384]")} ${
            themeColor && ("text-gray-800" || "!text-grey-800")
          }`}
        >
          {" "}
          {text}
        </span>
      </div>
    </div>
  );
};



/** COMPONENT STARTS HERE **/

export const AdminHome = () => {
  const location = useLocation();
  const token = location.state?.token;


  /** COMPONENT MOUNT */
  const dispatch = useAppDispatch();
  const { images:photos, videos } = useSelector((state:any):any=> {
    return state.photos
  }) || [];
  const { conversations } = useAppSelector((state:any):any=> {
    return state.messages
  }) || [];
  const { width: windowWidth } = useWindowDimensions();
  const [showGrowthMonitor, setShowGrowthMonitor] = useState(false);
  const [summaryData, setSummaryData] = useState({
    absent: 0,
    birthdays: 0,
    students: 0,
    classrooms: 0,
    loggedin: 0,
    loggedout: 0,
    messages: 0,
    "non-teachers": 0,
    parent: 0,
    photosandvideos: 0,
    teachers: 0,
  });
  const [classrooms, setClassrooms] = useState([]);


  useEffect(() => {
    fetchDashboardData();
    fetchClassrooms();
    dispatch(fetchConversationsThunk());
    dispatch(getMediaThunk());
  }, []);



  /** DATA FETCHING */
  const fetchDashboardData = async () => {
    const data = await makeFetchRequest(`${defaultUrl}/user/dashboard`, false, false, token)
    // console.log({ d: data });
    setSummaryData(data);
  };


  const fetchClassrooms = async () => {
    const response = await makeFetchRequest(`${defaultUrl}/classroom`, false, true, token);
    // console.log({ result: response});
    setClassrooms(response || []);
    return response || [];
  };


  /**ACTIONS */
  function toggleGrowthMonitor() {
    setShowGrowthMonitor((prev) => !prev);
  }



  return (
    <section
      className={`flex-1 h-[98vh] overflow-auto register-scroll `}
    >
      <div className="w-full h-max max-h-[7vh]">
        <TopAdminNav name="Jone Doe" title="Welcome Admin" />
      </div>

      <div className="h-full flex ">
        <div className=" block *w-[700px]* md:w-full 2xl:w-9/12 ">
          <div className="flex md:flex-row flex-wrap lg:flex-wrap-none justify-evenly">
            <AdminDashboardCard
              first="Attendance"
              second="Report"
              icon={<img src={icon1} alt="" />}
              link_to="attendance"
            >
              <div className="h-full w-full">
                <div className="flex mt-4 justify-between items-center w-full">
                  <div className="-mt-4">
                    <ChartAttendance />
                  </div>
                  <div className="flex flex-col px-1 w-full ml-4 md:ml-0">
                    <UpLis
                      count={summaryData && summaryData.loggedin}
                      text="Logged In"
                      blueColor
                    />
                    <UpLis
                      count={summaryData && summaryData.loggedout}
                      text="Logged out"
                      orangeColor
                    />
                    <UpLis
                      count={summaryData && summaryData.absent}
                      text="Absent"
                      orangeColor
                    />
                  </div>
                </div>
              </div>
            </AdminDashboardCard>
            <AdminDashboardCard
              first="Activities"
              icon={<img src={icon2} alt="" />}
              link_to="/admin/attendance"
            >
              <div className="flex w-full mt-11 flex-col">
                <UpLis
                  border
                  count={summaryData && summaryData.messages}
                  text="Messages"
                  themeColor
                />
                <UpLis
                  border
                  count={summaryData && summaryData.birthdays}
                  text="Birthdays"
                  themeColor
                />
                <UpLis
                  border
                  count={summaryData && summaryData.photosandvideos}
                  text="Photos & Videos"
                  themeColor
                />
              </div>
            </AdminDashboardCard>
            <AdminDashboardCard
              first="Overview"
              icon={<img src={icon3} alt="" />}
              link_to="attendance"
            >
              <div className="w-full">
                <div className="flex w-full mt-11 flex-col">
                  <UpLis
                    border
                    count={summaryData && summaryData.classrooms}
                    text="Classrooms"
                    themeColor
                  />
                  <UpLis
                    border
                    count={summaryData ? summaryData.students : 0}
                    text="Active Children"
                    themeColor
                  />
                  <UpLis
                    border
                    count={summaryData && summaryData.parent}
                    text="Parents"
                    themeColor
                  />
                </div>
                <div className="flex justify-between items-center mt-4 p-2">

                </div>
              </div>
            </AdminDashboardCard>
          </div>
          <div className="flex flex-col md:flex-row md:flex-wrap lg:flex-wrap-none justify-evenly">
            <div className='mt-8 pt-8 flex-1 border-t border-[#0096D133] '>
              <p className="underline text-xs text-[#012063]">Recently added</p>
              <div className="flex-1 flex flex-col md:flex-row md:flex-wrap lg:flex-wrap-none justify-evenly items-center py-4 max-h-[400px] overflow-auto register-scroll">
              {
                photos && photos?.slice(0, 10).map((item:any, index:number) => (
                  <div key={index} className="my-4"><DashboardPhotoCard item={item} /></div>
                ))
              }
              </div>
            </div>
          </div>
        </div>
        <div className="hidden relative 2xl:block md:w-3/12 w-full">
          <div className="md:absolute overflow-hidden">
            <AdminCalRec />
          </div>
        </div>
      </div>

      {showGrowthMonitor && (
        <div>
          <CustomModal
            width={90}
            title="Growth Monitor"
            children={<GrowthMonitor />}
            hideModal={toggleGrowthMonitor}
          />
        </div>
      )}
    </section>
  );
};
