/* eslint-disable import/no-anonymous-default-export */
import photoSample from '../assets/sampleDashboardPhoto.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default ({item, key,}:any)=> {
    // console.log({item})
    return (
        <div key={key} className="p-2 border-2 border-blue-100 rounded-lg w-[253px] overflow-hidden">
            <div className="max-h-[309px]">
                {/*Photo container*/}
                <LazyLoadImage
                 src={item.uri}
                 alt=""
                 className=''
                 effect="blur"
                 wrapperProps={{
                     style: {transitionDelay: "1s"},
                 }}
                  />
            </div>
        </div>
    )
}
