import "../../assets/css/studentRegistration.css";
import { useState, useEffect, useMemo } from "react";
import countryList from "react-select-country-list";
import { defaultUrl } from "../../features/actions";
import { makeFetchRequest } from "../../utilities/common";

export const First = ({
  handleChange,
  nextStep,
  form,
}: any) => {
  const [getClass, setGetClass] = useState([]);
  window.sessionStorage.removeItem("parentIdd");

  async function queryClassrooms() {
    const data = await makeFetchRequest(`${defaultUrl}/classroom`, false, true)
    setGetClass(data)
  }

  useEffect(() => {
    queryClassrooms();
  }, []);

  const options = useMemo(() => countryList().getData(), []);


  return (
    <>
      <div className="indicator">
        <div onClick={() => nextStep(1)} className="firstInd">
          1
        </div>
        <div onClick={() => nextStep(2)} className="firstInd2">
          2
        </div>
        <div onClick={() => nextStep(3)} className="firstInd2">
          3
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          nextStep();
        }}
        className="regStudentForm"
      >
        <div>
          <h2>Pupil</h2>

          <div className="studentFormContent">
            <h4>Name<span className="text-red-600">*</span></h4>
            <div className="nameForm">
              <input
                type="text"
                placeholder="First name*"
                name="firstname"
                value={form?.firstname}
                onChange={({ target })=> handleChange(target)}
                required
              />
              <input
                type="text"
                placeholder="Surname*"
                name="surname"
                value={form?.surname}
                onChange={({ target })=> handleChange(target)}
                required
              />
              <input
                type="text"
                placeholder="Other Names"
                name="othername"
                value={form?.othername}
                onChange={({ target })=> handleChange(target)}
              />
            </div>
          </div>
          <div className="studentFormContent">
            <h4>Gender<span className="text-red-600">*</span></h4>
            <div className="genderForm">
              <span>
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  onChange={({ target })=> handleChange(target)}
                  checked={form?.gender === "male"}
                  required
                />{" "}
                Boy
              </span>
              <span>
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  onChange={({ target })=> handleChange(target)}
                  checked={form?.gender === "female"}
                  required
                />{" "}
                Girl
              </span>
            </div>
          </div>

          <div className="studentFormContent">
            <h4>Date of Birth<span className="text-red-600">*</span></h4>
            <div className="nameForm">
              <input
                type="date"
                name="date_of_birth"
                value={form?.date_of_birth}
                onChange={({ target })=> handleChange(target)}
                required
              />
            </div>
          </div>
          <h2 className="font-medium text-center">Class Enrollment</h2>
          <div className="studentFormContent">
            <h4>Class<span className="text-red-600">*</span></h4>
            <div className="nameForm">
              <select
                name="classroom_id"
                value={form.classroom_id}
                onChange={({ target })=> handleChange(target)}
                required
              >
                <option value="">Select class</option>
                {getClass.map((user: any, idx: number) => {return (
                    <option key={user?.id} value={user?.id}>{user?.name}</option>
                )})}
              </select>
            </div>
          </div>

          <div className="studentFormContent">
            <h4>Admission Date<span className="text-red-600">*</span></h4>
            <div className="nameForm">
              <input
                type="date"
                name="admission_date"
                value={form?.admission_date}
                onChange={({ target })=> handleChange(target)}
              />
            </div>
          </div>

          <h2>Address</h2>
          <div className="">
            <div className="studentFormContent">
              <h4>Address<span className="text-red-600">*</span></h4>
              <div className="nameForm">
                <input
                  type="text"
                  placeholder="No/Street*"
                  name="house_address"
                  value={form.house_address}
                  onChange={({ target })=> handleChange(target)}
                  required
                />
                <div className="nameFormDiv">
                  <input
                    type="text"
                    placeholder="City*"
                    name="city"
                    value={form.city}
                    onChange={({ target })=> handleChange(target)}
                    required
                  />
                  <input
                    type="text"
                    placeholder="State*"
                    name="state"
                    value={form.state}
                    onChange={({ target })=> handleChange(target)}
                    required
                  />
                </div>
                <select name="country" onChange={({ target })=> handleChange(target)} required id="">
                  <option value="" >Select Country</option>
                  <option value="Nigeria" >Nigeria</option>
                  {options?.map((country: any, idx:number) => {
                    return (
                     <option key={country.label} value={country.label}>{country.label}</option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="studentFormContent">
              <h4>ZIP / Postal Code</h4>
              <div className="zip">
                <input
                  type="text"
                  placeholder="Code"
                  name="zip_code"
                  value={form.zip_code}
                  onChange={({ target })=> handleChange(target)}
                />
              </div>
            </div>
            <div className="studentFormContent">
              <h4>Address 2</h4>
              <div className="nameForm">
                <textarea name="" id="" placeholder="Optional"></textarea>
              </div>
            </div>
          </div>
          <button className="studentRegBtn">Next step</button>
        </div>
      </form>
    </>
  );
};
