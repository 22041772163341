import React from 'react'

import img1 from "../../../assets/image/report docs/x4.png"
import img2 from "../../../assets/image/report docs/x5.png"
import img3 from "../../../assets/image/report docs/x6.png"


function DocsAddingData() {
  return (
    <div className='my-16'>
      <h4 className='font-bold text-lg'>1.   ADDING DATA:</h4>

      <p className='my-3'>Input your report information in an Excel spreadsheet.</p>
      <p>
      <b>NOTE:</b> In situations where you have more than one box for an academic report sheet,
       you should add it into another sheet inside the same file on Excel: 
      </p>

      <div className='my-5'>
        <img src={img1} alt='' className='rounded-md h-full w-full' />
        </div>

        <div className='my-5'>
        <img src={img2} alt='' className='rounded-md h-full w-full' />
        </div>

        <div className='my-5'>
        <img src={img3} alt='' className='rounded-md h-full w-full' />
        </div>
    </div>
  )
}

export default DocsAddingData
