import { CaseReducer, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchMedia } from './actions';
import { toast } from 'react-hot-toast';

type State = {
    images: any[],
    videos: any[],
    loading: boolean
};
const initialState: State = {
    loading: false,
    images: [],
    videos: []
};

export const getMediaThunk = createAsyncThunk("media/fetch", async (_, { getState })=> {

    const token = sessionStorage.getItem("token") as string;
    const result = await fetchMedia(token);
    // console.log({resultInThunk: result})
    return result;
});

const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
        .addCase(getMediaThunk.fulfilled, (state, action: PayloadAction<any>) => {
            toast.remove(); toast.success("Media updated successfully");
            return {...state, ...action.payload, loading:false}
        })
        .addCase(getMediaThunk.pending, (state) => {toast.remove(); toast.loading("Updating Media...")})
        .addCase(getMediaThunk.rejected, (state) => {toast.remove(); toast.error("Failed to fetch media")})
        // .addCase(updateFeedThunk.fulfilled, (state, action: PayloadAction<any>) => ([action.payload, ...state]))
    },
})

export default mediaSlice.reducer
