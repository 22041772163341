import { createSlice, current } from "@reduxjs/toolkit";
import { fetchConversationsThunk, openConversationThunk } from "../actions";
import { store } from "../../app/store";
import toast from "react-hot-toast";


let initialState = {};
let reducers = {
    update: (state:any, action:any) => {
        let storeState = current(state);
        console.log({state, Convo: storeState?.convo, action: action.payload});
        // if(state.conID === action.payload.conversation_id) {
            let prevMessages:any[] = state.messages;
            return {...state, messages: [...prevMessages, action.payload]};
        // }
    }
};

let messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers,
    extraReducers(builder) {
        builder
        .addCase(openConversationThunk.fulfilled, (state, action) =>  {
            // console.log({payloadInBuilder: action.payload});
            return {...state, ...action.payload};
        })
        .addCase(fetchConversationsThunk.pending, (state, action) =>  {
            toast.loading("Fetching conversations...")
        })
        .addCase(fetchConversationsThunk.fulfilled, (state, action) =>  {
            toast.remove();
            toast.success("Fetched Successfully");
            return {...state, ...action.payload};
        })
        .addCase(fetchConversationsThunk.rejected, (state, action) =>  {
            toast.remove();
            toast.error("Failed to fetch conversations");
        })
    },
});

export let { update } = messageSlice.actions;


export default messageSlice.reducer;