import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/studentRegistration.css";
import BackButton from "../components/backButton";
import { useState, useEffect } from "react";
import { CircleLoader } from "../components/CircleLoader";
import { defaultUrl } from "../features/actions";
import { makeFetchRequest, makePostRequest } from "../utilities/common";

export const AddClass = () => {
  const [loading, setLoading] = useState(false);

  const [addClass, setAddClass] = useState({
    name: "",
    teacher_id: "",
  });
  const [getTeacher, setGetTeacher] = useState([]);

  const handleAddClass = ({ target: { name, value } }: any) => {
    setAddClass({ ...addClass, [name]: value });
  };

  const handleClassSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      name: addClass.name.trim(),
      teachers: addClass.teacher_id && [addClass.teacher_id.trim()],
    }

    await makePostRequest( `${defaultUrl}/classroom/create`,body)

    setLoading(false);
    setAddClass({
      name: "",
      teacher_id: "",
    });

  };

  async function queryTeacher() {
    const response = await makeFetchRequest(`${defaultUrl}/teachers`, false, true)
    setGetTeacher(response);
  }

  useEffect(() => {
    queryTeacher();
  }, []);

  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Create Class Form" />
      </div>

      <div className="studentReg">
        <div>
          <div className="studentRegisterCard">
            <RegisterCard
              title="Class"
              details="Enter Class data"
              activeNumber={10}
              activeDetails="Active Class"
            />
          </div>
          <form onSubmit={handleClassSubmit} className="regStudentForm class">
            <div>
              <h2>Enter details</h2>

              <div className="studentFormContent">
                <h4>Class Name</h4>
                <div className="nameForm">
                  <input
                    type="text"
                    placeholder="Enter Classroom name"
                    name="name"
                    value={addClass.name}
                    onChange={handleAddClass}
                  />
                </div>
              </div>

              <div className="studentFormContent">
                <h4>Assign To</h4>
                <select
                  id=""
                  name="teacher_id"
                  className="!text-[14px] !h-10"
                  onChange={handleAddClass}
                >
                  <option value="">Select a staff</option>
                  {getTeacher && getTeacher.map((user: any, idx: number) => (
                    <option key={idx} value={user?.id}>{user?.surname}</option>
                  ))}
                </select>
              </div>

              <div className="flex justify-center">
                {/* <button className="!h-14 py-1"></button> */}
                <button type="submit" className="studentRegBtn">
                  {loading ? <CircleLoader /> : "save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
