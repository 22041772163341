import React from "react";
import UserTableHead from "./userTableHead";

import { IUserData } from "./userTableRow";
import UserTableBody from "./userTBody";

const UserTable = ({ data }: { data: IUserData[] }) => {
  return (
    <div className="min-w-[1000px] lg:min-w-full overflow-x-auto w-full">
       {/* Table Head */}
       <UserTableHead />
        {/* Table body including each row */}
        <UserTableBody data={data} />
    </div>
  );
};

export default UserTable;
