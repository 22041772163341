import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";


function LandingFooter() {

    const date = new Date().getFullYear()
  return (
    <footer className="landingFooter">
    <div className="footToop">
      <div className="footToopFiest">
        <img src="15.svg" alt="" />
        <p>
        We would love to hear from you! Whether you're curious about how Pendulum Digital
         can help your school or have specific questions about our platform, feel free to reach out:
        </p>
        <div className="leftSu">
          <AiOutlinePhone />
          +234 913 782 6058
        </div>
        <div className="leftSu">
          <AiOutlineMail />
          pendulumdigitals@gmail.com
        </div>
        <div className="leftSu">
          <CiLocationOn />
          12A Dapo Adeoye Street Sholuyi Gbagada, Lagos, Nigeria
        </div>
      </div>
      <div className="footToop2 w-full lg:w-2/5 space-y-4 sm:space-y-0 my-4 px-1">
        {/* <ul className="w-full sm:w-[33%]">
          <h2>Events</h2>
        </ul>
        <ul className="w-full sm:w-[33%]">
          <h2>Support</h2>
        </ul>
        <ul className="w-full sm:w-[33%]">
          <h2>Blog</h2>
        </ul> */}
      </div>
    </div>
    <div className="footerBottom">
      <p>© {date}, All Rights Reserved</p>
    </div>
  </footer>
  )
}

export default LandingFooter
