import React, { useRef, useEffect } from "react";
import "../assets/css/landing.css";
import LandingHeroSection from "../components/LandingPage/landingHeroSection";
import LandingHeader from "../components/LandingPage/landingHeader";
import LandingFooter from "../components/LandingPage/landingFooter";
import LandingAbout from "../components/LandingPage/landingAbout";
import LandingWhoCan from "../components/LandingPage/landingWhoCan";
import LandingVision from "../components/LandingPage/landingVision";
import LandingOthers from "../components/LandingPage/landingOthers";
import LandingFAQs from "../components/LandingPage/landingFAQs";

import AOS from 'aos';
import 'aos/dist/aos.css';

export const Landingpage = () => {
  const aboutRef = useRef(null);
  const newsletterRef = useRef(null);
  const FAQs = useRef(null)

  const scrollTo = (id: string) => {
    let element : any;
    switch(id) {
      case 'about':
        element = aboutRef.current;
        break;
      case 'newsletter':
        element = newsletterRef.current;
        break;
      case 'FAQs':
        element = FAQs.current;
        break;
      default:
        element = null;
    }
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    AOS.init()
  },[])

  return (
    <div className="landing relative">
      <div className="fixed w-full z-[30] top-0 bg-white ">
      <LandingHeader scrollTo={scrollTo} />
      </div>
      <LandingHeroSection />
      <div ref={aboutRef}>
        <LandingAbout />
      </div>
        <LandingWhoCan />
        <LandingVision />
      <div ref={newsletterRef}>
        <LandingOthers />
      </div>
      <div ref={FAQs} >
        <LandingFAQs />
      </div>
      <LandingFooter />
    </div>
  );
};
