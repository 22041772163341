import React from "react";

interface IPagination {
  setPage: any;
  page: number;
  totalPages: number;
  totalItems?: number;
  paginationParams?: {
    page: number;
    totalPages: number;
    totalItems: number;
    from: number;
    to: number;
  };
}
const Pagination = ({
  setPage,
  page,
  totalPages,
  paginationParams,
}: IPagination) => {
  const pagesArray = Array.from({ length: totalPages }, (_, index) => {
    return index + 1;
  });

  function nextPage() {
    if (page < totalPages) {
      setPage(page + 1);
    } else {
      setPage(totalPages);
    }
  }

  function previousPage() {
    if (page > 0) {
      setPage(page - 1);
    } else {
      setPage(1);
    }
  }
  return (
    <div className="my-5 flex items-center justify-end">
      {pagesArray.length > 1 && (
        <div className="flex gap-3 items-center m-2 justify-center">
          <button
            className={`p-2 rounded  ${page === 1 ? 'cursor-not-allowed text-gray-400' : 'hover:bg-[#012063CC] hover:text-white'}`}
            onClick={() => previousPage()}
            disabled={page === 1}
          >
            previous
          </button>
          {pagesArray.map((item, index) => {
            return (
              <button
                key={item}
                className={`border flex items-center w-2 h-2 p-3 justify-center rounded-sm cursor-pointer ${page === index + 1 && "bg-[#012063CC] text-white"
                  } border-gray-300`}
                onClick={() => {
                  setPage(item)
                }}
              >
                {item}
              </button>
            );
          })}
          <button
            disabled={page === totalPages}
            className={`p-2 rounded   ${page === totalPages ? 'cursor-not-allowed text-gray-400' : 'hover:bg-[#012063CC] hover:text-white'}`}
            onClick={() => nextPage()}
          >
            next
          </button>
        </div>
      )}
      <div className="mr-5">
        <span>
          {paginationParams?.from} - {paginationParams?.to} of{" "}
          {paginationParams?.totalItems}
        </span>
      </div>
    </div>
  );
};

export default Pagination;



export const PagenateWithoutBackEnd = ({array, page_change, pageNum, itemperPage, totalItems}: any) => {
  let totalItemByCurrentPage = itemperPage * pageNum
  if(totalItemByCurrentPage > totalItems){
    totalItemByCurrentPage = totalItems
  }

  return <>
    <div className="flex justify-end mx-4">

      {array.length >= 1 && array.map((item: any, index : number) => {
        return <button
          key={index}
          className={`border flex items-center w-2 h-2 p-3 justify-center rounded-sm cursor-pointer ${pageNum === index + 1 && "bg-[#012063CC] text-white"
            } border-gray-300`}
          onClick={() => {
            page_change(index)
          }}
        >
          {index + 1}
        </button>
      })}
      {pageNum > 1 &&  <p className="ml-4 mr-2">{totalItemByCurrentPage} of {totalItems}</p>}
    </div>
  </>
}
