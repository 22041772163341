import { AdminNav } from "../layouts/AdminNav";
import { Outlet } from "react-router-dom";
import "../assets/css/dashboard.css";
import useWindowDimensions from "../hooks/useWindowSize";


export const AdminDashboard = () => {
  const { width } = useWindowDimensions();

  const isMobileScreen = width < 440;


  return (
    <div className="flex !max-w-[100vw] !min-h-[100vh] border-box !overflow-hidden">
      <div className="">
        <AdminNav />
      </div>
      <div
        className={` !w-full ${
          isMobileScreen
            ? "pl-[26px] max-w-[100%] overflow-auto max-w-[98%]"
            : "overflow-auto max-w-[100%]"
        }`}
      >
        <div className="dASI">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
