import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/main.css";
import { useEffect, useState } from "react";
import { defaultUrl } from "../features/actions";
import toast from "react-hot-toast";
import loader from "../assets/loader.gif";
import Pagination from "../components/pagination";
import { ContactType, IPaginationParams } from "../types/interfaces";
import ContactList from "../components/contact/ContactList";
import { makeFetchRequest } from "../utilities/common";
import StudentListInput from "../staff_pages/staff_components/staff_student_data/StudentListInput";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';



export const Contact = () => {
  const [contactList, setContactList] = useState<ContactType[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginationParams, setPaginationParams] = useState<IPaginationParams>({
    page: 1,
    totalPages: 1,
    totalItems: 1,
    from: 1,
    to: 1,
  });



  async function fetchContacts() {
    setIsLoading(true);
    try {
      if (searchTerm.length > 0) {
        setPage(1);
      }

      const data = await makeFetchRequest(`${defaultUrl}/students?q=${searchTerm}&page=${page}`, true, true)

      setPaginationParams({
        ...paginationParams,
        totalPages: data?.data?.meta?.last_page,
        from: data?.data?.meta?.from,
        to: data?.data?.meta?.to,
        totalItems: data?.data?.meta?.total,
      });

      const students = data?.data.data.map((student: any) => {
        const name = `${student?.firstname} ${student?.othername || ""} ${student.surname}`;
        return {
          ...student,
          name,
          profile_picture: (
            <LazyLoadImage
              style={{ height: "100%", width: "100%" }}
              src={student?.profile_picture}
              effect="blur"
              wrapperProps={{
                  style: {transitionDelay: "1s"},
              }}
              alt="pupil"
            />
          ),
        };
      });
        // SORT contact BY FIRST NAME
        const sortByName = students && students.sort(function (a: any, b: any) {
          return a?.firstname.localeCompare(b.firstname);
        });

      setIsLoading(false);
      setTotalPage(data?.data?.meta?.last_page);
      setContactList(sortByName);
      toast.remove();
      toast.success("Success");

    } catch (err: any) {
      // console.error('Error fetching contacts:', err);
      setIsLoading(false);
      toast.remove();
      toast.error("Failed to fetch, Please try again");
    }
  }




  // pagination
  const pagesArray = Array.from({ length: totalPage }, (_, index) => {
    return index + 1;
  });



  useEffect(() => {
    const searchBar = document.getElementById("searchAttendanceInput");
    searchBar?.focus();
    fetchContacts();
    // eslint-disable-next-line
  }, [searchTerm, page]);


  return (
    <>
      <div className="w-full">
        <TopAdminNav name="Jane Doe" title="Contact" />
      </div>

      <div className="flex overflow-y-auto register-scroll">
        <div className="block w-full h-[90vh] overflow-auto register-scroll">
          <div className="flex">
            <div className="w-full flex items-center md:pr-6">
              <div className="w-full">
                <RegisterCard
                  title="Contacts and Leads management"
                  details="View Pupil's Parents/Guardians Information"
                />
              </div>
            </div>
          </div>
          <div className="h-[80vh] rounded-lg border-2 border-gray-100 px-4 !pb-0 !pt-[0.05px] attendance_bg mt-2 mr-2 overflow-y-scroll register-scroll">
            <div className="flex flex-wrap justify-start mr-6 md:justify-between text-[#1f1f1f]/[0.7] md:mt-6 mb-3 w-[98%] ">
              <div className="w-full md:w-4/12 min-w-max mt-4 md:mt-0 pl-2">
                <StudentListInput
                onChange={(e: any) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="text-[#012063CC] mt-1 w-full overflow-x-auto register-scroll">
              <div className="w-full text-center">
                <div className="flex w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
                  <div className="w-2/12">Photo</div>
                  <div className="w-3/12 text-[0.9em] font-bold flex justify-center">
                    Name
                  </div>
                  <div className="w-4/12 text-[0.9em] font-bold flex justify-start">
                    Contact 1
                  </div>
                  <div className="w-3/12 text-[0.9em] font-bold flex justify-start">
                    Contact 2
                  </div>
                </div>
                <div className="h-full max-h-[64vh] w-[98%] !min-w-[700px]  overflow-y-scroll register-scroll">
                  {isLoading ? (
                    <div className="flex justify-center m-4">
                      <div>
                        <img src={loader} alt="loader" className="" />
                      </div>
                    </div>
                  ) : (contactList && contactList.length > 0) ? (
                    contactList && contactList.map((data, index) => {
                      return (
                        <ContactList
                        key={index}
                        item={data}
                        />
                      );
                    })
                  ) : (
                    <div className="flex justify-center my-4 text-lg ">
                      No data found...
                    </div>
                  )}
                </div>

                <Pagination
                  page={page}
                  totalPages={totalPage}
                  setPage={setPage}
                  paginationParams={paginationParams}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
