import { Link } from "react-router-dom";
import "../assets/css/main.css";
import "../assets/css/student.css";
import { AiOutlinePlus} from 'react-icons/ai'
import { RiQuestionMark } from "react-icons/ri";

interface addUserCard {
  title: any;
  content: string;
  link?: string;
  hiddenQ?: boolean;
}

export const AddUserCard = ({ title, content, link, hiddenQ }: addUserCard) => {
  return (
    <Link to={`${link}`} className="link w-[350px] mr-3 mb-3">
      <div className="stud !w-full">
        <div className="studCon ">
          <h2 className="">{title}</h2>
          <p className="!w-[150px]">{content}</p>
        </div>
        <div className="studentcircleOutline">
          <div className="Studentcircle">
            {hiddenQ ? 
            <div className="bg-white flex justify-center items-center absolute rounded-full
            top-[35%] right-[10%] h-12 w-12 z-[2]">
              <RiQuestionMark className="text-2xl text-[#0096d1]" />
            </div>
            :
            <div className="iconbody">
            <p>
              <AiOutlinePlus />
            </p>
          </div>
          }


          </div>
        </div>
      </div>
    </Link>
  );
};
