import LandingAppsButtons from "./landingAppsButtons"

function LandingOthers() {
  return (
    <div>
           <section className="newsletter">
        <div className="newsText">
          <p className="w-[80%] -mt-10 sm:mt-0">Stay ahead of the curve with the latest updates from Pendulum Digital!
            Subscribe to our newsletter and be the first to know about new features,
            tips on how to maximize your experience, and insights into the future of
             digital education. Sign up today!</p>
          <input type="text" placeholder="Enter Name" />
          <input type="email" placeholder="Enter Email Address" />
        </div>

        <button>Subscribe</button>

        <div className="secondImg">
          <img src="8.svg" alt="" />
        </div>
      </section>

      <section className="download">
        <div className="downloadOut">
          <div className="downloadIn">
            <h2>Download our App</h2>

            <div className="downloadCont">
              <img data-aos="zoom-out-right" src="10.svg" alt="" />
              <div className="downloadContText flex flex-col items-center sm:m-1 mt-2">
                <h3 className="!text-center">Take electronic communication and learning to the next
                  level with the Pendulum Digital app!</h3>
                  <LandingAppsButtons />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LandingOthers
