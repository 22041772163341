import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/main.css";
import { CircleLoader } from "../components/CircleLoader";
import { defaultUrl } from "../features/actions";
import { makePostRequest } from "../utilities/common";
import logo from "../assets/white_logo.png";

export const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [signUp, setSignUp] = useState({
    email: "",
  });
  const navigate = useNavigate();

  const handleChange = ({ target: { name, value } }: any) => {
    setSignUp({ ...signUp, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    window.sessionStorage.removeItem("token");

    const response = await makePostRequest(`${defaultUrl}/forgot-password`,{email: signUp.email,})

    console.log(response)
    if(!response){
      setLoading(false);

      setSignUp({
        email: "",
      });
      navigate("/login");
    }






  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-[100vh] Login_bg">
      <div className="hidden md:block Login_bg_div px-20 pt-24 pb-20">
        <h2 className="text-white font-bold text-[27px] Login_bg_div_h2 border-none">
          <img src={logo} alt="logo here" />
        </h2>
        <div className="h-[70vh] relative ">
          <div className="absolute bottom-0">
            <h4 className="Login_bg_div_div_h4 font-bold text-[22px] pb-3">
              Do you know?
            </h4>
            <p className="Login_bg_div_div_p text-[18px] w-[400px] font-normal">
              “Education is the most powerful weapon <br /> which you can use to
              change the world.” - Nelson Mandela
            </p>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className="p-1 md:p-20 m-auto">
        <div className="w-[100%] md:w-[400px] flex-justify-center">
          <h2 className="text-center font-bold tracking-widest text-[#012063] text-[22px]">
            RESET PASSWORD
          </h2>
          <></>
          <br />
          <form onSubmit={handleSubmit} className="">
            <label className="text-[18px] tracking-normal text-[#012063] font-medium">
              Email
            </label>
            <input
              type="text"
              className="block rounded-sm Login_Input p-3 w-full"
              name="email"
              value={signUp.email}
              onChange={handleChange}
              required
            />

        <div className="w-full flex justify-end my-5
                      text-[#012063] text-sm hover:text-[#182541] ">
                      <Link to="/login">Back to Login </Link>
                    </div>
            <div>
              <button type="submit" className="studentRegBtn">
                {loading ? <CircleLoader /> : "Reset"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
