import { AddUserCard } from "../components/AddUserCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import '../assets/css/student.css'
import useWindowDimensions from "../hooks/useWindowSize";

export const Student = () => {
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 440;
  return (
    <>
      <div className="w-full">
        <TopAdminNav name="Jone Doe" title="Pupil Management" />
      </div>

          <div className="flex flex-wrap items-center pt-4">
            {!isMobileScreen && <AddUserCard
              title="Add Pupil"
              content="Enter Pupil and Parent data for Enrollment"
              link="/admin/student/register"
            />}
            {/* <AddUserCard
              title="Child Evaluation"
              content="Evaluate and access child Development"
              link="/admin/student"
            /> */}

            <AddUserCard
              title="Child Data"
              content="Enter pupil data"
              link="/admin/student/student_data"
            />
            {/* <AddUserCard
              title="Child Data"
              content="Enter student data"
              hidden
            /> */}
      </div>
    </>
  );
};
