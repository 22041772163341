import React, { useState } from "react";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { Link } from "react-router-dom";
import cardBg from "../assets/image/auxiliary-bg.svg";
import healthBg from "../assets/image/health-bg.svg";
import financeBg from "../assets/image/finace-bg.svg";
import CustomModal from "../components/CustomModal/CustomModal";
import foodLogo from "../assets/image/foodLogo.jpg";
import foodIcon from "../assets/image/food-icon.png";
interface IAuxiliaryCard {
  bgImage: string;
  title: string;
  description: string;
  isActive: boolean;
}

const auxiliaryCardContent: IAuxiliaryCard[] = [
  {
    bgImage: cardBg,
    title: "Food",
    description: "Swing into Food Value Chain Space",
    isActive: true,
  },
  {
    bgImage: financeBg,
    title: "Coming soon...",
    description: "Swing into Financial Space",
    isActive: false,
  },
  {
    bgImage: healthBg,
    title: "Coming soon...",
    description: "Swing into Healthcare Space",
    isActive: false,
  },
];

const Billing = () => {
  const [openFoodModal, setOpenFoodModal] = useState<{
    food: boolean;
    finance: boolean;
    health: boolean;
  }>({
    food: false,
    finance: false,
    health: false,
  });
  const [openModal, setOpenModal] = useState(false);

  function handleModalState(title: any) {
    setOpenFoodModal({ ...openFoodModal, [title]: true });
  }

  function toggle() {
    setOpenModal(!openModal);
    console.log(openModal);
  }

  return (
    <div>
      <div className="w-full">
        <TopAdminNav title="Auxiliary" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:col-span-3 lg:gap-x-6">
          {auxiliaryCardContent && auxiliaryCardContent.map((item: IAuxiliaryCard, idx:number) => {
            const { bgImage, title, description, isActive } = item;
            return (
              <div
                key={idx}
                className={`h-[200px] relative ${
                  isActive ? "cursor-pointer" : "cursor-not-allowed"
                }`}
                onClick={() => setOpenModal(!openModal)}
              >
                <div className="bg-no-repeat h-full px-[30px] py-[26px] text-white">
                  <p className=" text-lg font-semibold">{title}</p>
                  <p className="text-sm max-w-[131px]">{description}</p>
                </div>
                <div>
                  <img
                    src={bgImage}
                    alt="bg"
                    className="absolute top-0 -z-10"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div></div>
      </div>

      {openModal && (
        <div>
          <CustomModal
            width={90}
            title=""
            children={
              <div className="flex justify-center items-center h-full">
                <div className="flex flex-col items-center gap-3">
                  <div className="w-1/4">
                    <img src={foodIcon} alt="food icon" />
                  </div>
                  <div className="text-center">
                    <p className="font-bold">Food Value</p>
                    <p className="">Chain</p>
                  </div>
                  <div className="w-1/3">
                    <img src={foodLogo} alt="food logo" />
                  </div>
                  <a href="https://wa.link/2j2tym" className="rounded-2xl px-3 py-2 bg-[#0096D1] text-white mt-5 cursor-pointer">
                    Swing In
                  </a>
                </div>
              </div>
            }
            hideModal={() => setOpenModal(false)}
          />
        </div>
      )}

      {/* <div className="headerBody">
        <header className="header">
          <div className="headerCircle"></div>
          <div className="headerCap">
            <div className="headerText">
              <h2>We have the perfect plan for your School Management.</h2>
              <p>
                Borem ipsum dolor sit amet, consectetur elit. Nunc vulputate
                libero et.
              </p>

              <div className="headerBtn">
                <button className="bbnntt1">Yearly</button>
                <button className="bbnnt">Monthly</button>
              </div>
            </div>
            <div className="headerImg">
              <img src="/img/group .png" alt="" />
            </div>
          </div>
        </header>
        <section className="plans">
          <div className="plansCon">
            <div className="plansConTop">
              <h2>Basic Plan</h2>
              <p>#50,000</p>
            </div>
            <div className="plansBody">
              <h3>Features</h3>
              <ul>
                <li>Borem ipsum dolor sit amet</li>
                <li>Consectetur elit. Nun</li>
                <li>c vulputate libero et.</li>
                <li>Borem ipsum dolor sit amet,</li>
                <li>Consectetur elit. Nunc </li>
              </ul>

              <button>Contact Sales</button>
            </div>
          </div>

          <div className="plansCon">
            <div className="plansConTop">
              <h2>Normal Plan</h2>
              <p>#70,000</p>
            </div>
            <div className="plansBody">
              <h3>Features</h3>
              <ul>
                <li>Borem ipsum dolor sit amet</li>
                <li>Consectetur elit. Nun</li>
                <li>c vulputate libero et.</li>
                <li>Borem ipsum dolor sit amet,</li>
                <li>Consectetur elit. Nunc </li>
                <li>Consectetur elit. Nun</li>
                <li>c vulputate libero et.</li>
                <li>c vulputate libero et.</li>
                <li>Borem ipsum dolor sit amet,</li>
              </ul>

              <button>Contact Sales</button>
            </div>
          </div>
          <div className="plansCon">
            <div className="plansConTop">
              <h2>Premium Plan</h2>
              <p>#70,000</p>
            </div>
            <div className="plansBody">
              <h3>Features</h3>
              <ul>
                <li>Borem ipsum dolor sit amet</li>
                <li>Consectetur elit. Nun</li>
                <li>c vulputate libero et.</li>
                <li>Borem ipsum dolor sit amet,</li>
                <li>Consectetur elit. Nunc </li>
                <li>Consectetur elit. Nun</li>
                <li>c vulputate libero et.</li>
                <li>c vulputate libero et.</li>
                <li>Borem ipsum dolor sit amet,</li>
                <li>Consectetur elit. Nun</li>
                <li>c vulputate libero et.</li>
                <li>c vulputate libero et.</li>
                <li>Borem ipsum dolor sit amet,</li>
              </ul>
              <Link to="/">
                <button>Contact Sales</button>
              </Link>
            </div>
          </div>
        </section>
        <header className="header">
          <div className="headerCircle"></div>
          <div className="headerCapp">
            <div className="headerImg">
              <img src="/img/group.png" alt="" />
            </div>
          </div>
        </header>
      </div> */}
    </div>
  );
};

export default Billing;
