import staffImage from "../../assets/image/staff-image.svg";

export interface IRecentMessage {
  sender: string;
  messageContent: string;
  time: string;
  receiver: string;
}

const RecentMessageCard = (props: IRecentMessage) => {
  const { sender, messageContent, time, receiver } = props;
  return (
    <div className="flex gap-2 items-start rounded-lg py-3 px-1 border">
      <img src={staffImage} alt="staff" />
      <div className="flex flex-col justify-between text-[15px] text-[#012063]">
        <span className="text-lg font-bold text-[#012063]">From {sender}</span>
        <p className="max-w-[254px] text-sm">{messageContent}</p>
        <div className="flex justify-between items-center italic text-[#4D4D4D]">
          <span className="font-light text-sm">{time} ago</span>
          <span className="font-medium text-sm">{receiver}</span>
        </div>
      </div>
    </div>
  );
};

export default RecentMessageCard;
