import React from 'react'

import img1 from "../../../assets/image/report docs/x7.png"



function DocsComment() {
  return (
    <div className='my-16'>
      <h4 className='font-bold text-lg'>2.   ADDING HEAD TEACHER’S/PRINCIPAL COMMENT:</h4>

      <p>
      For the head teacher's or principal's remark/comment on each pupil, create a sheet, 
      rename it "comment" and add the remark/comment text.
      </p>
      <p>Example:</p>

      <div className='my-5'>
        <img src={img1} alt='' className='rounded-md h-full w-full' />
        </div>

        <p className='my-4'>
       <b>NOTE:</b> This is for head teachers/principals only. Teachers will fill in their comments on the website.
        </p>

    </div>
  )
}

export default DocsComment
