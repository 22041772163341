const UserTableHead = () => {
  return (
    <div className="grid grid-cols-12 p-4 rounded-xl border font-semibold text-base text-[#1F1F1F]/[0.7] bg-white mb-8">
      <div className="col-span-11 grid grid-cols-4 text-center">
        <p className="text-[#012063]/[0.7] ">Name/Contact</p>
        <p className="">Email/Contact</p>
        <p className="">Address</p>
        <p className="">Plan</p>
      </div>
      <div className="col-span-1 text-end">
        <p className="">Action</p>
      </div>
    </div>
  );
};

export default UserTableHead