import React from 'react'
import { TopAdminNav } from '../layouts/TopAdminNav';
import { AddUserCard } from '../components/AddUserCard';

function ReportCardCategory() {
    const txt = <span className='text-lg font-[600]'>How to Generate <br/> Report Sheet</span>
  
    return (
      <>
        <div className="w-full">
          <TopAdminNav name="Jone Doe" title="Generate Academic Report" />
        </div>
  
        <div className="flex flex-wrap items-center pt-4">
        <AddUserCard
              title="Nursery Class"
              content="Generate Report"
              link="/staff/report-sheet/generate-report-sheet"
            />
          <AddUserCard
            title="Primary Class"
            content="Generate Report"
            link="/staff/report-sheet/generate-report-sheet?primaryclass=true"
          />
        <AddUserCard
            title={txt}
            content="Documentation"
            link="/staff/report-sheet/how-to/generate-report-sheet"
            hiddenQ={true}
          />
        </div>
      </>
    );
}

export default ReportCardCategory
