import "../assets/css/calendar.css";
import { EventItem } from "./EventAndCalendar";
import { formatTime } from "../utilities/importantFns";
import { readableDate } from "../utilities/common";

const EventList = ({
  events,
  selectedDate: value,
}: {
  events: EventItem[];
  selectedDate: Date;
}) => {
  const selectedDaystring = value.toDateString();
  const todayEvents = (currentEvent: any) => {
    let stripped = currentEvent.start_date.split(" ")[0]
    const formattedStripped = formatTime(value).split(" ")[0]
    return stripped === formattedStripped
}
const sortByDate = events && events.sort((a: any,b: any) =>{
  return a.start_date?.localeCompare(b.start_date)
})
// console.log(events)



  return (
    <div className="w-[70vw] min-w-min">
      <div
        className={`shadow my-2 px-2 pb-2 flex flex-wrap items-center justify-between`}
      >
        <h1 className={`text-[#012063B2] font-[600] text-[25px] text-center`}>
          {selectedDaystring}
        </h1>
      </div>
      <div className="h-[50vh] w-full flex flex-wrap items-start justify-center md:justify-start overflow-auto register-scroll">
        {events && events.length > 0 ? (
          sortByDate?.map((item, index) => {
            const satrt = readableDate(item.start_date)
            const end = readableDate(item.end_date)

            const isToday = todayEvents(item)


            return (
              <div key={index} className={`${isToday && 'bg-gray-200'} rounded border-2 border-[#0096D1] border-opacity-50 flex flex-col m-2 p-2`}>
                <div>
                  <h2 className="capitalize flex flex-col  text-[15px]">
                    <span className="text-xs text-[#0096D1] mr-1">Title: </span>
                    <b>{item.title}</b>
                  </h2>
                  <p className="capitalize flex flex-col my-4 text-[13px]">
                    <span className="text-xs text-[#0096D1] mr-1">
                      Description:{" "}
                    </span>
                    <span className=" h-10 max-h-20 w-60 max-w-80 overflow-auto register-scroll">
                      {item.description}
                    </span>
                  </p>
                  <p className="text-xs font-bold text-[#0096D1] mr-1">
                     {satrt}
                    <span
                      className={`${
                        item.start_date === item.end_date && "hidden"
                      }`}
                    >
                      {" "}
                      to {end}
                    </span>
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="animate-pulse"> No Events </p>
        )}
      </div>
    </div>
  );
};

export default EventList;
