import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/main.css";
import { useParams } from "react-router-dom";
import BackButton from "../components/backButton";
import { useState, useEffect, useRef } from "react";
import { useAppDispatch } from "../app/hooks";
import useWindowDimensions from "../hooks/useWindowSize";
import { defaultUrl, openConversationThunk } from "../features/actions";
import { toast } from "react-hot-toast";
import { makeFetchRequest, makePostRequest } from "../utilities/common";
import BroadcastForm from "../components/Messages/BroadcastForm";
import { GrDocumentPdf } from "react-icons/gr";
import MessageDisplayType from "../layouts/MessageDisplayType";

export const MessageBox = ({chat}: any) => {
  const {conversationID} = useParams()
  const divRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const isMobileScreen = windowWidth < 440;

  const [message, setMessageID] = useState<any>()
  const [allmessages, setAllMessageID] = useState<any>()
  const getReceiverId = window.localStorage.getItem("parentMessageId")?.replace(/['"]+/g, "");
  const getLocalConversation = JSON.parse(sessionStorage.getItem("messageBoxDetails") as string);
  const [reciever, setReceiver] = useState("")
  const [messageToSend, setMessageToSend] = useState("");


  const fetchAllConversations = async () => {
    // GET DETAILS OF THE RECEIPIENT
    const response = await makeFetchRequest(`${defaultUrl}/conversations`)
    const result = response && response.filter((item: any)=> {
      return String(item.conversation_id) === String(conversationID)
   })
   setMessageID(result)
   setReceiver(result[0]?.recipient)

   // GET THE MESSAGES BY CONVERSATION ID
   const response2 = await makeFetchRequest(`${defaultUrl}/conversations/${conversationID}`)
   const reversedResponse = response2.reverse()
   setAllMessageID(reversedResponse)

  divRef.current?.scrollIntoView({ behavior: 'auto', block: 'end' });
}


  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const body = {
      message: messageToSend,
      reciever_id: getReceiverId || message.recipientID ,
      reciever_type: "parent",
    }

   if(messageToSend === ""){
    toast.error("Message field is empty, please enter a message.")
   }else{
    toast.loading("Sending message...");
    try {
      const response = await makePostRequest( `${defaultUrl}/messages`,body)

      const { conversation_id }: any = response

      const convo = {
          conversation_id,
          recipient: reciever,
          recipient_id: getReceiverId,
        }

        if(convo){
        dispatch(openConversationThunk(convo))
        }
        setMessageToSend('')
        toast.remove();
        toast.success("Message sent!")
        fetchAllConversations()
    } catch (error) {
      toast.remove();
      toast.error("Message not sent. Please try again.")
    }

   }

  };



  const handleMessageChanges = (e: any) => {
    setMessageToSend(e.target.value);
  };




  useEffect(()=> {
    fetchAllConversations()
    getLocalConversation &&
    dispatch(openConversationThunk(getLocalConversation?.convo))
  }, [])


  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav title="Message" />
      </div>

      <div className="relative messagePage rounded-lg border border-gray-100 shadow !p-0 my-4">
        <div className="chatTop h-20 pl-2">
        {message &&
          <div className="chatProfile">

            <div
              className="linearGradientButton1 opacity-70 rounded-full h-[60px] w-[60px] bg-green-500 flex justify-center items-center"
            >

              <p className={`text-white ${isMobileScreen? 'text-md' : "text-xl"} font-semiBold`}>{message.length > 0  && message[0].recipient.split(' ').map((word: any) => word[0]).join('')}</p>
            </div>
            <div>

              <h2 className="!text-[16px]">{message.length > 0 && message[0]?.recipient}</h2>

              <p className="text-gray-100 text-sm">
                Recipient
              </p>
            </div>

          </div>
             }
          {/* <h2 className="ChatDate !text-[16px]"></h2> */}
        </div>
         {/* {chat.sender === "You" ? (   ) : ( )} */}

        <div className="chatSystem !px-4 pb-0">
          <div className="overflow-auto h-[50vh]">
          {allmessages && allmessages?.map((chat: any) => {
            return (
              <div key={chat.message_id} className={`my-1 w-full flex py-2 ${chat.sender === 'You'? "justify-end" : "justify-start"}`}>
                <div className={chat.sender !== 'You'? "secondMan" : "firstMan"}>
                  {(chat.sender !== 'You' && message) &&
                  <div
                    className={`linearGradientButton1 opacity-70
                      rounded-full ${isMobileScreen? "min-h-[40px] min-w-[40px]" :
                         "min-h-[50px] min-w-[50px]"} flex justify-center items-center`}
                  >

                    <p className={`text-white ${isMobileScreen? 'text-xs' : "text-lg"} font-semiBold`}
                    >{message.length > 0 && message[0].recipient.split(' ').map((word: any) => word[0]).join('')}</p>
                  </div>}
                  <div className={`${chat.sender !== 'You'? "secondChat" : "firstChat"}`}>

                    <MessageDisplayType message={chat.message} />
                    <span className="!text-[10px]">{chat.sent_at}</span>

                  </div>
                </div>
              </div>
            );
          })}
          <div ref={divRef} className="pt-[40px]" style={{ float: "left", clear: "both" }}></div>
          </div>


        </div>
        <div className="absolute bottom-0 left-2 w-full">
          <BroadcastForm
           mobileScreen={isMobileScreen}
           messageToSend={messageToSend}
           onChange={handleMessageChanges}
           onSubmit={handleSubmit}

          />
          </div>
      </div>
    </>
  );
};
