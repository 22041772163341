import { AiFillVideoCamera, AiTwotoneCamera } from "react-icons/ai";
import { RegisterCard } from "../../components/RegisterCard";
import { TimeSelect } from "../../components/TimeSelect";
import { CircleLoader } from "../../components/CircleLoader";


export const CAtemeperature = ({
  formData,
  onFormChange,
  onFormSubmit,
  loading
}: any) => {
  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="Temperature"
          details="Activity will be sent to parent/ guardians"
        />
        <br />
        <form onSubmit={onFormSubmit}>
          <div className="flex  flew-wrap items-center justify-between h-[90vh] py-4 overflow-y-scroll px-2.5 w-full">
            <div className="!p-0 md:w-2/3">
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Condition
                </h4>
                <div className="w-2/3 md:pl-6 ">
                  <div className="w-max flex items-center justify-evenly rounded border bg-[#f5f5f5] p-4 py-3">
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="condition"
                        checked={formData["condition"] === "High"}
                        onChange={(e) => onFormChange("condition", "High")}
                        className="form-input checkbox mr-2"
                      />
                      High
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="condition"
                        checked={formData["condition"] === "Normal"}
                        onChange={(e) => onFormChange("condition", "Normal")}
                        className="form-input checkbox mr-2"
                      />
                      Normal
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        type="checkbox"
                        name="condition"
                        checked={formData["condition"] === "Low"}
                        onChange={(e) => onFormChange("condition", "Low")}
                        className="form-input checkbox mr-2"
                      />
                      Low
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Time
                </h4>
                <div className="w-2/3 md:pl-6 flex items-center">
                  <div className="w-80 min-w-max h-10 mr-1 pb-1 rounded-lg border">
                    <TimeSelect
                      value={formData.time ?? "10:00"}
                      onChange={(val: string) => onFormChange("stop_time", val)}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Temperature
                </h4>
                <div className="w-2/3 md:pl-6">
                  <input
                    required
                    type="text"
                    name="temperature"
                    defaultValue={formData["temperature"]}
                    onChange={(e) =>
                      onFormChange("temperature", e.target.value)
                    }
                    className="p-2 w-80 rounded-lg border focus:outline-none"
                  />
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Description
                </h4>
                <div className="w-full md:w-2/3 md:pl-6">
                  <textarea
                    name="description"
                    defaultValue={formData["description"]}
                    onChange={(e) =>
                      onFormChange("description", e.target.value)
                    }
                    className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"
                  ></textarea>
                </div>
              </div>

              <div className="mt-10 flex h-12 w-full">
                <div className="hidden md:block w-1/3"></div>
                <div className="w-full md:w-2/3 md:pl-6">
                  <button className="flex items-center justify-center text-white w-full md:w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]">
                     {loading ? <CircleLoader /> : "Add"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile">
              <img
                src="/img/thermometer.svg"
                alt="login"
                className="w-40 h-40"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
