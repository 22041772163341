import { FaQuoteRight, FaQuoteLeft } from "react-icons/fa";
import CustomModal from "../CustomModal/CustomModal";
import LandingPopupDemo from "./landingPopupDemo";
import { useState } from "react";

function LandingVision() {

  const [showPopUp, setShowPopUp] = useState(false)

  return (
    <div>
              <section className="testimony">
        <FaQuoteLeft className="tesIcon" />
        <p className="sm:text-xl" data-aos="flip-left">
        Pendulum Digital envisions a world where education is universally accessible,
         transparent, and enhanced by cutting-edge technology. Our vision is to be a
         global leader in educational innovation, enabling every school, teacher, parent,
          and student to thrive in a digital ecosystem that prioritizes real-time learning,
           collaboration, and growth. We aim to shape the future of education by creating an
            environment where digital tools are integral to academic success and operational efficiency.
        </p>
        <FaQuoteRight className="tesIcon" />
      </section>
      <section className="landingDemo">
        <div className="demoCon">
          <img data-aos="zoom-in" src="7.svg" alt="" />
          <button onClick={() =>setShowPopUp(true)}>Book a Demo</button> {/*Add Pop up */}
        </div>
        <img src="9.svg" alt="" className="bac" />
      </section>


      {showPopUp &&
            <CustomModal
            title={`Book a Demo`}
            width={75}
            children={
              <LandingPopupDemo />
            }
            hideModal={() =>setShowPopUp(false)}
          />
        }
    </div>
  )
}

export default LandingVision
