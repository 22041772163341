import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/main.css";
import { Link } from "react-router-dom";

export const CreateQuizOptions = () => {
  return (
    <>
      <div className="w-full">
        <TopAdminNav name="Jane Doe" title="Create Quiz" />
      </div>

      <div className="flex">
        <div className="px-5 block w-full h-[87vh] overflow-auto register-scroll">
          <div className="flex">
            <div className="w-7/12">
              <RegisterCard
                title="Upload your quiz"
                details="Quiz for student now"
              />
            </div>
            <div className="w-5/12 h-full">
              <div className="flex">
                <div className="w-1/6"></div>
                <div className="w-4/6 h-full">
                  <div className="flex items-end justify-center h-full">
                    <Link to="/admin/class/quiz/import">
                      <button className="py-4 px-12 m-5 mb-0 bg-[#0096D1] text-white rounded-xl">
                        Import Quiz
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="w-1/6"></div>
              </div>
            </div>
          </div>
          <div className="text-[#012063] attendance_bg m-5 mt-1 p-5">
            <div className="flex text-[#1f1f1f]/[0.7] mb-7 w-full text-center">
              <div className="w-3/12 ">
                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center">
                  <option>All Created</option>
                </select>
              </div>
              <div className="w-3/12 ">
                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center">
                  <option>2021/2022</option>
                </select>
              </div>
              <div className="w-3/12 ">
                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center">
                  <option>Class</option>
                </select>
              </div>
              <div className="w-3/12 ">
                <select className="border-2 border-[#ccc]/[0.5] rounded-2xl w-10/12 mx-2 p-2 py-3 text-center">
                  <option>First Term</option>
                </select>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full text-center">
                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 mb-5 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2">
                  <div className=" block w-full py-3 px-5">
                    <h2 className="font-bold text-[#012063]/[0.7] text-left">
                      <span className="pr-3">1.</span> Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit?
                    </h2>
                    <div className="flex mt-4">
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 mb-5 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2">
                  <div className=" block w-full py-3 px-5">
                    <h2 className="font-bold text-[#012063]/[0.7] text-left">
                      <span className="pr-3">2.</span> Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit?
                    </h2>
                    <div className="flex mt-4">
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 mb-5 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2">
                  <div className=" block w-full py-3 px-5">
                    <h2 className="font-bold text-[#012063]/[0.7] text-left">
                      <span className="pr-3">3.</span> Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit?
                    </h2>
                    <div className="flex mt-4">
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 mb-5 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2">
                  <div className=" block w-full py-3 px-5">
                    <h2 className="font-bold text-[#012063]/[0.7] text-left">
                      <span className="pr-3">4.</span> Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit?
                    </h2>
                    <div className="flex mt-4">
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full text-center font-bold text-[#012063]/[0.7] bg-white p-1 m-2 mb-5 border-2 border-[#ccc]/[0.5] rounded-[12px] mt-2">
                  <div className=" block w-full py-3 px-5">
                    <h2 className="font-bold text-[#012063]/[0.7] text-left">
                      <span className="pr-3">5.</span> Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit?
                    </h2>
                    <div className="flex mt-4">
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                      <div className="border-2 border-[#ccc]/[0.7] rounded-2xl mx-2 px-3 py-1 font-medium text-[#1F1F1F]/[0.6] text-[15px]">
                        {" "}
                        (A) Lorem ipsum dolor sit ame
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
