import { AddUserCard } from "../../components/AddUserCard";
// import { AdminCalRec } from "../../layouts/AdminCalRec"
import { TopAdminNav } from "../../layouts/TopAdminNav";

export const Teacher = () => {
  return (
    <div>
      <TopAdminNav title="Add Users" name="John Doe" />

      <div className="flex">
        <div className="px-5 block w-[700px] w-12/12 lg:w-8/12">
          <div className="flex">
            <AddUserCard
              title="Create Quiz"
              content="Enter student and Parent data for Enrollment"
              link=""
            />
            <AddUserCard
              title="Lesson Note"
              content="Enter student data"
              link=""
            />
          </div>
          <div className="flex">
            <AddUserCard
              title="Quiz Report"
              content="Enter teacher data"
              link=""
            />
            <AddUserCard
              title="Report Card"
              content="Upload report card"
              link=""
            />
          </div>
        </div>
        {/* <AdminCalRec /> */}
      </div>
    </div>
  );
};
