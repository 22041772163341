import React from "react";
import { Link } from "react-router-dom";

export interface IUserData {
  id: string;
  userImage?: string;
  schoolName?: string;
  email: string;
  phoneNumber?: number;
  address?: string;
  plan: "standard" | "premium" | "suspended";
  date?: string;
}

function planColor(plan: string) {
  switch (plan) {
    case "standard":
      return "text-[#25D366]";
      break;
    case "suspended":
      return "text-[#FA614C]";
  }
}

const UserTableRow = (props: IUserData) => {
  const { id, userImage, schoolName, email, phoneNumber, address, plan, date } =
    props;
  return (
    <div className="min-w-[1000px] lg:min-w-full w-full bg-white">
      <div className="grid grid-cols-12 gap-2 mt-2">
        <div className="col-span-11 grid grid-cols-4 gap-4 border rounded-xl p-4 text-center text-[#1F1F1F]/[0.6] text-sm ">
          <div className="flex items-center gap-2">
            <div>
              <img src={userImage} alt="logo" />
            </div>
            <p className="text-[#012063]/[0.6] font-semibold">{schoolName}</p>
          </div>
          <div>
            <p>{email}</p>
            <p className="font-semibold">+{phoneNumber}</p>
          </div>
          <div>
            <p>{address}</p>
          </div>
          <div>
            <p className={`${planColor(plan)}`}>{plan}</p>
            <p>{date}</p>
          </div>
        </div>
        <Link
          className="col-span-1 text-white rounded-xl bg-[#0096D1] flex justify-center items-center"
          to={`/pendulum/edit-school/${id}`}
        >
          Edit
        </Link>
      </div>
    </div>
  );
};

export default UserTableRow;
