import GroupedBarChart from "../components/charts/BarChartGrouped";
import LineChart from "../components/charts/LineChart";

export const GrowthMonitor = ()=> {

    return (
        <div>
            <div className="p-3 max-w-[95%] mx-auto">
                <p>View and get insights from enrolment growth</p>
            </div>
            <main className={`max-h-[50vh] flex flex-col overflow-auto register-scroll`}>
                <div className="shadow-lg">
                    <h4 className="text-center font-black">Enrollment Growth per Term</h4>
                    <GroupedBarChart
                        data={[
                            {name: "first term", colorCode: "#D7320D", data: [65, 70, 180, 100, 260, 260]}, 
                            {name: "second term", colorCode: "#36a2eb", data: [70, 80, 200, 50, 262, 262]}, 
                            {name: "third term", colorCode: "lightgreen", data: [100, 105, 198, 267, 267, 267]}
                        ]}
                        titleText=""
                        xaxis={["2017","2018","2019","2020","2021","2022",]}
                        className={"max-w-[92%] mx-auto"}
                    />
                </div>
                <div className="mt-7 shadow-lg">
                    <h4 className="text-center font-black">Revenue Growth Trend</h4>
                    <LineChart
                        title=""
                        data={[
                            { name: "Tuition revenue", data: [0.5, 2, 1.5, 2.5, 2.5, 2.5]}, 
                            { name: "Supplies revenue", data: [1, 1, 1, 2, 2, 2]}, 
                            // { name: "Other revenues", data: [0.4, 0.75, 0.8, 0.67, 0.6, 0.7]}
                        ]}
                        xaxis={["2017","2018","2019","2020","2021","2022",]}
                    />
                </div>
            </main>
        </div>
    )
}