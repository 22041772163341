import React from 'react'

import img1 from "../../../assets/image/report docs/x9.png"

function DocsOthers() {
    return (
        <div className='my-16'>
            <h4 className='font-bold text-lg'>3.   SAVING THE FILES:</h4>

            <p>
                You must save the file as the pupil name is displayed on the website,
                i.e., Oluwadamilola Adebayo is saved as Oluwadamilola_Adebayo.
                Pay attention to the underscore ( _ ) sign.
            </p>


            <h4 className='font-bold mt-4 mx-4'>SAVING THE PUPIL ATTITUDE REPORT:</h4>

            <p className='mx-4'>
                The attitude report is only used for primary classes, an example, as shown above,
                must be saved in a different file with the pupil's name as the file's name
            </p>



            <div className='my-16'>
                <h4 className='font-bold text-lg'>4.   UPLOADING TO THE WEBSITE:</h4>
                <div className='my-5'>
                    <img src={img1} alt='' className='rounded-md h-full w-full' />
                </div>

                <p className='my-4'>
                    Click on the class for which you are generating a report, 
                    fill in the appropriate details, and you're done!
                </p>
            </div>


        </div>
    )
}

export default DocsOthers
