import { useState } from "react"
import { CircleLoader } from "../CircleLoader"
import { makePostRequest } from "../../utilities/common"
import { defaultUrl } from "../../features/actions"


function LandingPopupDemo() {

    const [loading, setLoading] = useState(false)

    const [inputValue, setInputValue] = useState({
        role: "",
        email: "",
        school_name: "",
        phone_number: ""
    })

    const handleChange = (e: any) => {
        const {name, value} = e.target

        setInputValue((prevValue) => {
            return {
                ...prevValue,
                [name] : value,
            }
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setLoading(true)


        await makePostRequest(`${defaultUrl}/requestDemo`, inputValue)
        setLoading(false)
        setInputValue({
            role: "",
            email: "",
            school_name: "",
            phone_number: ""
        })
    }


    return (
        <div className="space-y-6 px-4 my-4">

            <form onSubmit={handleSubmit}>
                <div className="">
                    <label className="text-xl font-semibold">CEO</label>
                    <input
                        id="searchAttendanceInput"
                        value={inputValue.role}
                        name="role"
                        onChange={handleChange}
                        placeholder="CEO"
                        className="rounded-lg pl-4 p-3 my-2 w-full focus:outline-none border-2 border-[#154f75] "
                        type="text"
                        required
                    />
                </div>

                <div className="">
                    <label className="text-xl font-semibold">Email</label>
                    <input
                        id="searchAttendanceInput"
                        value={inputValue.email}
                        name="email"
                        onChange={handleChange}
                        placeholder="Email"
                        className="rounded-lg pl-4 p-3 my-2 w-full focus:outline-none border-2 border-[#154f75] "
                        type="email"
                        required
                    />
                </div>


                <div className="">
                    <label className="text-xl font-semibold">School Name</label>
                    <input
                        id="searchAttendanceInput"
                        value={inputValue.school_name}
                        name="school_name"
                        onChange={handleChange}
                        placeholder="School Name"
                        className="rounded-lg pl-4 p-3 my-2 w-full focus:outline-none border-2 border-[#154f75] "
                        type="text"
                        required
                    />
                </div>

                <div className="">
                    <label className="text-xl font-semibold">Phone Number</label>
                    <input
                        id="searchAttendanceInput"
                        value={inputValue.phone_number}
                        name="phone_number"
                        onChange={handleChange}
                        placeholder="Phone Number"
                        className="rounded-lg pl-4 p-3 my-2 w-full focus:outline-none border-2 border-[#154f75] "
                        type="text"
                        required
                    />
                </div>


                <div className="flex justify-center my-4">
                    <button
                        className="flex justify-center px-5 py-3 rounded-lg text-white w-[300px]
        bg-gradient-to-b from-[#0096d1] to-[#012063]"
                    >
                        {loading ? <CircleLoader /> : "Book a demo"}

                    </button>
                </div>

            </form>
        </div>

    )
}

export default LandingPopupDemo
