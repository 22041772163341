import React from "react";
import { TopAdminNav } from "../layouts/TopAdminNav";
import "../assets/css/report.css";
import { AiOutlineSearch } from "react-icons/ai";

const contactReport = () => {
  return (
    <div>
      <div className="w-full">
        <TopAdminNav name="John Doe" title="Report" />
      </div>
      <div className="report">
        <div className="birthHead">
          <h2>Contact Report</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nunc.</p>
        </div>
        <div className="birthOption">
          <select name="" id="">
            <option value="">Crech 1</option>
            <option value="">Crech 2</option>
            <option value="">Crech 3</option>
          </select>
          <div className="search">
            <AiOutlineSearch />
            <input type="text" placeholder="Search" />
          </div>
          <button>Download</button>
        </div>
        <div className="tableOverflow">
          <table>
            <tr className="tableHead">
              <th>Name</th>
              <th>Class</th>
              <th>Age</th>
              <th>Date of Birth</th>
            </tr>

            <tr className="tableBody">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="tableBody">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="tableBody">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="tableBody">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="tableBody">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="tableBody">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="tableBody">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="tableBody">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default contactReport;
