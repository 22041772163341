import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import * as XLSX from 'xlsx';
import { populateReportCardData } from '../../../features/sharedStateSlices/shareState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeFetchRequest } from '../../../utilities/common';
import { defaultUrl } from '../../../features/actions';
import { CircleLoader } from '../../CircleLoader';

interface RowsState {
    attitude: Array<Array<Array<string | number | null>>>;
    results: Array<Array<Array<string | number | null>>>;
}


function ReportCardGenerator() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [queryString] = useSearchParams()
    const isPrimaryClass = queryString.get("primaryclass")

    const [result1, setResult1] = useState("Choose a file")
    const [result2, setResult2] = useState("Choose a file")

    


    const [rows, setRows] = useState<RowsState>({
        attitude: [],
        results: []
    });
    const [inputValue, setInputValue] = useState({
        title: "",
        presentDays: "",
        schoolDays: "",
        resumeDate: "",
        teacherComment: "",
        headTeacherComment: ""
    })
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const [averageScore, setAverageScore] = useState(0)
    const [childName, setChildName] = useState("")


    const getPupilDetails = async () => {
        const response = await makeFetchRequest(`${defaultUrl}/students`, false, true)

        const x = childName.split(".")[0].split("_")
        const fName = x[0].toLowerCase()
        const lName = x[1].toLowerCase()

        const studentID = response?.find(({ id, firstname, surname, is_archive }: any) => {
            if (is_archive === 0
                && firstname.toLowerCase() === fName
                && surname.toLowerCase() === lName) {
                return id
            } else {
                return null
            }

        })

        return studentID?.id

    }


    function getAverageScore(data: any) {

        const scorePercentIndex = data[0].indexOf("SCORE %")
        if (scorePercentIndex !== -1) {
            const totalSubjects = data[0].slice(1).length
            let totalScore = 0

            data[0].slice(1).map((item: any) => {
                totalScore = totalScore + parseFloat(item[scorePercentIndex])
                return totalScore
            })

            const average: any = Math.round(totalScore / totalSubjects).toFixed(0)
            setAverageScore(average)
        }

    }

    const handleTextUpload = (e: any) => {
        const { name, value } = e.target

        setInputValue((prevValue) => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }



    const handleFileUpload = async (event: any) => {
        try {
            const { name } = event.target
            const file = event.target.files?.[0];
            if (!file) return;
            if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                setError("Invalide file type");
                return
            }

            
            
            if(name === "attitude"){
                setResult2(file.name)
            }else{
                setResult1(file.name)
                setChildName(file.name)
            }
            
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);

            const sheetsData = workbook.SheetNames
            const sheetsCombined: any = []

            // Read file
            for (let i = 0; i < sheetsData.length; i++) {
                
                if(sheetsData[i].toLowerCase() === "comment" || sheetsData[i].toLowerCase() === "comments"){

                    const commentSheet = await readXlsxFile(file, { sheet: sheetsData[i] }) || []

                    setInputValue((prevValue : any) => {
                        return {
                            ...prevValue,
                            "headTeacherComment": commentSheet.length > 0 && commentSheet[0][0]
                        }
                    })

                }else{
                    const fileRows = await readXlsxFile(file, { sheet: sheetsData[i] });
                    sheetsCombined.push(fileRows);
                }

            }


            setRows((prevValue) => {
                return {
                    ...prevValue,
                    [name]: sheetsCombined
                }
            });

            setError('');
        } catch (err) {
            setError('Error reading file: ');
        }
    };


    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setLoading(true)

        const childID = await getPupilDetails()

        const body = {
            rows,
            averageScore,
            ...inputValue
        }

        dispatch(populateReportCardData(body))
        setLoading(false)

        if (childID.length > 0) {
            navigate(`/staff/report-sheet/${childID}`)
        }
    }


    useEffect(() => {
        rows.results.length > 0 && getAverageScore(rows.results[0])
    }, [rows])


    return (
        <div className="p-4">

            <form onSubmit={handleSubmit}>
                <div className="mb-5">
                    <label htmlFor='result1' className='cursor-pointer'>
                    <p>Upload Academic Result</p>

                    <div className='border p-2 px-4 rounded w-[250px] mt-3 '>
                        <p className='font-extralight'>{result1?.slice(0,24)}</p>
                    </div>
                        </label>
                    <input
                        type="file"
                        id='result1'
                        accept=".xlsx, .xls"
                        name="results"
                        onChange={handleFileUpload}
                        className='hidden'
                        required
                    />
                    <p className='text-[12px] my-2'>{result1 === "Choose a file" ? null : result1}</p>
                </div>
                

                {error && (
                    <div className="text-red-500 mb-4">
                        {error}
                    </div>
                )}



                {/* ATTITUDE GRADE */}
                {isPrimaryClass &&
                <div className="mb-5">

                <label htmlFor='result2' className='cursor-pointer'>
                    <p>Upload Attitude Report</p>

                    <div className='border p-2 px-4 rounded w-[250px] my-3'>
                        <p className='font-extralight'>{result2?.slice(0,24)}</p>
                    </div>
                        </label>
                    <input
                        type="file"
                        id='result2'
                        accept=".xlsx, .xls"
                        name="attitude"
                        onChange={handleFileUpload}
                        className='hidden'
                        required
                    />
                      <p className='text-[12px] my-2'>{result2 === "Choose a file" ? null : result2}</p>
            </div>
                }






                <div className="mb-5">
                    <label>Resumption Date</label><br />
                    <input
                        type="text"
                        name="resumeDate"
                        value={inputValue?.resumeDate}
                        onChange={handleTextUpload}
                        className="border-2 p-2 rounded w-[300px]"
                        required
                    />
                </div>

                <div className="mb-5">
                    <label>Report Card Title</label>
                    <input
                        type="text"
                        name="title"
                        value={inputValue?.title}
                        onChange={handleTextUpload}
                        className="border-2 p-2 rounded mx-5 w-[300px]"
                        placeholder='E.g Summer Report'
                        required
                    />
                </div>

                <div className="mb-7 flex items-center">
                    <input
                        type="text"
                        name="presentDays"
                        value={inputValue?.presentDays}
                        onChange={handleTextUpload}
                        className="border-2 p-2 rounded"
                        placeholder='Days Present e.g 56'
                        required
                    />
                    <span className='mx-2'>days present out of</span>
                    <input
                        type="text"
                        name="schoolDays"
                        value={inputValue?.schoolDays}
                        onChange={handleTextUpload}
                        className="border-2 p-2 rounded"
                        placeholder='School opening days'
                        required
                    />
                </div>

                <div className="mb-5">
                    <label>Class Teacher's Overall Comment</label><br />
                    <textarea name='teacherComment' value={inputValue?.teacherComment}
                        onChange={handleTextUpload}
                        className='px-4 h-[200px] w-[300px] sm:w-[500px] border-2 rounded-xl'
                        required>

                    </textarea>
                </div>


                <div className='my-7 flex justify-center'>
                    <button className='flex justify-center w-[300px] py-2 rounded-lg sm:hover:bg-[#123653] text-[#264a6a]
        sm:bg-gradient-to-b from-[#0096d1] to-[#1c3874] sm:text-white font-semibold'>
                        {loading ? <CircleLoader /> : "Generate Report Card"}
                    </button>
                </div>
            </form>

        </div>
    );
}

export default ReportCardGenerator