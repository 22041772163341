
function LandingWhoCan() {
  return (
    <section className="landingWho">
    <h2>Who can use Pendulum?</h2>

    <div className="whoContent">
      <div className="whoContentCap">
        <div className="capContainer">
          <div className="capText">
            <h2>School Admininstrators</h2>
          </div>
          <img data-aos="zoom-in-left" src="Admin.png" alt="" />
        </div>
      </div>
      <div className="whoContentCap">
        <div className="capContainer">
          <img data-aos="zoom-in-right" className="order-2 lg:order-1" src="Teachers.png" alt="" />
          <div className="capText2 order-1 lg:order-2">
            <h2>Teachers</h2>
            <ul>
              <li>
              Alleviate burnout by simplifying digital reporting
              </li>
            </ul>
          </div>
        </div>

        <p>
          “Our platform facilitates the efficient and effective management
          of activities involving parents, teachers, and students with ease
          and simplicity.”
        </p>

      </div>
      <div className="whoContentCap">
        <div className="capContainer">
          <div className="capText">
            <h2>Parent and Children</h2>
            <ul>
              <li>
              Keep track of school activities anytime and anywhere.
               Assess learning outcomes with real time photos and videos
              </li>
              <li>
              Enhance learning outcome with gamified resources for Gen Alpha
              </li>
            </ul>
          </div>
          <img className="!m-4 !sm:ml-0" data-aos="zoom-in-left" src="Parents.png" alt="" />
        </div>
      </div>
    </div>
  </section>
  )
}

export default LandingWhoCan
