import { Link } from 'react-router-dom'
import { FiMenu } from "react-icons/fi";
import { useState } from 'react';

function LandingHeader({ scrollTo } : any) {
  const [menuOpen, setMenuOpen] = useState(false)

  function toggleMenu(){
    setMenuOpen(!menuOpen)
  }

  const handleNavClick = (id: string) => {
    toggleMenu();
    scrollTo(id);
  }

  return (
    <nav className="landingNav relative">
      <Link to="/landingpage">
        <img src="/1.png" alt="" />
      </Link>

      <div className='block sm:hidden pr-4'>
        <FiMenu onClick={toggleMenu} className='text-3xl'/>
      </div>

      <ul className={`
          fixed top-0 sm:relative w-[80%] sm:w-auto h-full sm:h-auto z-[3] bg-white
          sm:flex justify-between items-center py-8 sm:py-0 px-4 sm:space-x-7 space-y-4 sm:space-y-0
          transition-transform duration-300 ease-in-out
          ${menuOpen ? 'translate-x-0' : '-translate-x-full'}
          sm:translate-x-0 sm:transition-none
        `}>
        <li className='py-2'>
          <button onClick={() => handleNavClick('about')}>About</button>
        </li>

        <li className='py-2'>
          <button onClick={() => handleNavClick('newsletter')}>Newsletter</button>
        </li>
        <li className='py-2'>
          <button onClick={() => handleNavClick('FAQs')}>FAQs</button>
        </li>
        <li data-aos="zoom-out-left" className='sm:px-4 py-2 rounded-lg sm:hover:bg-[#123653] text-[#264a6a]
        sm:bg-gradient-to-b from-[#0096d1] to-[#1c3874] sm:text-white font-semibold'>
          <Link to="/login" onClick={toggleMenu}>Sign In</Link>
        </li>
      </ul>
    </nav>
  )
}

export default LandingHeader
