import { useEffect, useState } from "react";
import { AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import { BiCategory, BiLogOut } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";

const sideBarMenu = {
  top: [
    {
      name: "Dashboard",
      link: "/pendulum",
      active: true,
      icon: <BiCategory />,
    },
    {
      name: "User",
      link: "/pendulum/users",
      active: false,
      icon: <AiOutlineUser />,
    },
  ],
  bottom: [
    {
      name: "Settings",
      link: "/admin/setting",
      active: false,
      icon: <AiOutlineSetting />,
    },
    {
      name: "Log out",
      link: "/",
      active: false,
      icon: <BiLogOut />,
    },
  ],
};

const PendulumNavBar = () => {
  const cachedActive = sessionStorage.getItem("activeNavItem");
  const [isActive, setIsActive] = useState(Number(cachedActive) ?? 0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    handleItemSelect(Number(cachedActive));
  }, []);

  const handleItemSelect = (idx: number) => {
    setIsActive(idx);
    setIsOpen(false);
    sessionStorage.setItem("activeNavItem", idx.toString());
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="">
      <div className={`${isOpen ? "activeNav" : "sideNav"}`}>
        <div onClick={toggle} className="toggleBtnns">
          <RxHamburgerMenu className="toggleBtnnsIcon" />
        </div>
        <div className="logo">
          <img src="/img/logo.svg" alt="logo here" className="laptopNav" />
          <div onClick={toggle} className="jio">
            <FaTimes className="jiso" />
          </div>
        </div>
        <ul className="h-[90vh] my-4 flex flex-col justify-between">
          <div className="flex flex-col gap-2">
            {sideBarMenu.top.map((item, index) => {
              return (
                <li
                  key={`${item.name}-${index}`}
                  onClick={() => handleItemSelect(index)}
                  className={`${
                    isActive === index
                      ? "bg-white w-full text-[#012063]"
                      : "text-white  w-full"
                  } rounded hover:bg-white  hover:text-[#012063]  transition ease-linear`}
                >
                  <Link
                    to={item.link}
                    className="flex items-center gap-2 py-2 px-3 "
                  >
                    <p>{item.icon}</p>
                    <p className="scale-105 hover:scale-100 transition-all ease-linear duration-200">
                      {item.name}
                    </p>
                  </Link>
                </li>
              );
            })}
          </div>

          <div className="listBottom mb-4 flex flex-col gap-2">
            {sideBarMenu.bottom.map((item, index) => {
              return (
                <li
                  key={`${item.name}-${index}`}
                  onClick={() => {
                    if (item.name === "Log out") {
                      window.sessionStorage.clear();
                    }
                  }}
                  className={`rounded hover:bg-white  hover:text-[#012063]  transition ease-linear`}
                >
                  <Link
                    to={item.link}
                    className="flex items-center gap-2 py-2 px-1"
                  >
                    {item.icon}
                    <p className="scale-105 hover:scale-100 transition-all ease-linear duration-200">
                      {item.name}
                    </p>
                  </Link>
                </li>
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default PendulumNavBar;
