import React from 'react'
import loader from '../assets/loader.gif'

const TableLoader = () => {
  return (
    <div>
        <img src={loader} alt='loader'/>
    </div>
  )
}

export default TableLoader