import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/main.css";
import { CircleLoader } from "../components/CircleLoader";
import { defaultUrl } from "../features/actions";
import { makePostRequest } from "../utilities/common";
import logo from "../assets/white_logo.png";
import { useSearchParams } from 'react-router-dom';
import toast from "react-hot-toast";

export const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [signUp, setSignUp] = useState({
        password: "",
        confirmPassword: "",
    });
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const handleChange = ({ target: { name, value } }: any) => {
        setSignUp({ ...signUp, [name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if(signUp?.password !== signUp?.confirmPassword ){
            toast.error("Passwords do not match!")
            return
        }

        if(signUp?.password.length < 8){
            toast.error("Password must be atleast 8 characters!")
            return
        }

        setLoading(true);

        const body = {
            email,
            token,
            "password": signUp?.password,
            "password_confirmation": signUp?.confirmPassword
        }


        window.sessionStorage.removeItem("token");

        await makePostRequest(`${defaultUrl}/reset-password`, body)

            setLoading(false);

            setSignUp({
                password: "",
                confirmPassword: "",
            });
            navigate("/login");
    };

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 h-[100vh] Login_bg">
            <div className="hidden md:block Login_bg_div px-20 pt-24 pb-20">
                <h2 className="text-white font-bold text-[27px] Login_bg_div_h2 border-none">
                    <img src={logo} alt="logo here" />
                </h2>
                <div className="h-[70vh] relative ">
                    <div className="absolute bottom-0">
                        <h4 className="Login_bg_div_div_h4 font-bold text-[22px] pb-3">
                            Do you know?
                        </h4>
                        <p className="Login_bg_div_div_p text-[18px] w-[400px] font-normal">
                            “Education is the most powerful weapon <br /> which you can use to
                            change the world.” - Nelson Mandela
                        </p>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div className="p-1 md:p-20 m-auto">
                <div className="w-[100%] md:w-[400px] flex-justify-center">
                    <h2 className="text-center font-bold tracking-widest text-[#012063] text-[22px]">
                        NEW PASSWORD
                    </h2>
                    <></>
                    <br />
                    <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                    <label className="text-[18px] tracking-normal text-[#012063] font-medium">
                            Password
                        </label>
                        <input
                            type="text"
                            className="block rounded-sm Login_Input p-3 w-full"
                            name="password"
                            value={signUp.password}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div>
                    <label className="mt-3 text-[18px] tracking-normal text-[#012063] font-medium">
                            Confirm password
                        </label>
                        <input
                            type="text"
                            className="block rounded-sm Login_Input p-3 w-full"
                            name="confirmPassword"
                            value={signUp.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <p>Password must be atleast 8 characters</p>

                        {/* <div className="flex justify-between pt-3">
              <span>
                <input type="checkbox" /> Remember me
              </span>
              <Link to="/forgetpassword">Forgot Password? </Link>
            </div> */}

                        <div>
                            <button type="submit" className="studentRegBtn">
                                {loading ? <CircleLoader /> : "Reset"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
