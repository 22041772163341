import React, { useEffect, useState } from "react";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { RegisterCard } from "../components/RegisterCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { globalToken } from "./AdminHome";
import axios from "axios";
import { defaultUrl } from "../features/actions";

// Expected Data Type Information
interface Payment {
  name: string;
  class: string;
  payment: string;
  transaction_id: string;
  timestamp: string;
  amount: string;
}

const PaymentAndInvoice = () => {
  /** COMPONENT MOUNT EFFECT */
  // const [selectedTab, setSelectedTab] = useState(1);
  // const [selectedSecTab, setSecSelectedTab] = useState(1);
  // useEffect(() => {
  //   const searchBar = document.getElementById("searchAttendanceInput");
  //   searchBar?.focus();
  //   fetchPaymentData();
  // }, []);

  // /** DATA FETCHING */
  // const [paymentData, setPaymentData] = useState<Payment[]>([]);

  // const fetchPaymentData = async () => {
  //   const data = await axios.get(`${defaultUrl}/payments`, {
  //     headers: {
  //       Authorization: "Bearer " + globalToken,
  //     },
  //   });
  //   console.log(data?.data.data);
  //   setPaymentData(data?.data.data);
  // };

  // /** FILTERINGS **/
  // const [searchTerm, setSearchTerm] = useState("");
  // const [classRoomFilter, setClassRoomFilter] = useState("");
  // const [filteredData, setFilteredData] = useState(paymentData);

  // function filterData() {
  //   const filtered = paymentData
  //     .filter((data) => {
  //       if (!searchTerm.length) {
  //         return data;
  //       }
  //       return data.name?.toLowerCase().includes(searchTerm.toLowerCase());
  //     })
  //     .filter((data) => {
  //       if (!classRoomFilter.length) {
  //         return data;
  //       }

  //       return data.class?.toLowerCase() === classRoomFilter.toLowerCase();
  //     });

  //   console.log({ filtered });
  //   return filtered;
  // }

  // useEffect(() => {
  //   setFilteredData(filterData());
  // }, [paymentData, searchTerm, classRoomFilter]);

  return (
    <div>
      <div className="w-full">
        <TopAdminNav name="Payments" title="Payment and Invoice" />
      </div>
      <div className="headerBody">
        <header className="header">
          <div className="headerCircle"></div>
          <div className="headerCap">
            <div className="headerText">
              <h2>Watch out! This exciting new feature is coming soon.</h2>
              <p>
                Soon you will be able to view and receive payments for fees and curricular activities.
              </p>
            </div>
          </div>
        </header>
      </div>
      {/* <main className="w-full">
        <section className="w-full flex">
          <div className="w-full">
            <RegisterCard
              title="Manage your School payments"
              details="Manage your School payments"
            />
          </div>
          <div className="flex items-center justify-end w-[15vw] min-w-max">
            <button className="rounded-xl bg-[#25D366] px-4 !h-[50px] w-min-max text-white text-center">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCashRegister} />
              </span>
              Cash Entry
            </button>
          </div>
        </section>

        <section className="w-full flex">
          <div
            onClick={() => setSelectedTab(1)}
            className={`flex flex-col justify-center rounded w-[98%] md:w-[25%] py-2 px-3 mr-3 my-2 cursor-pointer ${
              selectedTab === 1
                ? "bg-[#00648B] text-white"
                : "bg-[#0096D15C] text-[#004989]"
            }`}
          >
            <div className="flex flex-wrap items-center justify-between">
              <p className="text-xs font-semibold">School Fee received</p>
              <p className="text-lg font-semibold">₦ 1,200,300</p>
            </div>
            <div className="flex flex-wrap items-center justify-between">
              <p className="text-xs font-semibold">Expected Fee</p>
              <p className="text-lg font-semibold">₦ 5,200,300</p>
            </div>
          </div>

          <div
            onClick={() => setSelectedTab(2)}
            className={`rounded w-[98%] md:w-[25%] py-2 px-3 mr-3 my-2 cursor-pointer ${
              selectedTab === 2
                ? "bg-[#00648B] text-white"
                : "bg-[#0096D15C] text-[#004989]"
            } flex flex-col md:flex-row `}
          >
            <div className="flex flex-wrap items-center justify-between w-full">
              <p className="text-xs font-semibold">Total supplies received</p>
              <p className="text-lg font-semibold">₦ 1,200,300</p>
            </div>
          </div>

          <div
            onClick={() => setSelectedTab(3)}
            className={`rounded w-[98%] md:w-[25%] py-2 px-3 mr-3 my-2 cursor-pointer ${
              selectedTab === 3
                ? "bg-[#00648B] text-white"
                : "bg-[#0096D15C] text-[#004989]"
            } flex flex-col md:flex-row `}
          >
            <div className="flex flex-wrap items-center justify-between w-full">
              <p className="text-xs font-semibold">Child accounts</p>
              <p className="text-lg font-semibold">70</p>
            </div>
          </div>

          <div
            onClick={() => setSelectedTab(4)}
            className={`rounded w-[98%] md:w-[25%] py-4 pl-3 4xl:mr-3 my-2 cursor-pointer ${
              selectedTab === 4
                ? "bg-[#00648B] text-white"
                : "bg-[#0096D15C] text-[#004989]"
            } flex flex-col md:flex-row `}
          >
            <div className="md:w-1/2 flex items-center justify-between ">
              <p className="text-xs font-semibold">Class payment option</p>
            </div>
            <div className="md:w-1/2 md:ml-4 flex items-center justify-end ">
              <button className="rounded-r flex items-center justify-center bg-white md:p-2 xl:p-4 w-full text-[#0096D1]">
                <span className="w-[40px]">
                  <img src={"/img/edit_icon.svg"} alt="" />
                </span>
                Edit now
              </button>
            </div>
          </div>
        </section>

        <section className="studentData border border-gray-100 !pb-0 !pt-[0.05px] mt-2 attendance_bg ">
          {(selectedTab === 1 || selectedTab === 2 || selectedTab === 3) && (
            <div className="flex flex-wrap justify-start mr-6 md:justify-between text-[#1f1f1f]/[0.7] md:mt-3 mb-3 w-[98%] ">
              <div className="flex items-end">
                <p className="underline text-orange-600 cursor-pointer">
                  Recent history
                </p>
              </div>
              <div className="ml-1w-8/12 md:min-w-max flex flex-wrap md:flex-wrap-none justify-between items-center">
                <div className="w-3/12 min-w-[240px] mr-2 mb-1 md:mb-0">
                  <select
                    className="border-2 border-gray-200 rounded-2xl w-full max-w-[240px] min-w-[214px] mr-2 p-2 py-1 text-center focus:outline-none"
                    defaultValue={"one"}
                  >
                    <option value="all">All Classrooms</option>
                    <option value="one">Classroom One</option>
                    <option value="two">Classroom Two</option>
                  </select>
                </div>
                <div className="w-4/12 min-w-max">
                  <div className="flex w-full w-min">
                    <div className="flex items-center px-2 pl-4 rounded-2xl border-2 border-gray-200 min-w-[240px] ">
                      <FontAwesomeIcon icon={faSearch} />
                      <input
                        id="searchAttendanceInput"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search name"
                        className="rounded-2xl pl-4 p-1 w-full focus:outline-none "
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <main className="text-[#012063CC] mt-2">
            <div className="border-box overflow-x-auto w-full bg-[#F3FAFD] p-2 md:p-4 xl:p-6 ">
              {(selectedTab === 1 || selectedTab === 2) && (
                <div className="relative min-w-max flex flex-col items-center w-full text-center rounded-lg border border-[#012063] border-opacity-30">
                  <div className="absolute -top-6 flex w-[96%] !min-w-[700px] mb-2 py-4 bg-[#012063] rounded border text-white">
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Name</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Class</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Payment</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Transaction ID</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Date and Time</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Amount</p>
                    </div>
                  </div>
                  <div className=" h-[55vh] xl:h-[58vh] overflow-auto register-scroll w-[96%] !min-w-[700px] overflow-y-auto register-scroll">
                    {paymentData?.length > 0 &&
                      filteredData.map((item, index) => {
                        return (
                          <div className="flex items-center border w-full text-center font-semibold text-gray-600 bg-white py-3 rounded-xl mt-[5px]">
                            <div className="w-2/12 min-h-[20px]">
                              <p className="text-center text-[0.9em] text-[#012063CC]">
                                {item.name ?? null}
                              </p>
                            </div>
                            <div className="w-2/12 min-h-[20px]">
                              <p className="text-center text-[0.9em] min-w-max">
                                {item.class ?? null}
                              </p>
                            </div>
                            <div className="w-2/12 min-h-[20px]">
                              <p className="text-center text-[0.85em]">
                                {item.payment ?? null}
                              </p>
                            </div>
                            <div className="w-2/12 min-h-[20px]">
                              <p className="text-center text-[0.9em]">
                                {item.transaction_id ?? null}
                              </p>
                            </div>
                            <div className="w-2/12">
                              <p className="text-center text-[0.9em] ">
                                {item.timestamp ?? null}
                              </p>
                            </div>
                            <div className="w-2/12">
                              <p className="text-center text-[0.9em] ">
                                {item.amount ?? null}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {selectedTab === 3 && (
                <div className="relative min-w-max flex flex-col items-center w-full text-center rounded-lg border border-[#012063] border-opacity-30">
                  <div className="absolute -top-6 flex w-[96%] !min-w-[700px] mb-2 py-4 bg-[#012063] rounded border text-white">
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Name</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Class</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Paid</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>To Balance</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Status</p>
                    </div>
                    <div className="w-2/12 text-[0.9em] flex justify-center min-h-[20px]">
                      <p>Last Date</p>
                    </div>
                  </div>
                  <div className=" h-[55vh] overflow-auto register-scroll w-[96%] !min-w-[700px] overflow-y-auto register-scroll">
                    {paymentData?.length > 0 &&
                      filteredData.map((item, index) => {
                        return (
                          <div className="flex items-center border w-full text-center font-semibold text-gray-600 bg-white py-3 rounded-xl mt-[5px]">
                            <div className="w-2/12 min-h-[20px]">
                              <p className="text-center text-[0.9em] text-[#012063CC]">
                                {item.name ?? null}
                              </p>
                            </div>
                            <div className="w-2/12 min-h-[20px]">
                              <p className="text-center text-[0.9em] min-w-max">
                                {item.class ?? null}
                              </p>
                            </div>
                            <div className="w-2/12 min-h-[20px]">
                              <p className="text-center text-[0.85em]">
                                {item.payment ?? null}
                              </p>
                            </div>
                            <div className="w-2/12 min-h-[20px]">
                              <p className="text-center text-[0.9em]">
                                {item.transaction_id ?? null}
                              </p>
                            </div>
                            <div className="w-2/12">
                              <p className="text-center text-[0.9em] ">
                                {item.timestamp ?? null}
                              </p>
                            </div>
                            <div className="w-2/12">
                              <p className="text-center text-[0.9em] ">
                                {item.amount ?? null}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {selectedTab === 4 && (
                <div className="relative min-w-max flex flex-col items-center w-full text-center rounded-lg border border-[#012063] border-opacity-30">
                  <div className="flex flex-wrap justify-start mr-6 md:justify-between text-[#1f1f1f]/[0.7] md:mt-3 mb-3 w-[98%] ">
                    <div className="flex items-end gap-4 mb-8">
                      <p
                        onClick={() => setSecSelectedTab(1)}
                        className={`underline cursor-pointer ml-2 ${
                          selectedSecTab === 1
                            ? "text-orange-600"
                            : "text-grey-600"
                        }`}
                      >
                        Recent history
                      </p>
                      <p
                        onClick={() => setSecSelectedTab(2)}
                        className={`underline cursor-pointer ${
                          selectedSecTab === 2
                            ? "text-orange-600"
                            : "text-grey-600"
                        }`}
                      >
                        Uniform
                      </p>
                      <p
                        onClick={() => setSecSelectedTab(3)}
                        className={`underline cursor-pointer ${
                          selectedSecTab === 3
                            ? "text-orange-600"
                            : "text-grey-600"
                        }`}
                      >
                        End of the year
                      </p>
                      <p
                        onClick={() => setSecSelectedTab(4)}
                        className={`underline cursor-pointer ${
                          selectedSecTab === 4
                            ? "text-orange-600"
                            : "text-grey-600"
                        }`}
                      >
                        Party
                      </p>
                      <p
                        onClick={() => setSecSelectedTab(5)}
                        className={`underline cursor-pointer ${
                          selectedSecTab === 2
                            ? "text-orange-600"
                            : "text-green-600"
                        }`}
                      >
                        Add Now
                      </p>
                    </div>
                  </div>
                  {selectedSecTab === 1 && (
                    <div className=" h-[55vh] overflow-auto register-scroll w-[96%] !min-w-[700px] overflow-y-auto register-scroll">
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <h2 className="bg-[#0096D1] text-white w-96 h-[50px] flex items-center justify-center rounded-md">
                          School Fee
                        </h2>

                        <button className="bg-[#F5F5F5] text-[#0096D1] w-24 border-2	border-[#CDCBCB]	 h-[50px] flex items-center justify-center rounded-md">
                          Cancel
                        </button>
                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Save
                        </button>
                      </div>
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <div className="px-5 bg-[#F5F5F5] text-[#01206399] w-96 h-[50px] border-2	border-[rgba(0, 0, 0, 0.25)] flex items-center justify-between rounded-md">
                          <p>Pre class 2</p>
                          <p>10</p>
                          <p>#40000</p>
                        </div>

                        <button className="bg-[#F5F5F5] text-[#0096D1] w-24 border-2	border-[#CDCBCB] h-[50px] flex items-center justify-center rounded-md">
                          Cancel
                        </button>
                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Save
                        </button>
                      </div>
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <div className="px-5 bg-[#F5F5F5] text-[#01206399] w-96 h-[50px] border-2	border-[rgba(0, 0, 0, 0.25)] flex items-center justify-between rounded-md">
                          <p>Pre class 2</p>
                          <p>10</p>
                          <p>#40000</p>
                        </div>

                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Edit
                        </button>
                      </div>
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <div className="px-5 bg-[#F5F5F5] text-[#01206399] w-96 h-[50px] border-2	border-[rgba(0, 0, 0, 0.25)] flex items-center justify-between rounded-md">
                          <p>Pre class 2</p>
                          <p>10</p>
                          <p>#40000</p>
                        </div>

                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Edit
                        </button>
                      </div>
                      <div className="flex items-center justify-start gap-3 mb-3">
                        <div className="px-5 bg-[#F5F5F5] text-[#01206399] w-96 h-[50px] border-2	border-[rgba(0, 0, 0, 0.25)] flex items-center justify-between rounded-md">
                          <p>Pre class 2</p>
                          <p>10</p>
                          <p>#40000</p>
                        </div>

                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Edit
                        </button>
                      </div>
                      <div className="flex items-end gap-4">
                        <p className="underline text-green-600 cursor-pointer">
                          New Row
                        </p>
                      </div>
                    </div>
                  )}

                  {selectedSecTab === 2 && (
                    <div className=" h-[55vh] overflow-auto register-scroll w-[96%] !min-w-[700px] overflow-y-auto register-scroll">
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <h2 className="bg-[#0096D1] text-white w-96 h-[50px] flex items-center justify-center rounded-md">
                          Uniform
                        </h2>

                        <button className="bg-[#F5F5F5] text-[#0096D1] w-24 border-2	border-[#CDCBCB]	 h-[50px] flex items-center justify-center rounded-md">
                          Cancel
                        </button>
                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Save
                        </button>
                      </div>
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <div className="px-5 bg-[#F5F5F5] text-[#01206399] w-96 h-[50px] border-2	border-[rgba(0, 0, 0, 0.25)] flex items-center justify-between rounded-md">
                          <p>Small</p>
                          <p>#4000</p>
                        </div>

                        <button className="bg-[#F5F5F5] text-[#0096D1] w-24 border-2	border-[#CDCBCB] h-[50px] flex items-center justify-center rounded-md">
                          Cancel
                        </button>
                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Save
                        </button>
                      </div>
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <div className="px-5 bg-[#F5F5F5] text-[#01206399] w-96 h-[50px] border-2	border-[rgba(0, 0, 0, 0.25)] flex items-center justify-between rounded-md">
                          <p>Medium</p>
                          <p>#7000</p>
                        </div>

                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Edit
                        </button>
                      </div>
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <div className="px-5 bg-[#F5F5F5] text-[#01206399] w-96 h-[50px] border-2	border-[rgba(0, 0, 0, 0.25)] flex items-center justify-between rounded-md">
                          <p>Medium</p>
                          <p>#9000</p>
                        </div>

                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Edit
                        </button>
                      </div>
                      <div className="flex items-center justify-start gap-3 mb-3">
                        <div className="px-5 bg-[#F5F5F5] text-[#01206399] w-96 h-[50px] border-2	border-[rgba(0, 0, 0, 0.25)] flex items-center justify-between rounded-md">
                          <p>Extra Large</p>
                          <p>#12000</p>
                        </div>

                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Edit
                        </button>
                      </div>
                      <div className="flex items-end gap-4">
                        <p className="underline text-green-600 cursor-pointer">
                          New Row
                        </p>
                      </div>
                    </div>
                  )}
                  {selectedSecTab === 3 && (
                    <div className=" h-[55vh] overflow-auto register-scroll w-[96%] !min-w-[700px] overflow-y-auto register-scroll">
                      Third
                    </div>
                  )}
                  {selectedSecTab === 4 && (
                    <div className=" h-[55vh] overflow-auto register-scroll w-[96%] !min-w-[700px] overflow-y-auto register-scroll">
                      Forth
                    </div>
                  )}
                  {selectedSecTab === 5 && (
                    <div className=" h-[55vh] overflow-auto register-scroll w-[96%] !min-w-[700px] overflow-y-auto register-scroll">
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <h2 className="bg-[#0096D1] text-white w-96 h-[50px] flex items-center justify-center rounded-md">
                          Add New
                        </h2>

                        <button className="bg-[#F5F5F5] text-[#0096D1] w-24 border-2	border-[#CDCBCB]	 h-[50px] flex items-center justify-center rounded-md">
                          Cancel
                        </button>
                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Save
                        </button>
                      </div>
                      <div className="flex items-center justify-start gap-3 mb-6">
                        <div className="px-5 bg-[#F5F5F5] text-[#01206399] w-96 h-[50px] border-2	border-[rgba(0, 0, 0, 0.25)] flex items-center justify-between rounded-md">
                          <p>Details</p>
                          <p>Amount</p>
                        </div>

                        <button className="bg-[#F5F5F5] text-[#0096D1] w-24 border-2	border-[#CDCBCB] h-[50px] flex items-center justify-center rounded-md">
                          Cancel
                        </button>
                        <button className="bg-[#0096D1] text-white w-24  h-[50px] flex items-center justify-center rounded-md">
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </main>
        </section>
      </main> */}
    </div>
  );
};

export default PaymentAndInvoice;
