interface IInput {
  label?: string;
  name: string;
  value?: string;
  onChange?: ()=>void;
  placeholder?: string;
  required?: boolean;
  width?: number;
}

const FormOutlinedInput = ({ label, name, placeholder, required, width,value, onChange }: IInput) => {
  return (
    <div className="flex flex-col items-start md:flex-row md:gap-2 md:items-center my-2">
      <div className={`${label ? "md:max-w-[127px] md:w-full" : "hidden"}`}>
        <h6 className="font-medium text-sm text-right text-[#012063]/[0.7]">{`${
          label ? label : ""
        }`}</h6>
      </div>
      <input
        type="text"
        placeholder={placeholder}
        name={name}
        required={required}
        value={value}
        onChange={onChange}
        className={`h-10 rounded text-sm focus:outline-none shadow-md bg-white w-full text-[#1F1F1F]/[0.6] px-3 border md:my-2`}
       style={{maxWidth: `${width && width}px`}}
      />
    </div>
  );
};

export default FormOutlinedInput;
