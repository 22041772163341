import { useState } from "react"
import LandingPopupDemo from "./landingPopupDemo"
import CustomModal from "../CustomModal/CustomModal"


function LandingHeroSection() {

  const [showPopUp, setShowPopUp] = useState(false)

  return (
    <header className="landingheader mb-5 mt-10 pt-5">
      <div className="headerContainer">
        <div className="landingHeaderText">

          <div className="flex justify-between relative">
            <div className="absolute -top-6 sm:relative w-full sm:w-3/5 px-5">
              <h2>
                {" "}
                Empowering Education through Digital Innovation
              </h2>
              <p>
                The adoption of digital tools and platforms to transform learning
                and administrative processes in schools
              </p>

              <div className="landingHeaderBtn mt-7">
                <button className="headerBtn1" onClick={() =>setShowPopUp(true)}>Book a demo</button>
              </div>

              <div className="extraText !w-[400px] ml-10 mt-[80px]">
              <span>“</span>
              <p>
                Pendulum Digitals mission is to empower educators, pupils, and parents by providing real-time,
                technology-driven solutions that streamline school operations, improve learning outcomes, and
                foster seamless communication.
              </p>
              <span>“</span>
            </div>
            </div>

            <div data-aos="fade-left" className="landingHeaderPic w-[70%] sm:w-2/5 !pl-6 mt-4 sm:mt-0">
              <img src="2.png" alt="" />
            </div>
          </div>


          <div className="flex justify-end px-4">
           <div data-aos="flip-left" className="extraText !w-[400px] mt-7">
              <span>“</span>
              <p>We are dedicated to addressing the unique educational challenges
                in Africa, ensuring that every student has access to personalized
                learning tools and that schools can operate efficiently, all while
                reducing costs and alleviating teacher burnout.</p>
              <span>“</span>
            </div>
          </div>

        </div>

      </div>
      <div className="landingHeaderBtn2">
        <button className="headerBtn1" onClick={() =>setShowPopUp(true)}>Book a demo</button>
      </div>


      {showPopUp &&
            <CustomModal
            title={`Book a Demo`}
            width={75}
            children={
              <LandingPopupDemo />
            }
            hideModal={() =>setShowPopUp(false)}
          />
        }
    </header>
  )
}

export default LandingHeroSection
