import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  // TItle,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { getHeader } from "../utilities/importantFns";

import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { defaultUrl } from "../features/actions";
import React, { useEffect } from "react";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

ChartJS.register(ArcElement, Tooltip, Legend);

const labels = ["amkl", "b", "c"];

export const ChartAttendance: React.FC = () => {
  const [summaryData, setSummaryData] = React.useState({
    absent: 0,
    birthdays: 0,
    students: 0,
    classrooms: 0,
    loggedin: 0,
    loggedout: 0,
    messages: 0,
    "non-teachers": 0,
    parent: 0,
    photosandvideos: 0,
    teachers: 0,
  });

  const fetchDashboardData = async () => {
    const data = await axios.get(`${defaultUrl}/user/dashboard`, getHeader());
    // console.log({ d: data.data.data });
    setSummaryData(data?.data?.data);
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const data = {
    // labels,
    datasets: [
      {
        label: "['logged In', 'logged Out', 'Absent']",
        data: [
          summaryData?.loggedin,
          summaryData?.loggedout,
          summaryData?.absent,
        ],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "#D7320D"


        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(255, 99, 132, 0.7)"
        ],
        borderWidth: 1,
      },
    ],
  };

  // console.log('data', data)
  return (
    <div className="w-[120px]">
      <Doughnut data={data} />
    </div>
  );
};
