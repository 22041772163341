import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  studentCount: 0,
  staffCount: 0,
};

const registerSlice = createSlice({
  name: "registerslice",
  initialState,
  reducers: {
    incrementStudentCount: (state) => {
      state.studentCount = state.studentCount + 1;
    },
    decrementStudentCount: (state) => {
      state.studentCount = state.studentCount - 1;
    },
    incrementStaffCount: (state) => {
      state.staffCount = state.staffCount + 1;
    },
  },
});

export const {
  incrementStudentCount,
  decrementStudentCount,
  incrementStaffCount,
} = registerSlice.actions;

export default registerSlice.reducer;
