import { Outlet } from "react-router-dom";
import PendulumNavBar from "../../layouts/PendulumAdminLayout";
import AdminTopBar from "../../layouts/AdminTopBar";
import useWindowDimensions from "../../hooks/useWindowSize";

const PendulumAdminLayout = () => {
  const { width } = useWindowDimensions();

  const isMobileScreen = width < 440;
  return (
    <div className="">
      <div className="bg-blue-400">
        <PendulumNavBar />
      </div>
      <div
        className={` !w-full ${
          isMobileScreen
            ? "pl-[26px] overflow-auto max-w-[98%]"
            : "overflow-auto max-w-[100%]"
        }`}
      >
        <div className="dASI">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PendulumAdminLayout;
