export const faqs = [
    {
        Q: "How secure is the data stored on Pendulum Digital? ",
        A: "We use industry-standard encryption to ensure that all data stored on our cloud platform is safe and secure."
    },
    {
        Q: "Does Pendulum Digital work for schools of all sizes? ",
        A: "Yes, our platform is scalable and designed to meet the needs of small schools, large institutions, and everything in between."
    },
    {
        Q: "How do I get started with Pendulum Digital?",
        A: "Simply book for a demo session or contact us to schedule a demo, and we'll guide you through the setup process"
    },
    {
        Q: "Can I track my child's progress in real-time?",
        A: "Yes! Pendulum Digital offers real-time photos ,videos and report on pupil performance, assignments, and school activities directly through the app"
    },
    {
        Q: "Is the app available for both Android and iOS?",
        A: "Absolutely. Our app is available on both Android and iOS platforms."
    },
]
