import React, { useEffect, useState } from 'react'
import BackButton from '../components/backButton';
import { TopAdminNav } from '../layouts/TopAdminNav';
import { RegisterCard } from '../components/RegisterCard';
import ReportCardDisplay from '../components/ReportCard/Primary/ReportCardDisplay';
import { useParams } from 'react-router-dom';
import { makeFetchRequest } from '../utilities/common';
import { defaultUrl } from '../features/actions';

function ReportCard() {

    const { childID } = useParams()
    const [data, setData] = useState({})

    const getPupilDetails = async () => {
        const response = await makeFetchRequest(`${defaultUrl}/students`, false, true)
        const school = await makeFetchRequest(`${defaultUrl}/school`, false, true)
        const student = response?.find(({id, is_archive}: any) => {
            if(is_archive === 0){
                return childID === id 
            }else{
                return {}
            }
            
        })

        if(student){
            const classroom = await makeFetchRequest(`${defaultUrl}/classroom/${student?.classroom_id}`, false, true)
            const currentLoggedUser = JSON.parse(sessionStorage.getItem("user") || "")

            // Use loggedIn teacher data
            let teacherData = currentLoggedUser?.data[0]

            // But if the logged in user is an admin, search for the teacher
            if(currentLoggedUser?.role === "super_admin"){
                const teacherID = classroom?.teachers || []
                if(teacherID.length > 0){
                    teacherData = await makeFetchRequest(`${defaultUrl}/teachers/${teacherID[0]}`, false, true)
                }
            }
            



            const reportCardNameData = {
                student_id: student.id,
                child_name : `${student?.firstname || student?.othername || ""} ${student?.surname || ""}`,
                date_of_birth: student?.date_of_birth,
                profile_picture: student?.profile_picture,
                classroom : classroom?.name,
                teacher_name : `${teacherData?.firstname || ""} ${teacherData?.surname || ""}`,
                teacher_signature : teacherData?.teacher_signature,
                school_logo: school?.school_logo,
                head_teacher_signature : school?.school_head_signature
            }

            setData(reportCardNameData)

            
        }

    }

    useEffect(() => {
        getPupilDetails()
        
        // eslint-disable-next-line 
    },[])

    return (
        <div style={{ position: "relative" }}>
            <div className="w-full flex items-center">
                <BackButton />
                <TopAdminNav name="Jane Doe" title="Report Card Information" />
            </div>

            <div className="flex">
                <div className="block w-full">
                    <div className="flex">
                        <div className="w-full">
                            <RegisterCard
                                title="Report Card"
                                details="Summary of Child's academic perfomance"
                            />
                        </div>
                    </div>

                    <div>
                        <ReportCardDisplay ownerData={data} />
                    </div>

                </div>
            </div>


        </div>
    );

}

export default ReportCard