import { RegisterCard } from "../components/RegisterCard";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { HiOutlineArrowLeft } from "react-icons/hi";
import "../assets/css/main.css";
import BackButton from "../components/backButton";

export const BroadcastMessage = () => {
  return (
    <>
      <div className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jane Doe" title="Broadcast message" />
      </div>

      <div className="broadcastReg">
        <div className="broadcastForm fromMedia">
          <h2>To</h2>
          <div className="castFormp castMedia">
            <span>
              <input type="radio" name="reciever" id="" /> All
            </span>

            <span>
              <input type="radio" name="reciever" id="" /> Parent
            </span>

            <span>
              <input type="radio" name="reciever" id="" /> Teacher
            </span>
            <span>
              <input type="radio" name="reciever" id="" /> Non Teaching
            </span>
          </div>
        </div>

        <div className="broadcastForm fromMedia">
          <h2>Body</h2>
          <div className="castForm castMedia">
            <textarea name="" id=""></textarea>
          </div>
        </div>

        <div className="broadcastForm fromMedia">
          <h2>Attachment</h2>
          <div className="castForms castMedia">
            <input type="file" />
            <input type="file" />
            <input type="file" />
          </div>
        </div>
        <button className="studentRegBtn">Send</button>
      </div>
    </>
  );
};
