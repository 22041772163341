import TimePicker from 'react-time-picker';
import "../assets/css/timepicker.css";

export function TimeSelect({value, onChange}:{onChange:any, value:any}) {

    return (
      <div className='w-full h-full min-w-max'>
        <TimePicker format="h:m a" hourPlaceholder="hh" minutePlaceholder="mm" onChange={(val)=>{ onChange(val?.toString())}} value={value} />
      </div>
    );

}
