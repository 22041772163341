import HeaderCard from "../../components/pendulumAdmin/headerCard";
import AdminTopBar from "../../layouts/AdminTopBar";
import UserTable from "../../components/pendulumAdmin/userTable";
import dummyImage from "../../assets/image/staff-image.svg";
import UserTableRow, {
  IUserData,
} from "../../components/pendulumAdmin/userTableRow";
import { CiSearch } from "react-icons/ci";

export const dummyUserData: IUserData[] = [
  {
    id: "1",
    userImage: dummyImage,
    schoolName: "Saiya Model school",
    email: "saiya@yahoo.com",
    phoneNumber: 8033399944,
    address: "Dele awoseni streetLagos state.",
    plan: "standard",
    date: "20-june-2033",
  },
  {
    id: "2",
    userImage: dummyImage,
    schoolName: "Saiya Model school",
    email: "saiya@yahoo.com",
    phoneNumber: 8033399944,
    address: "Dele awoseni streetLagos state.",
    plan: "standard",
    date: "20-june-2033",
  },
  {
    id: "3",
    userImage: dummyImage,
    schoolName: "Saiya Model school",
    email: "saiya@yahoo.com",
    phoneNumber: 8033399944,
    address: "Dele awoseni streetLagos state.",
    plan: "standard",
    date: "20-june-2033",
  },
];

const dummyRemovedItem: IUserData = {
  id: "5",
  userImage: dummyImage,
  schoolName: "Saiya Model school",
  email: "saiya@yahoo.com",
  phoneNumber: 8033399944,
  address: "Dele awoseni streetLagos state.",
  plan: "suspended",
  date: "20-june-2033",
};

const UserData = () => {
  return (
    <div>
      <AdminTopBar title="User Data" name="John Doe" />
      <HeaderCard title="School Data" details="Update data on user" />
      <div className="mt-4 border rounded p-6 overflow-hidden bg-gradient-to-b from-white via-[#D0ECF6]/[0.5] to-white">
        <div className="mb-4 flex justify-end">
          <div className="relative flex items-center">
            <input
              placeholder="search"
              className="px-10 py-1 rounded-lg border h-10"
            />
            <CiSearch className="absolute left-2 text-[1F1F1F]/[0.6]" />
          </div>
        </div>
        <div className="overflow-scroll lg:overflow-auto">
          <UserTable data={dummyUserData} />
        </div>
      </div>

      <div className="p-6">
        <p className="text-[#FA614C] underline">Removed users</p>
        <div className="overflow-scroll lg:overflow-auto">
          <UserTableRow {...dummyRemovedItem} />
        </div>
      </div>
    </div>
  );
};

export default UserData;
