import React, { useState } from "react";
import { TopAdminNav } from "../../layouts/TopAdminNav";
import "../../assets/css/setting.css";
import SchProfileSetting from "../../components/schProfileSetting";
import Onboarding from "../../components/Onboarding";
import ChanPasssord from "../../components/chanPasssord";
import Faq from "../../components/faq";
import ContactUs from "./ContactUs";

const SchProfile = () => {
  const [active, setActive] = useState("3");
  const [subMenuOpen] = useState(true);
  const { role } = JSON.parse(sessionStorage.getItem('user') as string);

  const handleClick = (e: any) => {
    setActive(e.target.id);
  };



  return (
    <div>
      <TopAdminNav title="Settings" name="Jane Doe" />
      <div className="setting overflow-auto">
        <ul className={`settingNav max-w-[210px] ${subMenuOpen?"w-[200px]":"max-w-[100px] overflow-hidden"}`}>
          {role === 'super_admin' &&
          <li
            id="1"
            className={`${active === "1" ? "settingActive text-sm" : "settingList text-white text-sm"}`}
            onClick={handleClick}
          >
            School Profile
          </li>}
          <li
            id="3"
            className={`${active === "3" ? "settingActive text-sm" : "settingList text-white text-sm"}`}
            onClick={handleClick}
          >
            Change Password
          </li>
          <li
            id="4"
            className={`${active === "4" ? "settingActive text-sm" : "settingList text-white text-sm"}`}
            onClick={handleClick}
          >
            Feedback{" "}
          </li>
          <li
            id="5"
            className={`${active === "5" ? "settingActive text-sm" : "settingList text-white text-sm"}`}
            onClick={handleClick}
          >
            Contact us
          </li>

          <div className="rounded border-2 ml-2 pl-2 w-[140px] text-white bg-green-500">
            <a href="https://wa.me/+23409137826058" target="blank" className="w-full h-full" >Chat with us</a>
          </div>
        </ul>

        {active === "2"
          ? <Onboarding />
          : active === "3"
          ? <ChanPasssord />
          : active === "4"
          ? <Faq />
          : active === "5"
          ? <ContactUs />
          : <SchProfileSetting/>}
      </div>
    </div>
  );
};

export default SchProfile;
