import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
// import "../assets/css/setting.css";
// import ellipsis from "../assets/ellipse.svg";
import { AiOutlinePlus } from "react-icons/ai";
import { defaultUrl } from "../../features/actions";
import { globalToken } from "../AdminHome";

interface Message {
  name: string
  email: string
  subject: string
  phone_number?: string
  message: string
}

type ProfileItem = string|Blob|number;


const ContactUs = () => {
  let [form, setForm] = useState<Message>({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone_number: "",
  });

  function handleChange(name:string, value:ProfileItem) {
    console.log({[name]:value});
    setForm(prev=> {
      return { ...prev, [name]: value };
    })
  }

  async function handleSubmit() {
    toast.loading("Sending email...");
    if (form.message?.length<4|| form.email.length<4|| form.name?.length<3|| form.subject?.length<3) {
      toast.remove();
      toast.error("Please complete all required fields");
        return;
    }
    try {
        let { data } = await axios.post(`${defaultUrl}/contact`, form, {
            headers: {
                Authorization: "Bearer " + globalToken
            }
        })
        toast.remove();
        toast.success("Thank you for reaching out, we will respond to your email soon.")
        setForm({
          name: "",
          subject:"",
          message:"",
          phone_number:"",
          email: "",
        });

        return data
    } catch (e:any) {
        console.log({error: e.response.data || e});
    }
    return
  }


  return (
    <div className="schProfile flex-col">
      <div className="inputDv flex-1">
        <div className="input1 max-w-[280px]">
          <label className="!text-sm">Name</label>
          <input name="name" value={form.name} onChange={(e: any) => handleChange(e.target.name, e.target.value)} type="text" placeholder="Your full name" className="!h-12 px-4 focus:outline-none" />
        </div>
        <div className="input1 max-w-[280px]">
            <label className="!text-sm">Subject</label>
            <input name="subject" value={form.subject} onChange={(e: any) => handleChange(e.target.name, e.target.value)} type="text" className="!h-12 px-4 focus:outline-none" />
        </div>
        <div className="input max-w-[280px]">
          <label className="!text-sm">Message</label>
          <textarea name="message" value={form.message} onChange={(e: any) => handleChange(e.target.name, e.target.value)} className="p-4 focus:outline-none"></textarea>
        </div>
        <div className="inputOpt max-w-[280px] flex">
          <div className="input1 max-w-[280px]">
            <label className="!text-sm">Phone Number</label>
            <input name="phone_number" value={form.phone_number} onChange={(e: any) => handleChange(e.target.name, e.target.value)} type="tel" className="!h-12 px-4 focus:outline-none" placeholder="080xxx" />
          </div>
          <div className="input1 max-w-[280px]">
            <label className="!text-sm">Email</label>
            <input name="email" value={form.email} onChange={(e: any) => handleChange(e.target.name, e.target.value)} type="email" className="!h-12 px-4 focus:outline-none" placeholder="Enter your email address"/>
          </div>
        </div>
      </div>
      <div className="cameraBtn flex-row flex-wrap">
        <button onClick={handleSubmit} className='!text-sm m-4 ml-0 !h-12 max-w-[200px]'>Send message</button>
        <button className='btn2 !text-sm m-4 ml-0 !h-12 max-w-[200px]'>Cancel</button>
      </div>
    </div>
  );
};

export default ContactUs;
