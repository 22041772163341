import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilm } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

function ConversationList({ recipientInitials, item }: any) {

   let text = item.last_message
   if(text.includes("res.cloudinary.com")){
        text = <b>Media <FontAwesomeIcon icon={faFilm} /></b>
   }

    return (
        <Link to={`messagebox/${item.conversation_id}`}>
            <div className="flex items-center w-full border-blue-500">
                <div className="w-[40px] flex justify-center items-center">
                    <div
                        className={`rounded-full h-3 w-3 ${item.isOnline
                                ? "bg-green-500"
                                : " border border-gray-400"
                            }`}
                    ></div>
                </div>
                <div className="w-1/12 text-[0.9em] font-bold flex justify-center">
                    <div className="linearGradientButton1 opacity-70 rounded-full h-[50px] w-[50px] bg-green-500 flex justify-center items-center">
                        <p
                            className={`text-white text-xl font-semiBold`}
                        >
                            {recipientInitials}
                        </p>
                    </div>
                </div>
                <div className="w-2/12">
                    <p
                        className={`text-center text-[14px] text-[#012063CC] font-semibold ${!item.isRead && "font-bold text-blue-800"
                            }`}
                    >
                        {item.recipient ?? null}
                        <br />
                        <span
                            className={`text-[0.7em] text-gray-500 font-normal`}
                        >
                            {item.description ?? "Recipient"}
                        </span>
                    </p>
                </div>
                <div className="w-5/12 mr-5 ml-4 px-4 pl-6">
                    <p
                        className={`text-start text-[0.86em] text-gray-500 font-[600] max-h-[50px] overflow-hidden`}
                    >
                        {text}
                    </p>
                </div>
                <div className="w-2/12">
                    <p className={`text-center`}>
                        <span
                            className={`text-xs text-gray-500 w-max`}
                        >
                            {item.last_message_at}
                        </span>
                    </p>
                </div>
            </div>
        </Link>

    );
}


const ConversationListMobile = ({ recipientInitials, item }: any) => {
  let text = item.last_message
  if(text.includes("res.cloudinary.com")){
       text = <b>Media <FontAwesomeIcon icon={faFilm} /></b>
  }
    return (
          <Link to={`messagebox/${item.conversation_id}`}>
            <div className="flex items-start w-full  h-16 border-blue-500">
              <div className="w-1/4 pl-1 flex justify-center items-start ">
                <div className="w-full text-[0.9em] font-bold flex items-start justify-center">
                  <div className="linearGradientButton1 opacity-70 rounded-full h-[40px] w-[40px] bg-green-500 flex justify-center items-center">
                    <p className={`text-white font-semiBold`}>
                      {recipientInitials}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full h-16 flex flex-col">
                <div className="w-full">
                  <p
                    className={`text-start px-2 text-[12px] text-[#012063CC] font-semibold `}
                  >
                    {item.recipient ?? null}
                  </p>
                </div>
                <div className="w-full px-2 h-12 overflow-hidden">
                  <p
                    className={`text-start text-[12px] text-gray-600 `}
                  >
                    {text}
                  </p>
                </div>
                <div className="w-full pr-4">
                  <p
                    className={`text-end text-[10px] font-semibold `}
                  >
                    {item.last_message_at}
                  </p>
                </div>
              </div>
            </div>
          </Link>
      );
}



export {ConversationListMobile, ConversationList }
