import { AddUserCard } from "../components/AddUserCard";
import useWindowDimensions from "../hooks/useWindowSize";
import { TopAdminNav } from "../layouts/TopAdminNav";

export const Class = () => {
  const { width: windowWidth } = useWindowDimensions();
  const isMobileScreen = windowWidth < 440;

  return (
    <>
      <div className="w-full">
        <TopAdminNav name="Jone Doe" title="Manage Class" />
      </div>

      <div className="flex flex-wrap items-center pt-4">
        {!isMobileScreen && (
          <AddUserCard
            title="Classrooms"
            content="Pupils and Teachers"
            link="/admin/class/classes"
          />
        )}
        <AddUserCard
          title="Class Management"
          content="Update Classroom Activities"
          link="/admin/class/activities"
        />
      </div>
    </>
  );
};
