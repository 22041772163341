import TableHead from "./tableHead";
import TableBody from "./tableBody";
import { ITableData } from "./tableBody";

const MessageTable = ({ data }: { data: ITableData[] }) => {
  return (
    <table className="max-w-[600px] md:max-w-full w-full">
      <TableHead />
      <tbody className="max-w-[600px] md:max-w-full w-full">
        {data.map((item, index) => {
          return <TableBody {...item} />;
        })}
      </tbody>
    </table>
  );
};

export default MessageTable;
