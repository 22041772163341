import { RegisterCard } from "../../components/RegisterCard";
import { AiTwotoneCamera, AiFillVideoCamera } from "react-icons/ai";
import { TimeSelect } from "../../components/TimeSelect";
import { MediaUploadInput } from "../../components/MediaUploadInput";
import { CircleLoader } from "../../components/CircleLoader";

export const CAfood = ({formData, onFormChange, onFormSubmit,loading}:any) => {
  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="Food"
          details="Activity will be sent to parent/ guardians"
        />
        <br />
        <form onSubmit={onFormSubmit}>
          <div className="flex  flew-wrap items-center justify-between h-[90vh] my-4 px-2.5 overflow-x-hidden overflow-y-auto w-full">
            <div className="!p-0 md:w-2/3">
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-52 md:mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Food</h4>
                <div className="w-2/3 md:pl-6 ">
                  <div className="w-max max-w-[88vw] flex flex-wrap items-center justify-evenly rounded border bg-[#f5f5f5] p-4 py-3">
                    <label className="flex items-center mr-4 text-[14px]">
                      <input name="food" checked={formData["food"] === "breakfast"} onChange={(e)=> onFormChange("food", "breakfast")} type="checkbox" className="form-input checkbox mr-2" />
                      Breakfast
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input name="food" checked={formData["food"] === "breakfast snack"} onChange={(e)=> onFormChange("food", "breakfast snack")} type="checkbox" className="form-input checkbox mr-2" />
                      Breakfast Snack
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input name="food" checked={formData["food"] === "lunch"} onChange={(e)=> onFormChange("food", "lunch")} type="checkbox" className="form-input checkbox mr-2" />
                      Lunch
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input name="food" checked={formData["food"] === "lunch snack"} onChange={(e)=> onFormChange("food", "lunch snack")} type="checkbox" className="form-input checkbox mr-2" />
                      Lunch Snack
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Time</h4>
                <div className="w-2/3 md:pl-6 flex items-center">
                  <div className="w-80 min-w-max h-10 mr-1 pb-1 rounded-lg border"><TimeSelect value={formData.time ?? new Date()} onChange={(val:string)=> onFormChange("time", val)}  /></div>
                </div>
              </div> */}
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Quantity</h4>
                <div className="w-2/3 md:pl-6 ">
                  <div className="w-max flex items-center justify-evenly rounded border bg-[#f5f5f5] p-4 py-3">
                    <label className="flex items-center mr-4 text-[14px]">
                      <input name="" checked={formData["quantity"] === "all"} onChange={(e)=> onFormChange("quantity", "all")} type="checkbox" className="form-input checkbox mr-2" />
                      All
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input name="" checked={formData["quantity"] === "some"} onChange={(e)=> onFormChange("quantity", "some")} type="checkbox" className="form-input checkbox mr-2" />
                      Some
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input name="" checked={formData["quantity"] === "none"} onChange={(e)=> onFormChange("quantity", "none")} type="checkbox" className="form-input checkbox mr-2" />
                      None
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Meal</h4>
                <div className="w-2/3 md:pl-6">
                  <input type="text"
                   defaultValue={formData["meal"]}
                    onChange={(e)=> onFormChange("meal", e.target.value)}
                    className="w-full border-2 p-4 py-2 focus:outline focus:outline-[#012063]" />
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Description</h4>
                <div className="w-2/3 md:pl-6">
                  <textarea
                  defaultValue={formData["description"]}
                  onChange={(e)=> onFormChange("description", e.target.value)}
                  className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"></textarea>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row  mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Photo or Video</h4>
                <MediaUploadInput
                singleOption={false}
                onChangeFn={(e:any, type:any)=> onFormChange(type==="video"?"video" : "image", e && type==="video"? e.target.files[0] : e && type==="image"? e.target.files : "")} className="w-2/3 md:pl-6 flex flex-col items-center"
                pdfNull={true}
                />
              </div>

              <div className="mt-10 flex h-12 w-full">
                <div className="hidden md:block w-1/3"></div>
                <div className="w-full md:w-2/3 md:pl-6">
                  <button type="submit" className="flex items-center justify-center text-white w-full md:w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]">
                  {loading ? <CircleLoader /> : "Add"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile">
              <img
                src="/img/balanced-diet.svg"
                alt="login"
                className="w-40 h-40"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
