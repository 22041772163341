function StudentsArchive({archived_ChildrenPerPage,page_Index, handle_Archive }: any) {
  return (
    <div className="w-full text-center">
    <div className="grid grid-cols-5 w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl font-semibold border">
      <div className="col-span-1">Name</div>
      <div className="col-span-1 text-[0.9em] text-gray-600 font-bold flex justify-center">
        Class
      </div>
      <div className="col-span-1 text-[0.9em] text-gray-600 font-bold flex justify-center">
        Contact Number
      </div>
      <div className="col-span-1 text-[0.9em] text-gray-600 font-bold flex justify-center">
        Admission Date
      </div>
      {/* <div className="col-span-1 text-[0.9em] text-green-600 font-bold flex justify-center">
        Action
      </div> */}

    </div>


{archived_ChildrenPerPage &&
    <div className="h-full max-h-[64vh] w-[98%] !min-w-[700px]  overflow-y-scroll register-scroll">

      {archived_ChildrenPerPage.length >= 1 ? archived_ChildrenPerPage[page_Index].map((item: any, index: number) => {
        return (
          <div key={index} className="grid grid-cols-5 w-[98%] !min-w-[700px] mb-2 py-4 bg-[#F4F7FE] rounded-xl border">
            <div className="col-span-1">{item.firstname} {item.surname} {item.othername}</div>
            <div className="col-span-1 text-[0.9em] text-gray-600  flex justify-center">
              {item?.classname}
            </div>
            <div className="col-span-1 text-[0.9em] text-gray-600  flex justify-center">
              {item.parents && item.parents[0]?.phone}
            </div>
            <div className="col-span-1 text-[0.9em] text-gray-600  flex justify-center">
            {item?.admission_date}
            </div>
            <div className="col-span-1 mx-2.5 text-[0.9em] flex justify-end">
              <button className="bg-green-600 rounded-md hover:opacity-80 text-white p-2 my-auto h-[50px" onClick={() => {
                handle_Archive(item.id)
              }}>Unarchive</button>
            </div>

            {/* <div className="col-span-1 text-[0.9em] flex justify-start"></div> */}
          </div>
        );
      })
    :
    <p className="text-[#012063CC] py-3 text-xl text-center">No data found...</p>
    }
    </div>}
  </div>
  )
}

export default StudentsArchive
